import React from "react";
import "./Configuration.css";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setMenuInfo, setRoutingConfig } from "../../reducers/menuInfo";

class Configuration extends React.Component {
  constructor(props) {
    super(props);
    console.log(props.routesOrientation);
    this.state = {
      routesOrientation: props.routesOrientation,
      routesOrientations: ["Horizontal", "Vertical"],
    };
  }

  componentDidMount() {    
    this.props.setMenuInfo("Configuration");
  }

  render() {
    return (
      <div
        className="container-fluid page-container"
        style={{ cursor: this.state.wait ? "wait" : "default" }}
        disabled={this.state.wait}
      >
        {/* <div className="full-size center-content"> */}
          <div className="row">
            <label className="label-title-forInput col-4">Routes Orientation: </label>
            <select
              className={"form-control col-7 "}
              name="routesOrientation"
              value={this.state.routesOrientation}
              required={true}
              onChange={this.onChangeInput}
              onFocus={(e) => {
                this.lastInputFocused = e.target;
              }}
            >
              {this.state.routesOrientations.map((orientation, index) => {
                return (
                  <option
                    key={index}
                    value={orientation}
                    label={orientation}
                  >
                    {orientation}
                  </option>
                );
              })}
            </select>
          </div>          
        {/* </div> */}
      </div>
    );
  }

  onChangeInput = (event) => {
    console.log("onChangeInput");
    this.setState({[event.target.name]: event.target.value});
    this.props.setRoutingConfig(event.target.value);
  };
}

function mapStateToProps(state) {
  return {
    isLogged: state.auth.isLogged,
    currentPageName: state.menuInfo.currentPageName,
    routesOrientation: state.menuInfo.routesOrientation,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setMenuInfo,
      setRoutingConfig
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
