import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setMenuInfo } from "../../reducers/menuInfo";
import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";
import { Container, Button, Modal, Spinner } from "react-bootstrap";
import useModal from '../useModal/useModal';
import CustomModal from '../useModal/CustomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import { VersionService } from '../../restServices/VersionService';

export default function About(props) {

    const session = useSelector(state => state.auth.sessionData.session);
    const [modalData, showModal, hideModal] = useModal();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(setMenuInfo("About"));
    }, []);

    useEffect(() => {    
      getData();
    }, [session]); 
    
    const [wait, setWait] = useState(false);
    const [capstoneApiVersion, setCapstoneApiVersion] = useState("");        

    const getData = () => {
      if(!session){
        return;
      }
      setWait(true);
      const versionService = new VersionService(process.env.REACT_APP_CAPSTONE_REST_API);
      versionService.GetCurrentVersion(session)
        .then(response =>{
          setWait(false);
          if(response.error){
            showModal(response.error);
            return;
          }
          else if(response.result){
            setCapstoneApiVersion(response.result.version);
          }
        }); 
    }

    return (
    <Container fluid className="page-container">
      <Button onClick={getData} className="float-right"><FontAwesomeIcon icon={faSync}/></Button> 
        <div className="text-center">        
            <strong>ALMS Version: {props.appVersion}</strong>
            <br/>
            <br/>
            <strong>Capstone Rest API Version: {capstoneApiVersion} </strong>
            
        </div>
        <Modal
            className="modal-spinner"
            show={wait}
            centered
            onHide={(e) => {}}
        >
            <Spinner animation="border" role="status" />
            <span className="modal-spinner-text">
            {MESSAGES_CONSTANTS.MODAL_SPINNER_MESSAGE}</span>
        </Modal>
        <CustomModal modalData={modalData} hideModal={hideModal}/>
    </Container>);
}