import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StaticGoogleMap, Marker, Path } from 'react-static-google-map';

export function useDriverManifest(props){

  const currentWarehouse = useSelector(state => state.menuInfo.currentWarehouse);
  const [warehouseLocation, setWarehouseLocation] = useState();
  const [encodedDirections, setEncodedDirections] = useState("");
  const [itemsCopy, setItemsCopy] = useState(props.items); 

  useEffect(() => {
    setWarehouseLocation({
      lat: Number(currentWarehouse.Latitude),
      lng: Number(currentWarehouse.Longitude)
    });
  }, []); 
  
  const displayRouteDirections = () => {
    const directionsService = new props.google.maps.DirectionsService();
    console.log(props.items)
    let waypoints = [];
    props.items.forEach(item => {
      if(item.lat && item.lng){
        waypoints.push({
          location: { lat: item.lat, lng: item.lng },
          stopover: true,
        });
      } 
    });
    let request = {
      origin: warehouseLocation,
      waypoints: waypoints,
      destination: warehouseLocation,
      travelMode: "DRIVING",
    };      
    return directionsService.route(request).then(result => {       
      if (result.status === "OK") {
        return result;
      }
    });
  }

  const printDiv = (divName) => {    
    var div = document.getElementById(divName);
    if(div && div != null){
      var reportWindow=window.open();
      reportWindow.document.write('<html><head><title>Driver Manifest</title></head><body>');
      reportWindow.document.write(div.innerHTML);
      reportWindow.document.write('</body></html>');
      setTimeout(e => {
        reportWindow.print();
        reportWindow.close();
      }, 100);
    }
  }

  const printDriverManifest = () => {
    if(encodedDirections.length === 0 || props.items?.filter(item1 => !itemsCopy.some(item2 => item1.id === item2.id))?.length > 0){
      props.setWait(true);
      setItemsCopy(props.items);
      displayRouteDirections().then(directions => {
        props.setWait(false);
        console.log(directions);
        setEncodedDirections(directions.routes[0].overview_polyline);
        setTimeout(() => {
          printDiv("driverManifest" + props.id);
        }, 500);
      });
    }
    else{
      printDiv("driverManifest" + props.id);
    }
  }

  return [printDriverManifest, encodedDirections]

}

export function DriverManifest(props){

  const currentWarehouse = useSelector(state => state.menuInfo.currentWarehouse);
  const [warehouseLocation, setWarehouseLocation] = useState();
  const [encodedDirections, setEncodedDirections] = useState("");  
  const [loadData, setLoadData] = useState();

  useEffect(() => {
    setWarehouseLocation({
      lat: Number(currentWarehouse.Latitude),
      lng: Number(currentWarehouse.Longitude)
    });
  }, []); 
  
  useEffect(() =>{
    setLoadData(props.loadData);
  }, [props.loadData]);

  useEffect(() =>{
    setEncodedDirections(props.encodedDirections);
  }, [props.encodedDirections]);

  const totalWeight = (items)=>{
    let totalWeight = 0;
    items?.forEach(item => {
      totalWeight += item.totalWeight;
    });
    return totalWeight;
  }

  let stops = [];
  let previousLocation = "";
  let stopCounter = 0;
  return (
    <div id={"driverManifest" + props.id} style={{display: "none"}}>
      <h3>{props.title}</h3>
      {currentWarehouse.CompanyName}<br/>
      {loadData?.loadDate && (<>{loadData.loadDate}<br/></>)}
      {loadData?.driver && (<>{loadData.driver}<br/></>)}
      {loadData?.truck && (<>{loadData.truck}<br/></>)}
      <br/>
      Start Address: <br/>{currentWarehouse.CompanyName} - {currentWarehouse.BriefName} Warehouse<br/>
      {currentWarehouse.AddressLine1}<br/>
      {currentWarehouse.AddressLine2 && (<>{currentWarehouse.AddressLine2}<br/></>)}
      {currentWarehouse.City}, {currentWarehouse.StateOrProvince} {currentWarehouse.ZipOrPostalCode} <br/><br/>
      Starting Mileage: ______ <br/><br/>
      <table style={{width: "100%"}}>
        <thead>
          <tr>
            <th style={{textAlign: "left"}}>Stop</th>
            <th style={{textAlign: "left"}}>{props.title.indexOf("Sales Order") ? "Sales Order Number" : "Packing List Number"}</th>
            <th style={{textAlign: "left"}}>City/State</th>
            <th style={{textAlign: "right"}}>Weight</th>
            <th style={{textAlign: "left"}}>&nbsp;Arrive</th>
            <th style={{textAlign: "left"}}>Depart</th>
          </tr>
        </thead>
        <tbody>              
          {props.items && props.items.map((item, index) => {
            stops.push(0);
            let currentLocation = item.lat + "-" + item.lng + item.deliveryAddressName;
            if(previousLocation !== currentLocation){
              stopCounter++;
              stops[index]= stopCounter;
              previousLocation = currentLocation;
            }
            else{
              stops[index]= stopCounter;
            }
            return (
              <tr key={index}>
                <td style={{textAlign: "left"}}>{stops[index]}</td>	
                <td style={{textAlign: "left"}}>{item.label}</td>
                <td style={{textAlign: "left"}}>{item.cityAndState}</td>
                <td style={{textAlign: "right"}}>{Math.round(item.totalWeight).toLocaleString('en')} lbs</td>
                <td style={{textAlign: "left"}}>&nbsp;_______</td>
                <td style={{textAlign: "left"}}>_______</td>
              </tr>);
          })}
          <tr>
          <td></td>
            <td></td>
            <td></td>
            <td style={{textAlign: "right"}}>Total Weight</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style={{textAlign: "right"}}>{Math.round(totalWeight(props.items)).toLocaleString('en')} lbs</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>        
      End Address:<br/>{currentWarehouse.CompanyName} - {currentWarehouse.BriefName} Warehouse<br/>
      {currentWarehouse.AddressLine1}<br/>
      {currentWarehouse.AddressLine2 && (<>{currentWarehouse.AddressLine2}<br/></>)}
      {currentWarehouse.City}, {currentWarehouse.StateOrProvince} {currentWarehouse.ZipOrPostalCode} <br/><br/>
      Ending Mileage: ______ <br/>
      <br/> 
      <StaticGoogleMap size="1000x300" apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
        {warehouseLocation && (<Marker
          color="gray"
          label="W"
          location={warehouseLocation}/>)}

        {props.items && props.items.map((item, index) => {
          let location = item.lat + "-" + item.lng + item.deliveryAddressName;
          if(item.lat && item.lng ){
          return (
            <Marker 
              key={index}
              location={{ lat: item.lat, lng: item.lng }}
              label={stops[index].toString()}/>);
          }
          return null;
        })}            
        <Path
          points={"enc:" + encodedDirections}
        />
      </StaticGoogleMap>
      <br/>   
      <br/>              
      Lunch breaks / Rest periods<br/>
      Lunch/Rest #1 Start:______ End:______<br/>
      Lunch/Rest #2 Start:______ End:______<br/>
      <div>
          <h4>The above information is true and correct.</h4>
          _______________ Date: ______<br/>
          Signature<br/>
          _______________<br/>
          Print Name
      </div>
    </div> 
  );
}