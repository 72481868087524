import { BaseRestService } from "./BaseRestService.js";

export class VersionService extends BaseRestService {

  GetCurrentVersion(session) {

    return super.CustomFetch("Version/GetCurrentVersion", "", 'POST', session);
  }

}
