import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons';

class TreeNode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: props.defaultExpanded,
      disabled: props.disabled,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.disabled !== prevProps.disabled) {
      this.setState({ disabled: this.props.disabled });
    }
  }

  render() {
    var children = this.props.children.map((child, index) => {
      if (child.items === undefined) {
        return (
          child.access !== "N" && (
            <div key={index} className="collapsible">
              {this.state.isExpanded ? (
                <div className={this.props.classPrefix + "-treeLeaf"}>
                  <span
                    className={
                      "nodeText" +
                      (this.state.disabled ? " state-disabled" : "")
                    }
                    onClick={(event) => {
                      this.itemClick(child);
                    }}
                  >
                    {child.text}
                  </span>
                  {this.props.extraItem &&
                    this.props.extraItem(child, this.state.disabled)}
                </div>
              ) : null}
            </div>
          )
        );
      }

      let itemsWithPermission = child.items.filter(
        (item) => item.access === "Y"
      );
      let treeNode = (
        <TreeNode
          classPrefix={this.props.classPrefix}
          defaultExpanded={false}
          nodeLabel={child.text}
          children={child.items}
          onItemClick={this.props.onItemClick}
          favoriteId={child.favoriteId}
          disabled={this.state.disabled}
        />
      );
      return (
        <div key={index} className="collapsible">
          {this.state.isExpanded && child.items === undefined
            ? treeNode
            : this.state.isExpanded &&
              child.items &&
              child.items.length > 0 &&
              itemsWithPermission.length > 0
            ? treeNode
            : null}
        </div>
      );
    });
    return (
      <div className={this.props.classPrefix + "-treeNode"}>
        <span
          className={
            "nodeText" + (this.state.disabled ? " state-disabled" : "")
          }
          onClick={this.expandNode}
        >
          {this.state.isExpanded ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon icon={faChevronRight} />
          )}
          &ensp;
          {this.props.nodeLabel}
        </span>
        {children}
      </div>
    );
  }

  expandNode = (event) => {
    event.preventDefault();
    if (event.button === 0) {
      this.setState({ isExpanded: !this.state.isExpanded });
    }
  };

  contextMenu = (event, item) => {
    if (this.props.onContextMenu) {
      this.props.onContextMenu(event, item);
    }
  };

  itemClick = (item) => {
    if (this.props.onItemClick) {
      this.props.onItemClick(item);
    }
  };
}

export default TreeNode;
