import React from "react";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";
import { SalesOrderServiceSOAP } from "../../soapServices/SalesOrderService.js";
import useModal from '../useModal/useModal';
import CustomModal from '../useModal/CustomModal';
import { useDriverManifest, DriverManifest} from "./DriverManifest"
import { Button, Modal, Spinner } from "react-bootstrap";

export default function PrintSODocs(props){

  const salesOrderServiceSOAP = useRef();

  const session = useSelector(state => state.auth.sessionData.session); 
  const documentInitForSystemSettings = useSelector(state => state.auth.sessionData.documentInitForSystemSettings); 
  const [modalData, showModal, hideModal] = useModal();
  const [showDialog, setShowDialog] = useState(false);
  const [fulfillmentTicketLinesType, setFulfillmentTicketLinesType] = useState("");
  const [wait, setWait] = useState(false);
  const [printDriverManifest, encodedDirections] = useDriverManifest({...props, setWait});

  useEffect(() => {
    salesOrderServiceSOAP.current = new SalesOrderServiceSOAP(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);        
  }, []);

  useEffect(() => {
    setFulfillmentTicketLinesType(documentInitForSystemSettings?.FulfillmentTicketLinesType);
  }, [documentInitForSystemSettings]);

  useEffect(() => {
    setShowDialog(props.showDialog);
  }, [props.showDialog]);

  const hideDialog = () => {    
    setShowDialog(false);
    props.onHideDialog();
  };

  const printLoadingTickets = async () => {
    setWait(true);      
    for (let index = 0; index < props.items.length; index++) {
        let item = props.items[index];
        console.log("Printing loading ticket " + item.id);       
        await salesOrderServiceSOAP.current.PrintLoadingTicket(session, undefined, item.id)
        .then(printLoadingTicketResult => {
          if(printLoadingTicketResult.error){
            setWait(false);
            showModal(printLoadingTicketResult.error?.detail?.CapstoneException?.ErrorMessage, MESSAGES_CONSTANTS.MODAL_ERROR_TITLE);
            return false;
          }
          console.log("Loading Ticket done " + item.id);
          return sleep((Number(process.env.REACT_APP_WAIT_BETWEEN_PRINT_LOADDOCS_SECONDS) * 1000)).then(() => {   
            if(index === 0){
              setWait(false);
              showModal("Loading Ticket(s) Printed.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
            } 
            return true;            
          });                           
        });
    }
  }

  const printShippers = async () => {
    setWait(true);      
    for (let index = 0; index < props.items.length; index++) {
        let item = props.items[index];
        console.log("Printing shipper " + item.id);       
        await salesOrderServiceSOAP.current.PrintShipper(session, item.id, item.includePrincing, true)
        .then(printShipperResult => {
          if(printShipperResult.error){
            setWait(false);
            showModal(printShipperResult.error?.detail?.CapstoneException?.ErrorMessage, MESSAGES_CONSTANTS.MODAL_ERROR_TITLE);
            return false;
          }
          console.log("Shipper done " + item.id);
          return sleep((Number(process.env.REACT_APP_WAIT_BETWEEN_PRINT_LOADDOCS_SECONDS) * 1000)).then(() => {   
            if(index === 0){
              setWait(false);
              showModal("Shipper(s) Printed.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
            } 
            return true;            
          });                           
        });
    }
  }

  const printFulfillmentTickets = async () => {
    setWait(true);      
    for (let index = 0; index < props.items.length; index++) {
        let item = props.items[index];
        console.log("Printing Fulfillment Ticket " + item.id);       
        await salesOrderServiceSOAP.current.PrintOrPreviewFulfillmentTicket(session, item.id)
        .then(printFulfillmentResult => {
          if(printFulfillmentResult.error){
            setWait(false);
            let error = handleCapstoneException(printFulfillmentResult.error?.detail?.CapstoneException);
            showModal(error.message, error.title);
            return false;
          }
          console.log("Fulfillment Ticket done " + item.id);
          return sleep((Number(process.env.REACT_APP_WAIT_BETWEEN_PRINT_LOADDOCS_SECONDS) * 1000)).then(() => {   
            if(index === 0){
              setWait(false);
              showModal("Fulfillment Ticket(s) Printed.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
            } 
            return true;            
          });                           
        });
    }
  }

  const handleCapstoneException = (capstoneException) => {
    let message = capstoneException?.ErrorMessage;
    let title = MESSAGES_CONSTANTS.MODAL_ERROR_TITLE;
    if(capstoneException?.ErrorCode === "4031") {
      message += " No documents were printed.";
      title = MESSAGES_CONSTANTS.MODAL_INFO_TITLE;
    }
    return { message, title };        
  }

  const printPickingQuickCutTickets = async () => {
    setWait(true);      
    for (let index = 0; index < props.items.length; index++) {
        let item = props.items[index];
        console.log("Printing Picking Ticket " + item.id);       
        await salesOrderServiceSOAP.current.PrintSOPickingTicket(session, item.id).then(printPickingResult => {
          if(printPickingResult.error){
            setWait(false);
            let error = handleCapstoneException(printPickingResult.error?.detail?.CapstoneException);
            showModal(error.message, error.title);
            return false;
          }
          console.log("Picking Ticket done " + item.id);
          return sleep((Number(process.env.REACT_APP_WAIT_BETWEEN_PRINT_LOADDOCS_SECONDS) * 1000)).then(() => { 

            console.log("Printing Quick Cut Ticket " + item.id); 
            return salesOrderServiceSOAP.current.PrintSOQuickCutTicket(session, item.id).then(printQuickCutResult => {
              if(printQuickCutResult.error){
                setWait(false);
                console.log("Quick Cut not printed: " + printQuickCutResult.error?.detail?.CapstoneException?.ErrorMessage);
                if(index === 0){
                  setWait(false);
                  showModal("Picking Ticket(s) Printed.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
                } 
                return false;
              }
              else{
                console.log("Quick Cut Ticket done " + item.id);
                return sleep((Number(process.env.REACT_APP_WAIT_BETWEEN_PRINT_LOADDOCS_SECONDS) * 1000)).then(() => {   
                  if(index === 0){
                    setWait(false);
                    showModal("Picking and Quick Cut Ticket(s) Printed.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
                  } 
                  return true;
                });
              }                           
            });
          });                           
        });
    }
  }

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  return (
    <div>
      <Modal show={showDialog} onHide={(e) => {}} centered>
        <Modal.Header>
          <Modal.Title>Run Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{textAlign:"center"}}>                                        
            <br/>
              <button className="btn btn-secondary" onClick={printLoadingTickets}>Print Loading Tickets</button>
            <br/>
            <br/>              
              {fulfillmentTicketLinesType === "1" ? 
              <button className="btn btn-secondary" onClick={printPickingQuickCutTickets}>Print Picking and Quick Cut Tickets</button> :
              <button className="btn btn-secondary" onClick={printFulfillmentTickets}>Print Fulfillment Tickets</button>}              
            <br/>
            <br/>
              <button className="btn btn-secondary" onClick={printShippers}>Print Shippers</button>
            <br/>
            <br/>
              <button className="btn btn-secondary" onClick={printDriverManifest}>Print Driver Manifest</button>
            <br/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ width: "6rem" }} onClick={hideDialog}>
            Close
          </Button>                        
        </Modal.Footer>
      </Modal>      
      <Modal
        className="modal-spinner"
        show={wait}
        centered
        onHide={(e) => {}}
        backdropClassName={"custom-backdrop"}
      >
        <Spinner animation="border" role="status" />
        <span className="modal-spinner-text">
          {MESSAGES_CONSTANTS.MODAL_SPINNER_MESSAGE}
        </span>
      </Modal>
      <CustomModal modalData={modalData} hideModal={hideModal}/>
      <DriverManifest google={props.google} id={props.id} setWait={setWait}
        title={"Driver Manifest by Sales Order"} items={props.items} encodedDirections={encodedDirections}/>
    </div>
  );
}