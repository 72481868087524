import { BaseSoapService } from "./BaseSoapService.js";

export class LoginUserService extends BaseSoapService {
  constructor(baseUrl, capstoneVersion) {
    super(baseUrl, "LoginUserService.asmx", "LoginUserService", capstoneVersion);
  }

  GetLoginUser(session, userId) {
    var args = {
      session: session,
      userId: userId,
    };

    var resultResponse = {
      loginUserResult: undefined,
      error: undefined,
    };

    return super.makeSoapCall("GetLoginUser", args).then(
      (result) => {
        console.log("GetLoginUser:");
        console.log(result);
        if (result.GetLoginUserResponse) {
          resultResponse.loginUserResult =
            result.GetLoginUserResponse.GetLoginUserResult;
        }
        return resultResponse;
      },
      (error) => {
        console.log("Error on GetLoginUser");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  GetAllMenuAccess(session, userIdOrGroupId, loginType) {
    var args = {
      session: session,
      userOrGroupId: userIdOrGroupId,
      loginType: loginType,
    };

    var resultResponse = {
      data: [],
      error: undefined,
    };

    return super.makeSoapCall("GetAllMenuAccessForReact", args).then(
      (result) => {
        console.log("GetAllMenuAccessForReact Result:");
        let menuAccesses =
          result.GetAllMenuAccessForReactResponse
            .GetAllMenuAccessForReactResult;
        let data = [];
        if (menuAccesses && Array.isArray(menuAccesses.LoginMenuAccessReact)) {
          data = menuAccesses.LoginMenuAccessReact;
        } else if (menuAccesses) {
          data = [menuAccesses.LoginMenuAccessReact];
        }
        resultResponse.data = data;
        return resultResponse;
      },
      (error) => {
        console.log("Error on GetAllMenuAccessForReact:");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  GetLoginPermissionForGroup(session, groupId) {
    var args = {
      session: session,
      userId: groupId,
    };
    var resultResponse = {
      data: {},
      error: undefined,
    };

    return super.makeSoapCall("GetLoginPermissionForGroup", args).then(
      (result) => {
        console.log("GetLoginPermissionForGroupService");
        var loginPermission =
          result.GetLoginPermissionForGroupResponse
            .GetLoginPermissionForGroupResult.LoginPermission;
        console.log(loginPermission);
        resultResponse.data = loginPermission;

        return resultResponse;
      },
      (error) => {
        console.log("Error on GetLoginPermissionForGroup:");
        console.log(error);
        resultResponse.error = error;

        return resultResponse;
      }
    );
  }

  GetLoginPermissionForUser(session, userId) {
    var args = {
      session: session,
      userId: userId,
    };

    var resultResponse = {
      data: {},
      error: undefined,
    };
    return super.makeSoapCall("GetLoginPermissionForUser", args).then(
      (result) => {
        console.log("GetLoginPermissionForUserService");
        var loginPermission = result.GetLoginPermissionForUserResponse
          ? result.GetLoginPermissionForUserResponse
              .GetLoginPermissionForUserResult
            ? result.GetLoginPermissionForUserResponse
                .GetLoginPermissionForUserResult.LoginPermission
            : result.GetLoginPermissionForUserResponse
                .GetLoginPermissionForUserResult
          : result.GetLoginPermissionForUserResponse;
        resultResponse.data = loginPermission;

        return resultResponse;
      },
      (error) => {
        console.log("Error on GetLoginPermissionForUser:");
        console.log(error);
        resultResponse.error = error;

        return resultResponse;
      }
    );
  }

  GetMobileMenuAccessForUser(session, loginUserId) {
    var args = {
      session: session,
      loginUserId: loginUserId,
    };

    var resultResponse = {
      result: [],
      error: undefined,
    };

    return super.makeSoapCall("GetMobileMenuAccessForUser", args).then(
      (result) => {
        console.log("GetMobileMenuAccessForUser Result:");
        let menuAccesses =
          result.GetMobileMenuAccessForUserResponse
            .GetMobileMenuAccessForUserResult;
        let data = [];
        if (menuAccesses && Array.isArray(menuAccesses.LoginMenuMobileAccess)) {
          data = menuAccesses.LoginMenuMobileAccess;
        } else if (menuAccesses) {
          data = [menuAccesses.LoginMenuMobileAccess];
        }
        resultResponse.result = data;
        return resultResponse;
      },
      (error) => {
        console.log("Error on GetAllMenuAccessForReact:");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  GetAllDriverLoginUsers(session) {
    var args = {
      session: session
    };
    var resultResponse = {
      result: [],
      error: undefined
    };

    return super.makeSoapCall("GetAllDriverLoginUsers", args).then(
      result => {
        console.log("GetAllDriverLoginUsers Result:");
        let drivers = result.GetAllDriverLoginUsersResponse.GetAllDriverLoginUsersResult;
        let driversList = drivers !== null ? drivers.LoginUser : drivers;
        console.log(driversList);
        if (drivers && Array.isArray(driversList)) {
          resultResponse.result = driversList;
        } else if (drivers && driversList) {
          resultResponse.result = [driversList];
        }
        console.log("sort by Name Drivers");
        if (resultResponse.result) {
          resultResponse.result = resultResponse.result.sort(function(a, b) {
            if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
            if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
            return 0;
          });
        }
        //console.log(resultResponse.data);
        return resultResponse;
      },
      error => {
        console.log("Error on GetAllDriverLoginUsers:");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }
}
