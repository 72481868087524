import React, { useState, useEffect } from 'react';
import { flushSync } from 'react-dom'; 
import DropTarget from '../dragDropContainer/DropTarget';
import Item from './Item';
import Run from './Run';

import { useSelector, useDispatch } from "react-redux";
import { updateRoute, moveRoute, removeManualStop} from "../../reducers/routesReducer";

import { GithubPicker  } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltDown, faArrowAltFromTop, faArrowAltLeft, faArrowAltRight, faArrowAltToTop, faArrowAltUp, faPalette, faWindowMaximize, faWindowMinimize } from '@fortawesome/pro-solid-svg-icons';
import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";

import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import CustomModal from '../useModal/CustomModal';
import useModal from '../useModal/useModal';

var shortid = require('shortid');
const SUPER_MINIMIZED = "super-minimized";
const MINIMIZED = "minimized";

export default function Route(props) {

  const session = useSelector(state => state.auth.sessionData.session);
  const dispatch = useDispatch();

  const [items, setItems] = useState(props.routeData.items);  
  const [runs, setRuns] = useState(props.routeData.runs);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState(props.routeData.color);
  const [minimized, setMinimized] = useState(props.routeData.minimized);
  const [currentItemNote, setCurrentItemNote] = useState();
  useEffect(() => {
    setCurrentItemNote(props.currentItemNote);
    updateNoteItem(props.currentItemNote);
  }, [props.currentItemNote]);

  const [modalData, showModal, hideModal] = useModal();
  const [wait, setWait] = useState(false);

  useEffect(() => {
    setItems(props.routeData.items);
    setRuns(props.routeData.runs);
    setColor(props.routeData.color);
    setMinimized(props.routeData.minimized);
  }, [props.routeData]);

  const updateRouteReducer = (items, runsParam, updateDb = true, newColor, newMinimized) => {
    let routeToUpdate = {
      routeId: props.routeData.routeId,
      routeIndex: props.index,
      runs: runsParam ? runsParam : runs,
      items: items, 
      color: newColor ? newColor : color,
      minimized: newMinimized !== undefined ? newMinimized : minimized,
    }
    setWait(true);
    dispatch(updateRoute(routeToUpdate, updateDb, session))
    .then(responses => {
      setWait(false);
      console.log(responses);
      if (responses.length > 0 && responses[0].error) {
        showModal(responses[0].error.exceptionMessage, MESSAGES_CONSTANTS.MODAL_ERROR_TITLE,
          MESSAGES_CONSTANTS.MODAL_OK_OPTION, () => {
            hideModal();
            props.refreshRoutesData();
          });          
      }
    });
  }

  const moveLeft = (e) => {
    console.log("moveLeft");
    dispatch(moveRoute(props.index, -1));
  }

  const moveRight = (e) => {
    console.log("moveRight");
    dispatch(moveRoute(props.index, 1));
  }

  const editNote = (orderId, note) => {
    props.editNote(orderId, note);
  }

  const minimizeOrMaximize = () =>{
    let value = minimized === MINIMIZED ? "" : MINIMIZED;
    setMinimized(value);
    updateRouteReducer(items, runs, false, color, value);
  }

  const superMinimizeOrMaximize = () =>{
    let value = minimized === SUPER_MINIMIZED ? "" : SUPER_MINIMIZED;
    setMinimized(value); 
    updateRouteReducer(items, runs, false, color, value);
  }

  const handleDrop = (e) => {
    console.log("drop in route");
    let itemsCopy = items.slice();
    itemsCopy.push({
      ...e.dragData,
      uid: shortid.generate(),
      runNumber: null,
      routeId: props.routeData.routeId
    });    
    e.containerElem.style.visibility="hidden";
    setItems([...itemsCopy]);
    updateRouteReducer(itemsCopy);    
  };

  const swap = (fromIndex, toIndex, dragData) => {
    console.log("swap in route");
    console.log(dragData);
    let itemsCopy = items.slice();
    const item = {
      ...dragData, 
      uid: shortid.generate(),
      runNumber: null,
      routeId: props.routeData.routeId
    };
    itemsCopy.splice(toIndex, 0, item);
    setItems([...itemsCopy]);
    updateRouteReducer(itemsCopy);
  };

  const kill = (uid) => {
    console.log("kill in route: " + uid);
    let itemsCopy = items.slice();
    const index = itemsCopy.findIndex(item => item.uid === uid);
    if (index !== -1) {
      itemsCopy.splice(index, 1);
      //Fix to Force useState update in React 18      
      flushSync(() => {
        setItems([...itemsCopy]);
      });
      updateRouteReducer(itemsCopy);  
    }
  };

  const addRun = () =>{
    let runsCopy = runs.slice();
    runsCopy.push({
      items: [],
      title: props.routeData.name + ": Run ",
      isLoad: false,
      color: color,
      minimized: "",
    });
    setRuns([...runsCopy]);
    updateRouteReducer(items, runsCopy, false, color, minimized);
  }

  const updateNoteItem = (item) =>{
    if(item){
      let itemsCopy = items.slice();
      let itemToUpdate = itemsCopy.find(i => i.secondaryId === item.orderId);
      if(itemToUpdate){
        itemToUpdate.note = item.note;
        setItems([...itemsCopy]);
      } 
    }     
  }

  const deleteEmptyRun = (index) =>{
    console.log("deleteEmptyRun: " + index);
    let runsCopy = runs.slice();
    if (index >= 0 && index < runsCopy.length && runsCopy[index].items.length === 0) {
      runsCopy.splice(index, 1);
      setRuns([...runsCopy]);
      updateRouteReducer(items, runsCopy, false, color, minimized);
    }      
  }

  const handleColorChange = (e) => { 
    let newColor = e.hex;           
    let runsCopy = runs.slice();
    runsCopy.forEach(run => {
      run.color = newColor;
    });
    setColor(newColor);
    setRuns([...runsCopy]);
    setShowColorPicker(false);
    updateRouteReducer(items, runsCopy, false, newColor, minimized);
  }

  const removeItem = (item) => {
    if(item.manualStop){
      dispatch(removeManualStop(item));
      let itemsCopy = items.slice();
      const index = itemsCopy.findIndex(i => i.id === item.id);
      if (index !== -1) {
        itemsCopy.splice(index, 1);
        setItems([...itemsCopy]);
      }
    }
  }
    
  return (
    <div className={props.orientation === "Horizontal" ? "route" : "row"}>        
      <div className="component_box">
        <DropTarget
          onHit={handleDrop}
          targetKey={minimized === SUPER_MINIMIZED ? "no-drop" : "boxItem"}
          dropData={{name: props.name}}
        >  
        {showColorPicker && 
          (<div className="color-picker">
            <GithubPicker onChangeComplete={ handleColorChange }/>
          </div>)}                              
          <div className="box">                  
            <div className="box-title" style={{backgroundColor: color}}>
              {minimized !== SUPER_MINIMIZED && 
              (<span className="minimize-button float-left" onClick={minimizeOrMaximize}
              title={minimized === "" ? "Minimize" : "Maximize"}>
                <FontAwesomeIcon icon={minimized === "" ? faWindowMinimize : faWindowMaximize} />
              </span>)}
              <span className="super-minimize-button float-left" onClick={superMinimizeOrMaximize}
              title={minimized === SUPER_MINIMIZED ? "Maximize" : "Super-minimize"}>
                <FontAwesomeIcon icon={minimized === SUPER_MINIMIZED ? faArrowAltFromTop : faArrowAltToTop} />
              </span>
              <span className="move-left-button" onClick={moveLeft} 
              title={props.orientation === "Horizontal" ? "Move left" : "Move up"}>
                <FontAwesomeIcon icon={props.orientation === "Horizontal" ? faArrowAltLeft: faArrowAltUp} />
              </span>
              {props.routeData.name}
              <span className="move-left-button" onClick={moveRight} 
                title={props.orientation === "Horizontal" ? "Move right" : "Move down"}>
                <FontAwesomeIcon icon={props.orientation === "Horizontal" ? faArrowAltRight : faArrowAltDown} />
              </span>
              <span className="color-picker-button float-right" 
                onClick={() => setShowColorPicker(!showColorPicker)} title="Change color">
                <FontAwesomeIcon icon={faPalette} />
              </span>
              </div>
            <hr/>
            <div className={"box-body " + minimized}>
              {items.map((item, index) => {
                return (<Item key={item.uid} uid={item.uid} index={index} itemData={item}
                  kill={kill} swap={swap} showMoreInfo={props.showMoreInfo}
                  hidden={minimized !== ""} editNote={editNote} selectItem={props.selectItem} updateShipDate={props.updateShipDate}
                  updateTargetedShipDate={props.updateTargetedShipDate} remove={removeItem}/>)
              })}                     
            </div>
            <div className={"box-footer " + minimized}>
              {minimized !== SUPER_MINIMIZED  && 
              (<div>
                <div style={{height:"10px"}}></div>
                <span> Total items: {items.length}</span>
                <br/>
                {minimized !== MINIMIZED && (<button className="btn btn-secondary" onClick={addRun}>Add Run</button>)}
              </div>)}  
            </div>
          </div>
        </DropTarget>
      </div>
      {runs.map((run, index) => {
          let runTitlePrefix = run.title === undefined || run.title.trim() === '' ? props.routeData.name + ": Run " : run.title; 
          let runTitle = runTitlePrefix + (runTitlePrefix.startsWith(props.routeData.name) && isNaN(runTitlePrefix.trim().slice(-1)) ? (index + 1) : '');
          return (
          <Run key={run.uid ?? index} index={index} items={run.items} color={run.color} routeColor={color} isLoad={run.isLoad} 
            title={runTitle} routeId={props.routeData.routeId} showPrintLoadDialog={run.showPrintLoadDialog}
            deleteOnEmpty={deleteEmptyRun} showMoreInfo={props.showMoreInfo} google={props.google} minimized={run.minimized}
            editNote={editNote} currentItemNote={currentItemNote} selectItem={props.selectItem}
            refreshRoutesData={props.refreshRoutesData} filterMapLocations={props.filterMapLocations}
            updateShipDate={props.updateShipDate} updateTargetedShipDate={props.updateTargetedShipDate} 
            addManualStop={props.addManualStop} capstoneVersion={props.capstoneVersion}/>)
        })}
      <Modal
        className="modal-spinner"
        show={wait}
        centered
        onHide={(e) => {}}
      >
        <Spinner animation="border" role="status" />
        <span className="modal-spinner-text">
          {MESSAGES_CONSTANTS.MODAL_SPINNER_MESSAGE}</span>
      </Modal>
      <CustomModal modalData={modalData} hideModal={hideModal}/>         
    </div>);    
}