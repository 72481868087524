import { BaseRestService } from "./BaseRestService.js";

export class LoadService extends BaseRestService {

  CreateLoad(session, warehouseId, shipmentDate, driverId, truckId, invoices, routeId, runNumber) {
    var load = {
      session: session,
      RouteId: routeId,
      RunNumber: runNumber,
      LoadHeader: {
        Warehouse_Id: warehouseId,
        Delivery_Date: new Date(shipmentDate),
        Driver_Id: driverId,
        Truck_Id: truckId,
        LoadDetails: [],                
      }};

    invoices.forEach(item => {      
      load.LoadHeader.LoadDetails.push({
        SalesInvoiceHeader_Id: item.id,
        PackingListTotalWeight: item.totalWeight,
        ScanVerification_Flag: false
      });      
    });

    return super.CustomFetch("Load/Add", "", 'POST', load)
  }

  UpdateLoad(session, loadHeaderId, driverId, truckId, invoices) {
    var load = {
      session: session,
      loadHeader: {
        LoadHeader_Id: loadHeaderId,
        Driver_Id: driverId,
        Truck_Id: truckId,
        LoadDetails: [],
    }};

    invoices.forEach(item => {      
      load.loadHeader.LoadDetails.push({
        SalesInvoiceHeader_Id: item.id,
      });      
    });

    return super.CustomFetch("Load/Update", "", 'PUT', load);
  }

  DeleteLoad(session, loadHeaderId) {
    var load = {
      session: session,
      loadHeader: {
        LoadHeader_Id: loadHeaderId
      }
    };
    return super.CustomFetch("Load/Delete", "?loadHeaderId=" + loadHeaderId, 'DELETE', load);
  }

  GetLoad(session, loadHeaderId) {
    var load = {
      session: session,
      loadHeader: {
        LoadHeader_Id: loadHeaderId
      }
    };

    return super.CustomFetch("Load/Get", "?loadHeaderId=" + loadHeaderId, 'POST', load);
  }

  DeleteLoadDetail(session, loadHeaderId, invoiceHeaderId) {
    var load = {
      session: session,
      loadHeader: {
        LoadHeader_Id: loadHeaderId
      }
    };
    return super.CustomFetch("Load/DeleteDetail", ("?loadHeaderId=" + loadHeaderId + "&invoiceHeaderId=" + invoiceHeaderId), 'DELETE', load);
  }

  PrintDriverManifest(session, loadHeaderId, imageMapUrl, directPrint=false) {
    var load = {
      session: session,
      imageMapUrl: imageMapUrl,
      loadHeader: {
        LoadHeader_Id: loadHeaderId
      }
    };
    return super.CustomFetch("Load/printDriverManifest", 
    ("?loadHeaderId=" + loadHeaderId + "&directPrint=" + directPrint),
    'POST', load);
  }

  DownloadDriverManifest(session, loadHeaderId) {
    var resultResponse = {
      result: undefined,
      error: undefined,
    };

    var load = {
      session: session,
      loadHeader: {
        LoadHeader_Id: loadHeaderId
      }
    };

    return fetch(this.baseUrl + "Load/DownloadDriverManifest?loadHeaderId=" + loadHeaderId, { 
      method: 'POST', 
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(load)
    })
    .then(response => {
      if(response.status === 200){
        return response.arrayBuffer();//Not Json
      }
      else{
        resultResponse.error = response.statusText;
        return resultResponse;
      }
    })
    .then(response => {
      console.log("PrintDriverManifest");
      console.log(response);
      resultResponse.result = response;
      return resultResponse;
    })
    .catch(error => {
      console.log("Error on PrintDriverManifest");
      console.log(error);
      resultResponse.error = error;
      return resultResponse;
    });
  }

}
