import { useState } from "react";
import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";

export default function useModal(){

    const intialModalData = {
        show: false,
        message: "",
        title: "",
        firstButtonText: undefined,
        firstButtonCallback: undefined,
        secondButtonText: undefined,
        secondButtonCallback: undefined,
    };    
    const [modalData, setModalData] = useState(intialModalData);

    function showModal(message, title, firstButtonText, firstButtonCallback, secondButtonText, secondButtonCallback){        
        setModalData({
            show: true,
            message: message,
            title: title ? title : MESSAGES_CONSTANTS.MODAL_ERROR_TITLE,
            firstButtonText: firstButtonText ? firstButtonText : MESSAGES_CONSTANTS.MODAL_OK_OPTION,
            firstButtonCallback: firstButtonCallback ? firstButtonCallback : hideModal,
            secondButtonText: secondButtonText,
            secondButtonCallback: secondButtonCallback,
        });  
    };

    function hideModal(){
        setModalData(intialModalData);
    }

    return [modalData, showModal, hideModal]
};