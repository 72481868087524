import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';

class DateInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateString: props.value,
      disabled: props.disabled,
      focusDate: props.focusDate,
      touched: props.touched,
      errors: props.errors,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ dateString: this.props.value });
    }
    if (this.props.disabled !== prevProps.disabled) {
      this.setState({ disabled: this.props.disabled });
    }
    if (this.props.touched !== prevProps.touched) {
      this.setState({ touched: this.props.touched });
    }
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });
    }
    if (this.props.focusDate !== prevProps.focusDate) {
      this.setState({ focusDate: this.props.focusDate });
      if (this.props.focusDate) {
        this.setfocus();
      }
    }
  }

  render() {
    return (
      <div className={"custom-row " + this.props.className}>        
        <input
          id={this.props.id}
          required={this.props.required}
          className={
            "form-control" +
            (this.state.touched && this.state.errors ? "invalid" : "")
          }
          value={this.state.dateString}          
          onChange={this.onChangeCalendarInput}
          disabled={this.state.disabled}
          ref={this.props.ref}
          autoComplete={"off"}
          onKeyDown={this.onKeyDown}
        />
        &nbsp;
        <Button
          className="btn-square btn btn-primary"
          disabled={this.state.disabled}
          onClick={this.props.onClick}>
          <FontAwesomeIcon icon={faCalendarAlt} />
        </Button>
      </div>
    );
  }

  onChangeCalendarInput = (event) => {
    var value = event.target.value;

    const regexEditingDate = /^\d{0,2}(\/)?\d{0,2}(\/)?\d{0,4}$/;

    let valid = regexEditingDate.test(value);

    if (valid === true) {
      if (value.length === 8 && value.indexOf("/") === -1) {
        value =
          value.substring(0, 2) +
          "/" +
          value.substring(2, 4) +
          "/" +
          value.substring(4, 8);
      }
    }
    this.setState({
      dateString: value,
    });

    if (this.validDate(value)) {
      this.props.setDate(true, value);
    } else {
      this.props.setDate(false, value);
    }
  };

  setfocus = () => {
    let date = document.getElementById(this.props.id);
    setTimeout((e) => {
      date.focus();
    }, 200);
  };

  validDate = (value) => {
    const regexValidDate = /^(0?[1-9]|1[12])[/]?(0?[1-9]|[12][0-9]|3[01])[/]\d{4}$/;
    let validDate = regexValidDate.test(value);
    return validDate;
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (this.props.onEnterKeyDown) {
        this.props.onEnterKeyDown(event);
      }
    }
  }
}

export default DateInput;
