import { WarehouseService } from "../soapServices/WarehouseService.js";
import { SalesOrderService } from "../restServices/SalesOrderService.js";
import { SalesInvoiceService } from "../restServices/SalesInvoiceService.js";
import { LoadService } from "../restServices/LoadService";
import utils from "../utils/utils.js";
import { ROUTE_COLORS, ROUTE_DAYS } from "../utils/routesConstants";
var shortid = require('shortid');

const initialState = {  
  routesData: [],
  mapLocations: [],
  mapFilterOptions: [],
  planningDate: null,
  directionsDisplay: undefined,
};

var salesInvoiceService = undefined;
var salesOrderService = undefined;
var warehouseService = undefined;
var loadService = undefined;

export const initServices = (capstoneWebAPI, capstoneServiceUrl, capstoneVersion) => {
  salesInvoiceService = new SalesInvoiceService(capstoneWebAPI);
  salesOrderService = new SalesOrderService(capstoneWebAPI); 
  loadService = new LoadService(capstoneWebAPI);  
  warehouseService = new WarehouseService(capstoneServiceUrl, capstoneVersion);
  console.log("Routing services init") 
};

const routesReducer = (state = initialState, action) => {
  switch (action.type) {   
    case "setRoutes":
      let mapFilters = updateMapFilters(action.routes);
      return {
        ...state,
        routesData: action.routes,
        mapLocations: action.mapLocations ? action.mapLocations : state.mapLocations,
        mapFilterOptions: mapFilters,
        itemsOutOfRouteDate: action.itemsOutOfRouteDate ? action.itemsOutOfRouteDate : state.itemsOutOfRouteDate,
        allRoutes: action.allRoutes ? action.allRoutes : state.allRoutes
      };
    case "updateRoute":
      let routesCopy = state.routesData.slice();
      if(routesCopy.length-1 >= action.index && routesCopy[action.index]){
        routesCopy[action.index].items = action.items;
        routesCopy[action.index].runs = action.runs;
        routesCopy[action.index].color = action.color;
        routesCopy[action.index].minimized = action.minimized;
      }
      let mapFilters2 = updateMapFilters(routesCopy); 
      sessionStorage.setItem("routes",JSON.stringify(routesCopy));     
      return {
        ...state,
        routesData: routesCopy,
        mapFilterOptions: mapFilters2,
      };
    case "updateRun":
      let routesCopy2 = state.routesData.slice();
      let route = routesCopy2.find(r => r.routeId === action.routeId);
      if(route.runs.length-1 >= action.runIndex && route.runs[action.runIndex]){

        route.runs[action.runIndex].items = action.items;
        route.runs[action.runIndex].color = action.color;
        if(action.minimized !== undefined)
          route.runs[action.runIndex].minimized = action.minimized;
        if(action.loadCreated !== undefined)
          route.runs[action.runIndex].isLoad = action.loadCreated;
        if(action.title !== undefined)
          route.runs[action.runIndex].title = action.title; 
        if(action.showPrintLoadDialog !== undefined)
          route.runs[action.runIndex].showPrintLoadDialog = action.showPrintLoadDialog;
      }

      let mapFilters3 = updateMapFilters(routesCopy2);
      console.log(routesCopy2)
      sessionStorage.setItem("routes",JSON.stringify(routesCopy2));
      return {
        ...state,
        routesData: routesCopy2,
        mapLocations: action.mapLocations,
        mapFilterOptions: mapFilters3
      };
    case "setDirectionsDisplay":
      return {
        ...state,
        directionsDisplay: action.directionsDisplay
      };
    default:
      return state;
  }
};

export const setRoutes = (routes, mapLocations) => {
  return (dispatch) => {
    console.log("setRoutes")
    sessionStorage.setItem("routes",JSON.stringify(routes));
    dispatch({
      type: "setRoutes",
      routes: routes,
      mapLocations: mapLocations
    });
  };
};

export const setDirectionsDisplay = (directionsDisplay) => {
  return (dispatch) => {
    dispatch({
      type: "setDirectionsDisplay",
      directionsDisplay: directionsDisplay,
    });
  };
};

export const getDefaultPlanningDate = (session, showSalesOrders, planningDateParam) => {
  return (dispatch, getState) => {

    // in case planning date was set manually
    if(planningDateParam) return new Promise(resolve => resolve(null));

    let today = utils.formatDate(new Date());
    console.log(today)
    let planingDateResult = null;
    //Querying default planning date otherwise set to todays date
    let promisesForDefaultPlaningDate = [salesInvoiceService.GetPlanningDate(session,today)];
    if(showSalesOrders){
      promisesForDefaultPlaningDate.push(salesOrderService.GetPlanningDate(session, today));
    }    

    return Promise.all(promisesForDefaultPlaningDate).then(responsesForPlaningDates => {
      console.log(responsesForPlaningDates);      
      if(responsesForPlaningDates[0] && responsesForPlaningDates[0].result && responsesForPlaningDates[0].result.planningDate){
        planingDateResult = new Date(responsesForPlaningDates[0].result.planningDate);
      }
      if(responsesForPlaningDates[1] && responsesForPlaningDates[1].result && responsesForPlaningDates[1].result.planningDate &&
        planingDateResult > new Date(responsesForPlaningDates[1].result)){
          planingDateResult = new Date(responsesForPlaningDates[1].result.planningDate);
      }
      console.log(planingDateResult)
      console.log(planingDateResult instanceof Date)
      return (planingDateResult instanceof Date) ? planingDateResult : undefined;
    });
  }
}

export const loadRoutesAndItems = (planningDate, warehouseId, session, filters) => {

  return (dispatch, getState) => {
    
    const routesSaved = JSON.parse(sessionStorage.getItem("routes"));
    console.log(routesSaved);   
    let mapLocations = []; 
    let itemsOutOfRouteDate = [];   
    return queryRoutes(planningDate, warehouseId, session, routesSaved).then(routesResponse => {
      let routes = routesResponse.validDateRoutes;
      //Always query for Sales Invoices
      let promises = [salesInvoiceService.GetSalesInvoicesForRouting(session, warehouseId, planningDate, 
        filters.includeInvoicesPriorDates, filters.includeInvoicesFutureDates)];

      //Check Show Sales Orders filter
      if(filters.showSalesOrders){
        promises.push(salesOrderService.GetOpenSalesOrders(session, 
          warehouseId, planningDate, filters.queryOrdersWithinDeliveryWindow, filters.includeOverdueOrders, false, false));
      }

      var resultResponse = {
        result: undefined,
        error: undefined,
        info: undefined,
      };
      return Promise.all(promises).then(responses => {
        console.log(responses);
        responses.forEach(response => {
          if(response.error){
            resultResponse.error = response.error.exceptionMessage;
            dispatch({
              type: "setRoutes",
              routes: [],
              mapLocations: [],
              itemsOutOfRouteDate: []
            });    
            return resultResponse;
          } 
        });

        if(responses[0].result.length === 0 && (!responses[1] || (responses[1] && responses[1].result.length === 0))){
          resultResponse.info = "No orders or packing lists available for this date.";
          dispatch({
            type: "setRoutes",
            routes: [],
            mapLocations: [],
            itemsOutOfRouteDate: []
          });    
          return resultResponse;
        }

        loadSalesInvoices(responses[0], routes, mapLocations, itemsOutOfRouteDate);
        if(responses[1]){
          loadOpenSalesOrders(responses[1], routes, mapLocations, itemsOutOfRouteDate);
        }  
        
        routes.forEach(route => {
          route.runs.forEach((run, index) => {
            if(run.items.length === 0){
              route.runs.splice(index, 1);
            }
            else{            
              run.showPrintLoadDialog = undefined;
            }
          });
        });

        console.log(routes);
        sessionStorage.setItem("routes",JSON.stringify(routes));

        if(itemsOutOfRouteDate.length > 0){
          resultResponse.info = "There are Sales Orders or Packing Lists that cannot be grouped into the displayed routes, " +
          "because their associated routes do not run on the selected planning date.";
        }

        dispatch({
          type: "setRoutes",
          routes: routes,
          mapLocations: mapLocations,
          itemsOutOfRouteDate: itemsOutOfRouteDate,
          allRoutes: routesResponse.allRoutes
        });
        resultResponse.mapLocations = mapLocations;
        resultResponse.routes = routes;
        return resultResponse;
      });
    });
  }
}

const queryRoutes = (planningDate, warehouseId, session, routesSaved) => {
  const planingDayOfWeek = planningDate.getDay(); 
  return warehouseService.GetAllActiveWarehouseDeliveryRoutesForWarehouse(session, warehouseId)
  .then(routesResult =>{
    let validDateRoutes = [];
    routesResult.result.forEach((route, index) => {
      let isValidDay = ROUTE_DAYS[planingDayOfWeek];
      if(route[isValidDay] === "true"){

        let routeIndexSaved = routesSaved && Array.isArray(routesSaved) ?
        routesSaved.findIndex(r => r.routeId === route.RouteId) : 0;
        let routeSaved = routesSaved && Array.isArray(routesSaved) && routeIndexSaved !== -1 ? 
          routesSaved[routeIndexSaved] : undefined;

        let runs = routeSaved ? routeSaved.runs : [];
        if (routeSaved) { //do not load saved empty runs
          let runsWithItems = [];
          routeSaved.runs.forEach(run => {
            if(run.items && run.items.length > 0){
              runsWithItems.push({
                ...run, 
                items:[], 
                isLoad: false,
                uid : shortid.generate(),
              });
            }
          });
          runs = runsWithItems;
        }

        validDateRoutes.splice(routeIndexSaved, 0, {
          routeId: route.RouteId,
          name: route.Name,
          items: [],
          color: routeSaved ? routeSaved.color : index < ROUTE_COLORS.length ? ROUTE_COLORS[index] : '',            
          minimized: !routeSaved || typeof routeSaved.minimized === 'boolean' ? "" : routeSaved.minimized,
          runs: runs,
          uid : shortid.generate(), 
        });
      }
    });
    console.log(validDateRoutes)
    return {
      allRoutes: routesResult.result,
      validDateRoutes: validDateRoutes,
    }
  });
}

const loadOpenSalesOrders = (openOrdersResult, routes, mapLocations, itemsOutOfRouteDate) => {
  if(!openOrdersResult.error){
    openOrdersResult.result.forEach(order => {
      let currentRoute = routes.find(r => r.routeId === order.routeId?.toString());
      if(currentRoute){
        let currentRun = undefined;
        if(order.runNumber != null && order.runNumber !== undefined){
          currentRun = pushRunItem(currentRoute, order);
        }
        else{
          currentRoute.items.push(order);
        }
        var position = {
          id: order.id,
          lat: order.lat, 
          lng: order.lng, 
          label: order.label + (order.cityAndState ? " - " + order.cityAndState : ""),
          deliveryAddressName: order.deliveryAddressName,  
          color: currentRun?.color ?? currentRoute.color,
          routeId: currentRoute.routeId,
          runNumber: order.runNumber,
          sequenceNumber: order.sequenceNumber,
        };
        mapLocations.push(position);            
      }
      else{
        itemsOutOfRouteDate.push(order);
      }
    });        
  }
}

const loadSalesInvoices = (invoicesResult, routes, mapLocations, itemsOutOfRouteDate) => {
  if(!invoicesResult.error){
    invoicesResult.result.forEach(invoice => {
      let currentRoute = routes.find(r => r.routeId === invoice.routeId?.toString());
      if(currentRoute){        
        let currentRun = undefined;        
        if(invoice.runNumber != null && invoice.runNumber !== undefined){
          currentRun = pushRunItem(currentRoute, invoice);
        }
        else{
          currentRoute.items.push(invoice);
        }        
        var position = {
          id: invoice.id,
          lat: invoice.lat, 
          lng: invoice.lng,
          label: invoice.label + (invoice.cityAndState ? " - " + invoice.cityAndState : ""),
          deliveryAddressName: invoice.deliveryAddressName, 
          color: currentRun?.color ?? currentRoute.color,
          routeId: currentRoute.routeId,
          runNumber: invoice.runNumber,
          sequenceNumber: invoice.sequenceNumber,
        };
        mapLocations.push(position);
      }
      else{
        itemsOutOfRouteDate.push(invoice);
      }
    }); 
  }
}

const pushRunItem = (route, item) => {  
  while(!route.runs[item.runNumber] && route.runs.length <= item.runNumber){        
    route.runs.push({ 
      title: route.name + ": Run ",
      items: [],
      isLoad: false,
      color: route.color,
      minimized: ""
    });
  }
  let itemIndex = route.runs[item.runNumber].items.findIndex(i => i.id === item.id);
  if(itemIndex === -1){
    if(route.runs[item.runNumber].isLoad && !item.hasLoad){
      route.items.push(item);
    }
    else{
      route.runs[item.runNumber].items.push(item);
    }
    //route.runs[item.runNumber].items.push(item);
    
    if(item.hasLoad){
      route.runs[item.runNumber].isLoad = true;
    }
    return route.runs[item.runNumber];
  }  
}

export const updateRoute = (routeParam, updateDb = true, session) => {
  return (dispatch, getState) => {
    const state = getState();
    let mapLocationsState = state.routesReducer.mapLocations.slice();
    updateMapLocations(routeParam.items, mapLocationsState, routeParam.routeId, null, routeParam.color);
    let updatePromises = [];
    if(updateDb && routeParam.items){    
      updatePromises = updateItems(session, routeParam.items, routeParam.routeId, null);
    }  
    
    return Promise.all(updatePromises).then(responses =>{        
      dispatch({
        type: "updateRoute",
        index: routeParam.routeIndex,
        runs: routeParam.runs,
        items: routeParam.items,
        color: routeParam.color,
        minimized: routeParam.minimized
      });
      return responses;
    });
  };
};

export const moveRoute = (indexToMove, direction) => {
  return (dispatch, getState) => {
    const state = getState();
    let routes = state.routesReducer.routesData.slice();
    let routeToMove = { 
      ...routes[indexToMove],
      uid : shortid.generate(),
     };
    routeToMove.runs.forEach(run => {
      run.uid = shortid.generate();
      run.items = [...run.items];
    });
    routes.splice(indexToMove, 1);
    if(indexToMove === 0 && direction === -1){ //If move first to the left
      routes.push(routeToMove);
    }
    else if(indexToMove === routes.length && direction === 1){ //If move last to the right
      routes.splice(0, 0 , routeToMove);
    }
    else{
      routes.splice(indexToMove + direction, 0 , routeToMove);
    }

    sessionStorage.setItem("routes",JSON.stringify(routes));
    dispatch({
      type: "setRoutes",
      routes: routes,
    });
  }
}

export const updateRun = (runParam, updateDb = true, session) => {
  return (dispatch, getState) => {
    const state = getState();
    let mapLocationsState = state.routesReducer.mapLocations.slice();
    updateMapLocations(runParam.items, mapLocationsState, runParam.routeId, runParam.runIndex, runParam.color);
    let updatePromises = [];
    if(updateDb && runParam.items){    
      updatePromises = updateItems(session, runParam.items, runParam.routeId, runParam.runIndex);
    }  
    return Promise.all(updatePromises).then(responses =>{        
      dispatch({
        type: "updateRun",
        routeId: runParam.routeId,
        runIndex: runParam.runIndex,
        items: runParam.items,
        color: runParam.color,
        minimized: runParam.minimized,
        loadCreated: runParam.loadCreated,
        title: runParam.title,
        showPrintLoadDialog: runParam.showPrintLoadDialog,
        mapLocations: mapLocationsState
      });
      return responses;
    });
  };
};

export const updateItems = (session, items, routeId, runIndex) =>{
  let ordersToSave = [];
  let invoicesToSave = [];
  items.forEach((item, index) => {
    if(item.manualStop === undefined){
      let itemToSave = {
        id: item.id,
        routeId: routeId,
        runNumber: runIndex,
        stopSequenceNumber: index
      }
      item.sequenceNumber = index;
      if(item.label.startsWith("PL#")){
        invoicesToSave.push(itemToSave);
      }
      else{
        ordersToSave.push(itemToSave);
      }
    }
  });
  let promises = [];
  if(invoicesToSave.length > 0){        
    promises.push(salesInvoiceService.Update(session, invoicesToSave));
  }
  if(ordersToSave.length > 0){
    promises.push(salesOrderService.Update(session, ordersToSave));
  }
  return promises;
}

const updateMapLocations = (items, mapLocations, routeId, runIndex, newColor) => {
  if(items){
    items.forEach((item, index) => {
      let position = mapLocations.find(p => p.id === item.id);
      if(position){
        position.routeId = routeId;
        position.runNumber = runIndex;
        position.color = newColor;
        position.sequenceNumber = index;
      }
      else if(item.manualStop){
        var newPosition = {
          id: item.id,
          lat: item.lat, 
          lng: item.lng,
          label: item.label + (item.cityAndState ? " - " + item.cityAndState : ""),
          color: newColor,
          routeId: routeId,
          runNumber: runIndex,
          sequenceNumber: items.length,
        };
        mapLocations.push(newPosition);
      }
    });
  }
}

const updateMapFilters = (routes) => {
  let mapFilterOptions = []; 
  if(routes){     
    routes.forEach(route => {
      mapFilterOptions.push({id: route.routeId, label: route.name, color: route.color});
      if(route.runs){
        route.runs.forEach((run, index) =>{
          mapFilterOptions.push({            
            id: route.routeId + '-' + index, 
            label: run.title + (run.title.startsWith(route.name) && isNaN(run.title.trim().slice(-1)) ? (index + 1) : '') + (run.isLoad ? ' (Load)' : ''),
            color: run.color
          });
        });
      }
    });
  }
  //console.log(mapFilterOptions);
  return mapFilterOptions;
}

export const removeManualStop = (item) => {
  return (dispatch, getState) => {
    const state = getState();
    let routes = state.routesReducer.routesData.slice();
    let mapLocationsState = state.routesReducer.mapLocations.slice();
    let routeIndex = routes.findIndex(route => route.routeId === item.routeId?.toString());
    let routeToChange = { ...routes[routeIndex] };

    if(item.runNumber !== undefined && item.runNumber !== null){
      let runToChange = {
        ...routeToChange.runs[item.runNumber],
      };
      let indexToRemove = runToChange.items.findIndex(i => i.id === item.id);
      runToChange.items.splice(indexToRemove, 1);      
      routeToChange.runs[item.runNumber] = runToChange;
      routes[routeIndex] = routeToChange;
    }
    else{
      let indexToRemove = routeToChange.items.findIndex(i => i.id === item.id);
      routeToChange.items.splice(indexToRemove, 1);
      routes[routeIndex] = routeToChange;
    }

    let mapLocationIndex = mapLocationsState.findIndex(i => i.id === item.id);
    if(mapLocationIndex !== -1){
      mapLocationsState.splice(mapLocationIndex, 1);
    }

    sessionStorage.setItem("routes",JSON.stringify(routes));
    dispatch({
      type: "setRoutes",
      routes: routes,
      mapLocations: mapLocationsState
    });
  }
}

export const removeFromLoad = (item, loadNumber, session) => {  
  return (dispatch, getState) => {
    const state = getState();
    let routes = state.routesReducer.routesData.slice();
    let mapLocationsState = state.routesReducer.mapLocations.slice();
    let routeIndex = routes.findIndex(route => route.routeId === item.routeId?.toString());
    let routeToChange = { ...routes[routeIndex] };
    
    var resultResponse = {
      result: undefined,
      error: undefined,
      packingListAlreadyRemoved: undefined,
      deleteLoadError: undefined,
    };

    return salesInvoiceService.GetSalesInvoices(session, item.id)
    .then(getInvoiceResult => {
      if(getInvoiceResult.error){
        resultResponse.error = getInvoiceResult.error.exceptionMessage ? getInvoiceResult.error.exceptionMessage : "An error has ocurred.";
        return resultResponse;
      } 

      let invoice = getInvoiceResult.result.length > 0 ? getInvoiceResult.result[0] : undefined;
      if(invoice && invoice.status_Type !== 2 && invoice.status_Type !== 3){
        if(invoice.loadHeader_Id == null){
          resultResponse.packingListAlreadyRemoved = "This packing list was already removed from this load.";          
          return resultResponse;
        }
        return loadService.DeleteLoadDetail(session, loadNumber, item.id)
        .then(deleteResponse => {
          if(deleteResponse.error){
            resultResponse.deleteLoadError = deleteResponse.error.exceptionMessage;
            resultResponse.result = invoice;
            return resultResponse;
          }
          if(deleteResponse.result.error){
            resultResponse.deleteLoadError = deleteResponse.error.exceptionMessage;
            resultResponse.result = invoice;
            return resultResponse;
          }
          else if(deleteResponse.result === 1){             
            routeToChange.items.push({
              ...item, 
              runNumber: null,
              uid : shortid.generate()
            });

            let runToChange = {
              ...routeToChange.runs[invoice.runNumber],
              showPrintLoadDialog: true
            };

            let indexToRemove = runToChange.items.findIndex(i => i.id === item.id);
            runToChange.items.splice(indexToRemove, 1);
            
            routeToChange.runs[invoice.runNumber] = runToChange;
            routes[routeIndex] = routeToChange;

            let mapLocation = mapLocationsState.find(i => i.id === item.id);
            if(mapLocation){
              mapLocation.runNumber = null;
              mapLocation.sequenceNumber = null;
            }

            sessionStorage.setItem("routes",JSON.stringify(routes));
            dispatch({
              type: "setRoutes",
              routes: routes,
              mapLocations: mapLocationsState
            });

            //Removing item from load and nulling load number
            let invoiceToUpdate = [{
              id: invoice.salesInvoiceHeader_Id,
              routeId: routeToChange.routeId,
              runNumber: null,
              stopSequenceNumber: routeToChange.items.length
            }];
            return salesInvoiceService.Update(session, invoiceToUpdate).then(result => {
              resultResponse.result = result;
              return resultResponse;
            });
          }
        });
      }
      else{
        resultResponse.error = "You cannot modify the load for invoiced shipments.";
        return resultResponse;
      }
    });
  }
}

export const deleteLoad = (routeId, loadNumber, invoice, session) =>{
  return (dispatch, getState) => {
    const state = getState();
    let routes = state.routesReducer.routesData.slice();
    let mapLocationsState = state.routesReducer.mapLocations.slice();
    let routeIndex = routes.findIndex(route => route.routeId === routeId?.toString());
    let routeToChange = { ...routes[routeIndex] };
    
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return loadService.DeleteLoad(session, loadNumber)
    .then(deleteLoadResult => {
      if(deleteLoadResult.result >= 1){
        let runs = routeToChange.runs.slice();         
        let editingItem = runs[invoice.runNumber].items.find(runItem => runItem.id === invoice.salesInvoiceHeader_Id);
        editingItem.runNumber = null;
        routeToChange.items.push({
          ...editingItem, 
          uid : shortid.generate()
        });

        routeToChange.runs.splice(invoice.runNumber, 1);

        routes[routeIndex] = routeToChange;
        sessionStorage.setItem("routes",JSON.stringify(routes));

        let mapLocation = mapLocationsState.find(i => i.id === invoice.salesInvoiceHeader_Id);
        console.log(mapLocation)
        if(mapLocation){          
          mapLocation.runNumber = null;
          mapLocation.sequenceNumber = null;
        }
        console.log(mapLocation)
        console.log(mapLocationsState)

        dispatch({
          type: "setRoutes",
          routes: routes,
          mapLocations: mapLocationsState
        });

        //Removing item from load and nulling load number
        let invoiceToUpdate = [{
          id: invoice.salesInvoiceHeader_Id,
          routeId: routeToChange.routeId,
          runNumber: null,
          stopSequenceNumber: routeToChange.items.length
        }]; 
        return salesInvoiceService.Update(session, invoiceToUpdate).then(result => {
          resultResponse.result = result;
          return resultResponse;
        });
      }
    });
  }
}
export default routesReducer;