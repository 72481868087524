import { menuTypes } from "../utils/menuTypes.js";

const initialState = {
  currentWarehouse: "",
  currentPageName: "",
  showBackOption: false,
  userMenuAccess: undefined,
  menuItems: [], //Array.from(menuTypes),
  previousPage: undefined,
  routesOrientation: "Horizontal",
  filters: undefined,
};

const menuInfo = (state = initialState, action) => {
  switch (action.type) {
    case "setCurrentWarehouse":
      return {
        ...state,
        currentWarehouse: action.currentWarehouse,
      };
    case "setMenuInfo":
      return {
        ...state,
        currentPageName: action.currentPageName,
        showBackOption: action.showBackOption,
      };
    case "setPreviousPage":
      return {
        ...state,
        previousPage: action.previousPage,
      };
    case "setUserMenuAccess":
      return {
        ...state,
        userMenuAccess: action.userMenuAccess,
        isAdmin: action.isAdmin,
        menuItems: action.menuItems,
      };
    case "setRoutingConfig":
      return {
        ...state,
        routesOrientation: action.routesOrientation,
      };
    case "setFilters":
      return {
        ...state,
        filters: action.filters,
      };
    default:
      return state;
  }
};

export const setRoutingConfig = (routesOrientation) => {
  return (dispatch) => {
    localStorage.setItem(
      "routesOrientation",
      JSON.stringify(routesOrientation)
    );
    dispatch({
      type: "setRoutingConfig",
      routesOrientation: routesOrientation,
    });
  };
};

export const setCurrentWarehouse = (currentWarehouse) => {
  return (dispatch) => {
    sessionStorage.setItem(
      "currentWarehouse",
      JSON.stringify(currentWarehouse)
    );
    dispatch({
      type: "setCurrentWarehouse",
      currentWarehouse: currentWarehouse,
    });
  };
};

export const setMenuInfo = (
  currentPageName,
  showBackOption = false
) => {
  return (dispatch) => {
    dispatch({
      type: "setMenuInfo",
      currentPageName: currentPageName,
      showBackOption: showBackOption,
    });
  };
};


export const setPreviousPage = (previousPage) => {
  localStorage.setItem("previousPage", previousPage);
  return (dispatch) => {
    dispatch({
      type: "setPreviousPage",
      previousPage: previousPage,
    });
  };
};

export const setFiltersReducer = (filters) => {
  localStorage.setItem("filters", JSON.stringify(filters));
  return (dispatch) => {
    dispatch({
      type: "setFilters",
      filters: filters,
    });
  };
};

export const setUserMenuAccess = (userMenuAccess, isAdmin) => {
  sessionStorage.setItem("userMenuAccess", JSON.stringify(userMenuAccess));
  let currentMenu = [];
  console.log("is Admin: " + isAdmin);
  if(isAdmin){
    currentMenu = Array.from(menuTypes);
    setAdminAccess(currentMenu);
  }
  else if (userMenuAccess) {
    currentMenu = Array.from(menuTypes);
    findAndSetMenuAccess(currentMenu, userMenuAccess);
  }

  console.log(currentMenu);
  sessionStorage.setItem("menuItems", JSON.stringify(currentMenu));
  return (dispatch) => {
    dispatch({
      type: "setUserMenuAccess",
      userMenuAccess: userMenuAccess,
      menuItems: currentMenu,
    });
  };
};

function findAndSetMenuAccess(currentMenu, userMenuAccess) {
  currentMenu.forEach((menuItem) => {
    if (menuItem.items === undefined) {
      let accessFound = userMenuAccess.find(
        (access) => access.ProgramId === menuItem.programId
      );
      // console.log("accessFound for " + menuItem.text);
      // console.log(accessFound);
      menuItem.access =
        (accessFound && accessFound.AccessType === "2") || !menuItem.programId
          ? "Y"
          : "N";
      return;
    } else {
      findAndSetMenuAccess(menuItem.items, userMenuAccess);
    }
  });
}

function setAdminAccess(currentMenu) {
  currentMenu.forEach((menuItem) => {
    if (menuItem.items === undefined) {
      menuItem.access = "Y";
      return;
    } else {
      setAdminAccess(menuItem.items);
    }
  });
}
export default menuInfo;