import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "./auth";
import menuInfo from "./menuInfo";
import routesReducer from "./routesReducer";
import pickupReducer from "./pickupReducer";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    menuInfo,
    routesReducer,
    pickupReducer
  });

  export default rootReducer;
