import { BaseSoapService } from "./BaseSoapService.js";

export class SalesOrderServiceSOAP extends BaseSoapService {
  constructor(baseUrl, capstoneVersion) {
    super(baseUrl, "SalesOrderService.asmx", "SalesOrderService", capstoneVersion);
  }

  GetOpenSalesOrders(session, warehouseId) {
    var args = {
      session: session,
      warehouseId: warehouseId,
      excludeFullyShippedOrdersFlag: false
    };
    var resultResponse = {
      result: [],
      error: undefined,
    };
    return super.makeSoapCall("GetOpenSalesOrders", args).then(
      (result) => {
        console.log("GetOpenSalesOrders");
        console.log(result);
        if (
          result.GetOpenSalesOrdersResponse &&
          result.GetOpenSalesOrdersResponse.GetOpenSalesOrdersResult
        ) {
          var orders =
            result.GetOpenSalesOrdersResponse
            .GetOpenSalesOrdersResult.OpenSalesOrder;
          if (orders && Array.isArray(orders)) {
            resultResponse.result = orders;
          } else if (orders) {
            resultResponse.result = [orders];
          }
        }
        return resultResponse;
      },
      (error) => {
        resultResponse.error = error;
        console.log("Error on GetOpenSalesOrders:");
        console.log(error);
        return resultResponse;
      }
    );
  }

  GetSalesOrderHeaderForLookup(session) {
    var args = {
      session: session,
      returnClosedOrdersFlag: false
    };
    var resultResponse = {
      result: [],
      error: undefined,
    };
    return super.makeSoapCall("GetSalesOrderHeaderForLookup", args).then(
      (result) => {
        console.log("GetSalesOrderHeaderForLookup");
        console.log(result);
        if (
          result.GetSalesOrderHeaderForLookupResponse &&
          result.GetSalesOrderHeaderForLookupResponse.GetSalesOrderHeaderForLookupResult
        ) {
          var orders =
            result.GetSalesOrderHeaderForLookupResponse
            .GetSalesOrderHeaderForLookupResult.GetSalesOrderHeaderForLookupResult;
          if (orders && Array.isArray(orders)) {
            resultResponse.result = orders;
          } else if (orders) {
            resultResponse.result = [orders];
          }
        }
        return resultResponse;
      },
      (error) => {
        resultResponse.error = error;
        console.log("Error on GetSalesOrderHeaderForLookup:");
        console.log(error);
        return resultResponse;
      }
    );
  }

  PrintShipper(session, salesOrderHeaderId, includePricing = false, directPrint = false) {
    var args = {
      session: session,
      salesOrderHeaderId: salesOrderHeaderId,
      printOriginalShipperFlag: true,
      directPrintFlag: directPrint,
      includeExtendedPriceFlag: includePricing,
      salesInvoiceFlag: false
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("PrintShipper", args).then(
      (result) => {
        console.log("PrintShipper");
        console.log(result);
        if (result.PrintShipperResponse && result.PrintShipperResponse.PrintShipperResult) {          
            resultResponse.result = result.PrintShipperResponse.PrintShipperResult
        }        
        return resultResponse;
      },
      (error) => {
        resultResponse.error = error;
        console.log("Error on PrintShipper:");
        console.log(error);
        return resultResponse;
      }
    );
  }

  PrintLoadingTicket(session, salesInvoiceHeaderId, salesOrderHeaderId, directPrint=true) {
    var args = {
      session: session,
      salesInvoiceHeaderId: salesInvoiceHeaderId,
      salesOrderHeaderId: salesOrderHeaderId,
      directPrintFlag: directPrint
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("PrintLoadingTicket", args)
    .then(printResult => {
        console.log("PrintLoadingTicket:");
        console.log(printResult);
        var order = printResult.PrintLoadingTicketResponse.PrintLoadingTicketResult;
        resultResponse.result = order;
        return resultResponse;
      },
      (error) => {
        console.log("Error on PrintLoadingTicket:");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  PrintOrPreviewFulfillmentTicket(session, salesOrderHeaderId, directPrint=true) {
    var args = {
      session: session,
      salesOrderHeaderId: salesOrderHeaderId,
      printOriginalFulfillmentTicketFlag: true,
      directPrintFlag: directPrint
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("PrintOrPreviewFulfillmentTicket", args)
    .then(printResult => {
        console.log("PrintOrPreviewFulfillmentTicket:");
        console.log(printResult);
        var order = printResult.PrintOrPreviewFulfillmentTicketResponse.PrintOrPreviewFulfillmentTicketResult;
        resultResponse.result = order;
        return resultResponse;
      },
      (error) => {
        console.log("Error on PrintOrPreviewFulfillmentTicket:");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  PrintSOPickingTicket(session, salesOrderHeaderId, directPrint=true) {
    var args = {
      session: session,
      salesOrderHeaderId: salesOrderHeaderId,
      printOriginalPickingTicketFlag: true,
      directPrintFlag: directPrint
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("PrintSOPickingTicket", args)
    .then(printResult => {
        console.log("PrintSOPickingTicket:");
        console.log(printResult);
        var order = printResult.PrintSOPickingTicketResponse.PrintSOPickingTicketResult;
        resultResponse.result = order;
        return resultResponse;
      },
      (error) => {
        console.log("Error on PrintSOPickingTicket:");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  PrintSOQuickCutTicket(session, salesOrderHeaderId, directPrint=true) {
    var args = {
      session: session,
      salesOrderHeaderId: salesOrderHeaderId,
      printOriginalQuickCutTicketFlag: true,
      directPrintFlag: directPrint
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("PrintSOQuickCutTicket", args)
    .then(printResult => {
        console.log("PrintSOQuickCutTicket:");
        console.log(printResult);
        var order = printResult.PrintSOQuickCutTicketResponse.PrintSOQuickCutTicketResult;
        resultResponse.result = order;
        return resultResponse;
      },
      (error) => {
        console.log("Error on PrintSOQuickCutTicket:");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  UpdateTargetedDeliveryOrShipOrPickUpDateTimeForSalesOrder(session, salesOrderHeaderId, targetedDeliveryDatetime) {

    var args = {
      session: session,
      salesOrderHeaderId: salesOrderHeaderId,
      TargetedDeliveryOrShipOrPickUpDateTime: targetedDeliveryDatetime
    };
    return super.makeSoapCall("UpdateTargetedDeliveryOrShipOrPickUpDateTimeForSalesOrder", args)
    .then(updateResult => {
        console.log("UpdateTargetedDeliveryOrShipOrPickUpDateTimeForSalesOrder:");
        console.log(updateResult);
        return { result: updateResult?.UpdateTargetedDeliveryOrShipOrPickUpDateTimeForSalesOrderResponse
          ?.UpdateTargetedDeliveryOrShipOrPickUpDateTimeForSalesOrderResult };
      },
      (error) => {
        console.log("Error on UpdateTargetedDeliveryOrShipOrPickUpDateTimeForSalesOrder:");
        console.log(error);
        return { error: error};
      }
    );
  }

  ValidateSalesOrderHeaderWithLock(session, salesOrderHeaderId) {
    var args = {
      session: session,
      salesOrderHeaderId: salesOrderHeaderId
    };
    return super.makeSoapCall("ValidateSalesOrderHeaderWithLock", args)
    .then(orderResult => {
        console.log("ValidateSalesOrderHeaderWithLock:");
        console.log(orderResult);
        return { result: orderResult?.ValidateSalesOrderHeaderWithLockResponse?.ValidateSalesOrderHeaderWithLockResult };
      },
      (error) => {
        console.log("Error on ValidateSalesOrderHeaderWithLock:");
        console.log(error);
        return { error: error};
      }
    );
  }

  UnlockSalesOrderHeaderById(session, salesOrderHeaderId) {
    var args = {
      session: session,
      salesOrderHeaderId: salesOrderHeaderId
    };
    return super.makeSoapCall("UnlockSalesOrderHeaderById", args)
    .then(orderResult => {
        console.log("UnlockSalesOrderHeaderById:");
        console.log(orderResult);
        return { result: orderResult?.UnlockSalesOrderHeaderByIdResponse?.UnlockSalesOrderHeaderByIdResult };
      },
      (error) => {
        console.log("Error on UnlockSalesOrderHeaderById:");
        console.log(error);
        return { error: error};
      }
    );
  }

  GetAllSalesOrderPaymentDetailsForSalesOrder(session, salesOrderHeaderId) {
    var args = {
      session: session,
      salesOrderHeaderId: salesOrderHeaderId
    };
    return super.makeSoapCall("GetAllSalesOrderPaymentDetailsForSalesOrder", args)
    .then(orderResult => {
        console.log("GetAllSalesOrderPaymentDetailsForSalesOrder:");
        console.log(orderResult);
        let payments = orderResult?.GetAllSalesOrderPaymentDetailsForSalesOrderResponse
          ?.GetAllSalesOrderPaymentDetailsForSalesOrderResult?.SalesOrderPaymentDetail ?? [];
        if (payments && Array.isArray(payments)) {
          return { result : payments };
        } else if (payments) {
          return { result: [payments] };
        }
      },
      (error) => {
        console.log("Error on GetAllSalesOrderPaymentDetailsForSalesOrder:");
        console.log(error);
        return { error: error};
      }
    );
  }

  GetTotalUnitPriceExtendedForOrder(session, orderId) {
    var args = {
      session: session,
      orderId: orderId
    };
    return super.makeSoapCall("GetTotalUnitPriceExtendedForOrder", args)
    .then(orderResult => {
        console.log("GetTotalUnitPriceExtendedForOrder:");
        console.log(orderResult);
        return { result: orderResult?.GetTotalUnitPriceExtendedForOrderResponse?.GetTotalUnitPriceExtendedForOrderResult };
      },
      (error) => {
        console.log("Error on GetTotalUnitPriceExtendedForOrder:");
        console.log(error);
        return { error: error};
      }
    );
  }
  
}
