import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { SalesOrderServiceSOAP } from "../../soapServices/SalesOrderService";
import { WarehouseService } from "../../soapServices/WarehouseService";
import { useSelector } from "react-redux";
import { ROUTE_DAYS } from "../../utils/routesConstants";
import "react-datepicker/dist/react-datepicker.css";

const CustomInput = (props, ref) => {
  return (
    <input {...props} />
  );
};

export default function UpdateSalesOrderDialog(props) {

    const session = useSelector(state => state.auth.sessionData.session);
    const [show, setShow] = useState(false);
    const [currentItem, setCurrentItem] = useState();
    const [targetedshipDate, setTargetedShipDate] = useState();
    const inputDateTimePicker = useRef(null);
    const salesOrderServiceSOAP = useRef(null);
    const warehouseService = useRef(null);

    useEffect(() => {
      salesOrderServiceSOAP.current = new SalesOrderServiceSOAP(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);
      warehouseService.current = new WarehouseService(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);
    }, []);

    useEffect(() => {
        setCurrentItem(props.currentItem);
        //console.log(props.currentItem)
        if(props.currentItem && props.currentItem.targetDeliveryDate){
          setTargetedShipDate(props.currentItem.targetDeliveryDate);
        }
    }, [props.currentItem]);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    const hide = () => {
        setShow(false);
        props.hide();
    }

    const update = (e) => {      
      e.preventDefault();
      if(currentItem.deliveryWindowFromDate > targetedshipDate || 
          currentItem.deliveryWindowToDate < new Date(targetedshipDate.toDateString())){
        props.onError('Targeted ship date is out of delivery window.');
        return;
      }
      hide();
      props.setWait(true);
      warehouseService.current.GetWarehouseDeliveryRoute(session, currentItem.routeId).then(responseRoute => {   
        console.log(responseRoute)
        if(responseRoute.result && !responseRoute.error){          
          const isValidDay = ROUTE_DAYS[targetedshipDate.getDay()];
          if(responseRoute.result[isValidDay] === "true"){
              salesOrderServiceSOAP.current.ValidateSalesOrderHeaderWithLock(session, currentItem.id).then(validateOrder => {
                if(validateOrder.result && !validateOrder.error){              
                  let formattedShipDate = toISOStringNoTimeZone(targetedshipDate); 
                  console.log(formattedShipDate)             
                  salesOrderServiceSOAP.current.UpdateTargetedDeliveryOrShipOrPickUpDateTimeForSalesOrder(session, currentItem.id, formattedShipDate)
                  .then(response => {
                    props.setWait(false);
                    console.log(response);
                    if(!response.error){
                      props.onUpdateDone();
                      currentItem.targetDeliveryDate = targetedshipDate;
                      salesOrderServiceSOAP.current.UnlockSalesOrderHeaderById(session, currentItem.id).then(unlockOrder => {
                        console.log(unlockOrder);
                      });
                    }
                  })
                }
                else{
                  props.setWait(false);
                }
              })
          }
          else{
            props.setWait(false);
            props.onError(`The ${responseRoute.result.Name} route does not run on the date you selected. The Ship/Delivery date was not updated.`);

          }
        }
        else{
          props.setWait(false);
        }
      })
    }

    const toISOStringNoTimeZone = (date) => {
      const userTimezoneOffset = date.getTimezoneOffset() * 60000;
      const dateNoTimeZone = new Date(date.getTime() - userTimezoneOffset);
      return dateNoTimeZone.toISOString();
    }

    return (
    <Modal
        show={show}
        onHide={hide}
        centered
        autoFocus={true}
      >
        <Modal.Header>
          <Modal.Title>Update Sales Order</Modal.Title>
        </Modal.Header>
        <Form onSubmit={update}>
          <Modal.Body>
            <label className="form-label">Targeted Ship Date:&nbsp;</label>
            <DatePicker 
              selected={targetedshipDate} 
              onChange={(date) => setTargetedShipDate(date)}
              className={"form-control"}
              customInput={React.createElement(React.forwardRef(CustomInput))}
              required={true}
              dateFormat="MM/dd/yyyy h:mm aa"
              //timeFormat="h:mm aa"
              timeIntervals={15}
              showTimeSelect
              minDate={props.currentItem?.deliveryWindowFromDate}
              maxDate={props.currentItem?.deliveryWindowToDate}
              ref={inputDateTimePicker}
            />
                    
          </Modal.Body>
          <Modal.Footer>          
            <Button
              onClick={hide}>
              Cancel
            </Button>
            <Button type='submit'>
              Save
            </Button>          
          </Modal.Footer>
        </Form>
      </Modal>
    )
}