export default function useFetch(baseUrl){

    const customFetch = (apiCall, urlParams, httpMethod, apiKey = undefined) => {
        var resultResponse = {
          result: undefined,
          error: undefined,
        };

        var headers = new Headers();
        headers.append("Accept", 'application/json');
        headers.append("Content-Type", 'application/json');
        if(apiKey){
            headers.append("ApiKey", apiKey);
            // headers.append("Authorization", `apiKey ${apiKey}`);
            // headers.append("Access-Control-Allow-Origin", "*");
            // headers.append("Access-Control-Allow-Credentials", true);
        }
    
        return fetch(baseUrl + apiCall + urlParams, { 
          method: httpMethod, 
          headers: headers,          
        })
        .then(response => {     
            console.log(response) 
          if(response.status === 200){
            return response.json();
          }
          else{
            return response.json().then(error => {
              throw error;
            }); 
          }
        })
        .then(jsonResult => {
          console.log(apiCall);
          console.log(jsonResult);
          resultResponse.result = jsonResult;   
          return resultResponse;
        })
        .catch(error => {
          console.log("Error on " + apiCall);
          console.log(error);
          if(error && error.exceptionMessage === undefined){
            error.exceptionMessage = "Unexpected error in " + apiCall;
          }
          resultResponse.error = error;
          return resultResponse;
        });
    }

    return [customFetch];
}