import { BaseRestService } from "./BaseRestService.js";

export class EmailService extends BaseRestService {

  SendEmail(session, fromEmail, fromName, toEmail, ccEmail, subject, body, attachmentUrl) {
    let emailModel = {
      session: session,
      fromEmail: fromEmail,
      fromName: fromName,
      toEmail: toEmail,
      ccEmail: ccEmail,
      subject: subject,
      htmlBody: body,
      attachmentUrl: attachmentUrl
    }
    return super.CustomFetch("Email/Send", "", 'POST', emailModel)
    .then(emailResult => {
      return emailResult;
    });
  }

  SendEmailForShipment(session, fromEmail, fromName, toEmail, ccEmail, subject, body, attachmentUrl, salesInvoiceHeader) {
    let emailModel = {
      session: session,
      fromEmail: fromEmail,
      fromName: fromName,
      toEmail: toEmail,
      ccEmail: ccEmail,
      subject: subject,
      htmlBody: body,
      attachmentUrl: attachmentUrl,
      salesInvoiceHeader: {
        salesInvoiceHeader_Id: salesInvoiceHeader.id,
        salesOrderHeader_Id: salesInvoiceHeader.secondaryId,
        status_Type: salesInvoiceHeader.statusType,
        ship_Date: salesInvoiceHeader.shipDate
      }
    }
    return super.CustomFetch("Email/SendEmailForShipment", "", 'POST', emailModel)
    .then(emailResult => {
      return emailResult;
    });
  }
}
