import { BaseSoapService } from "./BaseSoapService.js";
var md5 = require("md5");

export class UserSessionService extends BaseSoapService {
  constructor(baseUrl, capstoneVersion) {
    super(baseUrl, "UserSessionService.asmx", "LoginUserService", capstoneVersion);
  }

  CheckLoginUser(userCode, password) {
    var passwordMD5 = md5(password);

    var args = {
      userCode: userCode,
      password: passwordMD5,
    };

    var resultResponse = {
      data: {},
      error: undefined,
    };
    return super.makeSoapCall("CheckLoginUser", args).then(
      (result) => {
        console.log("CheckLoginUser");
        var branches =
          result.CheckLoginUserResponse.CheckLoginUserResult
            .CheckLoginUserOutPut;
        console.log(branches);
        if (branches && Array.isArray(branches)) {
          if (branches) {
            branches = branches.sort(function (a, b) {
              if (a.BranchName.toLowerCase() < b.BranchName.toLowerCase())
                return -1;
              if (a.BranchName.toLowerCase() > b.BranchName.toLowerCase())
                return 1;
              return 0;
            });
          }

          resultResponse.data = branches;
        } else if (branches) {
          resultResponse.data = [branches];
        }
        return resultResponse;
      },
      (error) => {
        console.log("Error:");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  Login(
    branchId,
    userCode,
    password,
    clientHostName,
    clientUserName,
    clientIPAddress
  ) {
    var passwordMD5 = md5(password);

    var args = {
      loginRequest: {
        BranchId: branchId,
        UserCode: userCode,
        Password: passwordMD5,
        ConnectedDeviceHostName: clientHostName,
        ConnectedDeviceUserName: clientUserName,
        ClientIPAddress: clientIPAddress,
        ApplicationType: 5,
        RemoteClientName: "ALMS"
      },
    };

    var resultResponse = {
      loginResult: {},
      error: undefined,
    };
    return super.makeSoapCall("Login", args).then(
      (result) => {
        let session = result.LoginResponse.LoginResult;
        // session.DefaultWarehouseId =
        //   session.DefaultWarehouseId == null ||
        //   session.DefaultWarehouseId === ""
        //     ? session.WarehouseId
        //     : session.DefaultWarehouseId;
        resultResponse.loginResult = session;

        return resultResponse;
      },
      (error) => {
        resultResponse.error = error;
        console.log("Error:");
        console.log(error);
        return resultResponse;
      }
    );
  }

  Logout(session) {
    var args = {
      session: session,
    };

    return super.makeSoapCall("Logout", args)
    .then(logoutResult => {
      return {result: logoutResult};
    },
    error => {
      return {error: error}
    });
  }

  GetCapstoneVersion() {
    var resultResponse = {
      result: undefined,
      error: undefined,
    };

    return super.makeSoapCall("GetCapstoneVersion").then(
      (result) => {
        resultResponse.result = result.GetCapstoneVersionResponse?.GetCapstoneVersionResult;
        return resultResponse;
      },
      (error) => {
        console.log("Error on GetCapstoneVersion");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }
}
