import React, { useState, useEffect } from 'react';
import { printInvoice, selectItem } from "../../reducers/pickupReducer";
import { useDispatch } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPrint, faMoneyBillWave, faFileExcel, faLock, faTruck, faCalendarAlt} from '@fortawesome/pro-solid-svg-icons';
import './PickupItem.css';
import { SALES_INVOICE_STATUS_TYPES } from '../../utils/SalesInvoiceStatusTypes';

export default function PickupItem(props) {

  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  const [hidden, setHidden] = useState(props.hidden);
  useEffect(() => {
    setHidden(props.hidden);
  }, [props.hidden]);    

  const showMore = (e) => {
    console.log("showMore Item");
    props.showMoreInfo(props.itemData);
  }

  const showLoadingTicket = (e) => {
    console.log("showMore Item");
    props.showMoreInfo(props.itemData, true);
  }

  const printInvoice = (e) => {
    console.log("print PL");
    props.printInvoice(props.itemData);
  }

  const printOrder = (e) => {
    console.log("print SO");
    props.printOrder(props.itemData);
  }

  const showMoney = (e) => {
    props.showMoney(props.itemData);
  }

  const selectItemChange = (e) => {
    setSelected(e.target.checked)
    dispatch(selectItem(props.itemData, e.target.checked));
  }

  const exportTags = (e) => {
    props.exportTags(props.itemData.id);
  }

  const updateShipDate = () => {
    props.updateShipDate(props.itemData);
  }

  return (
    <div className={"box_item_component" + (hidden ? " hidden" : "")}>      
      <div className={"outer " + 
        (props.itemData.statusType === SALES_INVOICE_STATUS_TYPES.SHIPPED || 
          props.itemData.statusType === SALES_INVOICE_STATUS_TYPES.DELIVERED ? "shipped" :
          props.itemData.overdue ? "overdue" : 
          props.itemData.future ? "future" : "")}>
        {!props.itemData.lockedBy && (props.printInvoice || props.printOrder) ?
        (<input type="checkbox" checked={selected} onChange={selectItemChange}/>) : (<span>&nbsp;</span>)}
        <div className={"item pickup"} id={props.uid} title={props.itemData.deliveryAddressName}>
          <span>
            {props.itemData.label + (props.itemData.cityAndState ? " - " + props.itemData.cityAndState : "")}
          </span>
          &nbsp;
          <span className="float-right">{Math.round(props.itemData.totalWeight).toLocaleString('en')} lbs</span>
        </div>
        {props.itemData.lockedBy && 
        (<span title={"Locked by " + props.itemData.lockedBy} className="box-item-option float-right"><FontAwesomeIcon icon={faLock} /></span>)}
        {!props.itemData.lockedBy && props.itemData.label.startsWith("PL#") &&
        (<div className="item-options float-right pickup">
          <span className="box-item-option box-item-click" title="Print" onClick={printInvoice}>                    
            <FontAwesomeIcon icon={faPrint} />
          </span>          
          {props.itemData.processThroughAccountsReceivable === false && 
          <span className="box-item-option box-item-click" onClick={showMoney}>                    
            <FontAwesomeIcon icon={faMoneyBillWave} />
          </span>}
          <span className="box-item-option box-item-click" title="Show loading ticket" onClick={showLoadingTicket}>                    
            <FontAwesomeIcon icon={faTruck} />
          </span>  
          <span className="box-item-option box-item-click" title="Update ship date" onClick={updateShipDate}>                    
            <FontAwesomeIcon icon={faCalendarAlt} />
          </span>
          <span className="box-item-option box-item-click" title="Show more info" onClick={showMore}>                    
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>                  
        </div>)} 
        {!props.itemData.lockedBy && props.itemData.label.startsWith("SO#") &&
        (<div className="item-options float-right pickup">
          {props.printOrder && (<span className="box-item-option box-item-click" title="Print" onClick={printOrder}>                    
            <FontAwesomeIcon icon={faPrint} />
          </span>)} 
          {props.itemData.totalTagsPieceCount > 0 && 
          (<span className="box-item-option box-item-click" title="Export tags" onClick={exportTags}>                    
            <FontAwesomeIcon icon={faFileExcel} />
          </span>)}   
          {props.itemData.processThroughAccountsReceivable === false && 
          <span className="box-item-option box-item-click" onClick={showMoney}>                    
            <FontAwesomeIcon icon={faMoneyBillWave} />
          </span>}
          <span className="box-item-option box-item-click" title="Show loading ticket" onClick={showLoadingTicket}>                    
            <FontAwesomeIcon icon={faTruck} />
          </span> 
          <span className="box-item-option box-item-click" title="Show more info" onClick={showMore}>                    
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>              
        </div>)}               
      </div>
    </div>
  );  
}