import React from "react";
import {ReactComponent as DragonesLogo} from "../../images/DragonesLogo.svg";
import "./Login.css";
import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logInSuccess, logInError, logOut } from "../../reducers/auth";
import {
  setCurrentWarehouse,
  setMenuInfo,
  setUserMenuAccess,
} from "../../reducers/menuInfo";
import { history } from "../../Store";

import { UserSessionService } from "../../soapServices/UserSessionService.js";
import { LoginUserService } from "../../soapServices/LoginUserService.js";
import { InventoryService } from "../../soapServices/InventoryService.js";
import { WarehouseService } from "../../soapServices/WarehouseService.js";
import { MainInitService } from "../../soapServices/MainInitService.js"

import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import DeviceUUID from "device-uuid";

class Login extends React.Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      usercode: "",
      password: "",
      showSelectBranch: false,
      loginFailed: false,
      errorWithServices: false,
      errorMessage: "",
      branchId: "",
      session: undefined,
      branches: [],
      currentBranch: {},
      type: "password",
      iconClass: "far fa-eye",
      disableSelect: true,
      wait: false,
      editingDropdown: false,
      openedDropdownBranch: false,

      //UnExpected Errors Fields
      unexpectedErrorDialogShow: false,
      moreInfoErrorDialogMessage: undefined,
      showDialog: false,
      dialogMessage: "",
      isWarningDialog: false,
      isAutoLogout: props.match.params.auto,
    };
  }

  sessionData = undefined;

  componentDidMount() {
    var capstoneServiceUrl = process.env.REACT_APP_CAPSTONE_SERVICE_URL;
    this.userSessionService = new UserSessionService(capstoneServiceUrl, this.props.capstoneVersion);
    this.loginUserService = new LoginUserService(capstoneServiceUrl, this.props.capstoneVersion);
    this.inventoryService = new InventoryService(capstoneServiceUrl, this.props.capstoneVersion);
    this.warehouseService = new WarehouseService(capstoneServiceUrl, this.props.capstoneVersion);
    this.mainInitService = new MainInitService(capstoneServiceUrl, this.props.capstoneVersion);

    //Set reducers Actions
    this.props.setMenuInfo("");
    if (window.isLoggedIn && this.state.isAutoLogout === "auto") {
      this.setState({
        showDialog: true,
        dialogType: MESSAGES_CONSTANTS.MODAL_INFO_TITLE,
        dialogMessage: MESSAGES_CONSTANTS.LOGIN_SESSION_EXPIRE_ERROR,
      });
      setTimeout((e) => {
        this.modalOkButton.focus();
      }, 200);
    }
    this.props.logOut();
    window.isLoggedIn = false;
    window.removeEventListener("unload", this.logout);
    if (this.props.sessionData.session) {
      this.logout(this.props.sessionData.session);
    }
    this.deviceID = new DeviceUUID.DeviceUUID().get();
    this.userCodeInput.focus();
  }

  render() {
    return (
      <div
        className="Login-page"
        style={{ cursor: this.state.wait ? "wait" : "default" }}
        disabled={this.state.wait}
      >
        <div className="Login-container">
          <div className="Login-panel">            
            <div className="Login-top">
              <DragonesLogo/>
            </div>
            <br/>
            <h5 style={{textAlign:"center"}}>Advanced Logistics Management System</h5>
            <hr/>
            <form onSubmit={this.handleSubmit}>
              <fieldset className="Login-fieldset">
                <div className="mb-3 col-12">
                  <div className="row">
                    <label>
                      <b>Capstone User Code:</b>
                    </label>
                  </div>
                  <div className="row">
                    <input
                      className="form-control"
                      tabIndex="1"
                      id="inputLoginUsercode"
                      name="usercode"
                      required={true}
                      value={this.state.usercode}
                      onChange={this.onChangeInput}
                      disabled={this.state.wait}
                      ref={(ref) => {
                        this.userCodeInput = ref;
                      }}
                      onFocus={(e) => {
                        this.lastInputFocused = e.target;
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 col-12">
                  <div className="row">
                    <label>
                      <b>Password:</b>
                    </label>
                  </div>
                  <div className="row">
                    <input
                      className="form-control"
                      tabIndex="2"
                      id="inputLoginPassword"
                      name="password"
                      required={true}
                      value={this.state.password}
                      type={this.state.type}
                      onChange={this.onChangeInput}
                      disabled={this.state.wait}
                      onFocus={(e) => {
                        this.lastInputFocused = e.target;
                      }}
                    />
                  </div>
                </div>
              </fieldset>
              <div className="Login-button">
                <button
                  tabIndex="3"
                  id="buttonLoginLoginButton"
                  type="submit"
                  className="btn btn-primary"
                  disabled={this.state.wait}
                >
                  Log in
                </button>
              </div>
            </form>
          </div>
        </div>
        <Modal show={this.state.showSelectBranch} onHide={(e) => {}} centered>
          <Modal.Header>
            <Modal.Title>Select Branch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <label className={"col-3 "}>Branch:</label>
              <div className={"col-9 "}>
                <select
                  className={"form-control "}
                  name="branchId"
                  value={this.state.branchId}
                  required={true}
                  onChange={this.handleChange}
                  disabled={this.state.wait}
                  onFocus={(e) => {
                    this.lastInputFocused = e.target;
                  }}
                  ref={(ref) => {
                    this.branchIdSelect = ref;
                  }}
                >
                  <option value={""} label={""} style={{display: "none"}}></option>
                  {this.state.branches.map((branch, index) => {
                    return (
                      <option
                        key={index}
                        value={branch.BranchId}
                        label={branch.BranchName}
                      ></option>
                    );
                  })}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideErrorDialog}>Cancel</Button>
            <Button
              onClick={this.selectBranch}
              disabled={this.state.branchId === ""}
              ref={(ref) => {
                this.selectBranchButton = ref;
              }}
            >
              Select
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showDialog}
          onHide={this.hideErrorDialog}
          centered
        >
          <Modal.Header>
            <Modal.Title>{this.state.dialogType}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.state.dialogMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            {!this.state.isWarningDialog ?
            <Button
              onClick={this.hideErrorDialog}
              ref={(ref) => {
                this.modalOkButton = ref;
              }}
            >
              {MESSAGES_CONSTANTS.MODAL_OK_OPTION}
            </Button>
            :
            <>
            <Button
              onClick={this.hideErrorDialog}
              ref={(ref) => {
                this.modalOkButton = ref;
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.setState({wait: true, showDialog: false});
                this.startLoginSessionProcess(this.state.usercode,this.state.password,this.state.branchId);
              }}
            >
              Continue Anyway
            </Button>
            </>}
          </Modal.Footer>
        </Modal>
        <Modal
          className="modal-spinner"
          show={this.state.wait}
          centered
          onHide={(e) => {}}
        >
          <Spinner animation="border" role="status" />
          <span className="modal-spinner-text">
            {MESSAGES_CONSTANTS.MODAL_SPINNER_MESSAGE}
          </span>
        </Modal>
      </div>
    );
  }

  handleSubmit = (event) => {
    console.log("handleSubmit");
    event.preventDefault();

    this.setState({ wait: true });
    fetch(process.env.REACT_APP_CAPSTONE_REST_API)
    .then(done => {
      console.log(done)
      this.checkUserData(this.state.usercode, this.state.password);
    })
    .catch(error => {
      console.log(error)
      this.setState({ wait: false });
      this.onLoginError("Could not connect to Capstone web API.");
      return;
    })

    
    
  };

  checkUserData = (usercode, password) => {    
    return this.userSessionService
      .CheckLoginUser(usercode, password)
      .then((userResultSet) => {
        console.log(userResultSet)
        let branches = userResultSet.data;
        let error = userResultSet.error;
        let detailError =
          error && error.detail && error.detail.CapstoneException
            ? error.detail.CapstoneException
            : undefined;
        if (error) {
          if (detailError && detailError.ErrorCode) {
            switch (detailError.ErrorCode) {
              case "5913":
                //5913:User don't have branch associated
                let brachNotAssociatedError =
                  MESSAGES_CONSTANTS.BRANCH_NOT_ASSOCIATED_ERROR;
                this.onLoginError(brachNotAssociatedError);
                break;
              case "5910":
              case "5911":
                //Invalid user code or password
                this.onLoginError(MESSAGES_CONSTANTS.LOGIN_FAILED);
                break;
              default:
                this.onLoginError();
                break;
            }
          } else {
            //Errors not handled
            this.onLoginError(error.Message);
          }
        } else {
          this.sessionData = {};
          if (branches.length === 1) {
            this.sessionData.currentBranch = branches[0];
            this.setState({branchId: branches[0].BranchId});
            
            this.userSessionService.GetCapstoneVersion(usercode, password)
            .then((capstoneVersionResult) => {
              console.log(capstoneVersionResult);
              if(!capstoneVersionResult.result.CapstoneVersion.startsWith(this.props.capstoneVersion)){
                this.setState({
                  wait: false,
                  isWarningDialog: true,
                  showDialog: true,
                  dialogType: MESSAGES_CONSTANTS.MODAL_WARNING_TITLE,
                  dialogMessage: "This version of ALMS has not been tested with your current Capstone version. " +
                  "You are running Capstone Version: "+ capstoneVersionResult.result?.CapstoneVersion + 
                  ". This version of ALMS has only been tested on Capstone Version: " + this.props.capstoneVersion,
                });
                setTimeout((e) => {
                  this.modalOkButton.focus();
                }, 200);
              }
              else{
                this.startLoginSessionProcess(usercode, password, branches[0].BranchId);
              }
            });
            
            
          } else if (branches.length > 1) {
            this.setState({
              wait: false,
              branches: branches,
              showSelectBranch: true,
              disableSelect: true,
            });
            setTimeout((e) => {
              this.branchIdSelect.focus();
            }, 200);
          } else {
            this.onLoginError();
          }
        }
      });
  };

  selectBranch = (event) => {
    event.preventDefault();
    console.log("branchId: " + this.state.branchId);

    if (this.state.branchId !== "") {
      this.setState({
        showSelectBranch: false,
        wait: true,
        disableSelect: true,
      });
      this.startLoginSessionProcess(
        this.state.usercode,
        this.state.password,
        this.state.branchId
      );
    }
  };

  startLoginSessionProcess = (user, password, branchId) => {
    console.log("Current Branch");
    console.log(branchId);
    //console.log(this.sessionData.currentBranch);
    if (branchId > 0 && this.sessionData) {
      this.createLoginSession(user, password, branchId).then(
        (sessionResult) => {
          if (
            this.sessionData.session &&
            this.sessionData.session.DefaultWarehouseId
          ) {
            this.getLoginUser().then((loginResult) => {
              if (this.sessionData.loginUser) {
                this.getLoginPermission(
                  this.sessionData.loginUser,
                  this.sessionData
                ).then((permissionResult) => {
                  this.getMobileMenuAccess(
                    this.sessionData.loginUser.LoginUserId
                  ).then((menuAccessResult) => {
                    this.getInventoryInit().then((inventoryResult) => {
                      this.getDocumentInitForSystemSettings().then((documentSettingsResult) => {
                      this.getAllwarehouses().then((AllWarehouseResult) => {
                        //this.getAllWarehouseLocationsForWarehouse().then(
                          //(AllWarehouseLocationResult) => {
                            if (!this.state.errorWithServices) {
                              this.setState({ wait: false });
                              window.isLoggedIn = true;
                              this.props.logInSuccess(this.sessionData);
                              console.log(this.sessionData.loginUser.UserCode);
                              this.props.setUserMenuAccess(this.menuAccess, this.sessionData.loginUser.UserCode === "ADMIN");
                              console.log("this.sessionData");
                              console.log(this.sessionData);
                              console.log(this.props.previousPage)
                              history.push(this.props.previousPage && this.props.previousPage != null ? 
                                this.props.previousPage : "/routing");
                            }
                          }
                        );
                      });
                    });
                  });
                });
              }
            });
          }
        }
      );
    }
  };

  createLoginSession = (usercode, password, branchId) => {
    return this.userSessionService
      .Login(branchId, usercode, password, this.deviceID, "ALMS", "")
      .then((result) => {
        let error = result.error;
        if (error && error.detail && error.detail.SplusException) {
          this.onLoginError(error.detail.SplusException.ErrorMessage);
        } else {
          console.log("Login Result:");
          var loginResult = result.loginResult;
          console.log(loginResult);
          if (
            loginResult.DefaultWarehouseId == null ||
            loginResult.DefaultWarehouseId === ""
          ) {
            this.onLoginError(MESSAGES_CONSTANTS.DEFAULT_WAREHOUSE_ERROR);
          }
          if (loginResult.SessionId.length > 0) {
            this.sessionData.session = loginResult;
          } else {
            this.onLoginError();
          }
        }
      });
      
  };

  getLoginUser = () => {
    return this.loginUserService
      .GetLoginUser(this.sessionData.session, this.sessionData.session.UserId)
      .then((result) => {
        let error = result.error;
        if (error) {
          this.setState({ showSelectBranch: false, errorWithServices: true });
          this.onLoginError();
        } else {
          console.log("Login User Result:");
          let loginUserResult = result.loginUserResult;
          console.log(loginUserResult);
          if (loginUserResult) {
            this.sessionData.loginUser = loginUserResult;
          } else {
            this.onLoginError();
          }
        }
      });
  };

  getInventoryInit = () => {
    return this.inventoryService
      .GetInventoryInit(this.sessionData.session)
      .then((result) => {
        let error = result.error;
        if (error) {
          this.setState({ showSelectBranch: false, errorWithServices: true });
          this.onLoginError();
        } else {
          console.log("Inventory Init Result:");
          console.log(result);
          if (result.result) {
            this.sessionData.inventoryInit = result.result;
          } else {
            this.onLoginError();
          }
        }
      });
  };

  getDocumentInitForSystemSettings = () => {
    return this.mainInitService
      .GetDocumentInitForSystemSettings(this.sessionData.session)
      .then((result) => {
        let error = result.error;
        if (error) {
          this.setState({ showSelectBranch: false, errorWithServices: true });
          this.onLoginError();
        } else {
          console.log("GetDocumentInitForSystemSettings Result:");
          console.log(result);
          if (result.result) {
            this.sessionData.documentInitForSystemSettings = result.result;
          } else {
            this.onLoginError();
          }
        }
      });
  };

  getMobileMenuAccess = (LoginUserId) => {
    return this.loginUserService
      .GetMobileMenuAccessForUser(this.sessionData.session, LoginUserId)
      .then((menuAccessResult) => {
        let error = menuAccessResult.error;
        if (error) {
          this.setState({ showSelectBranch: false, errorWithServices: true });
          this.onLoginError();
        } else {
          console.log("GetMobileMenuAccessForUser Result:");
          console.log(menuAccessResult);
          if (menuAccessResult.result) {
            this.menuAccess = menuAccessResult.result;
          } else {
            this.onLoginError();
          }
        }
      });
  };

  getLoginPermission = (loginUserResult, session) => {
    if (loginUserResult.GroupId !== undefined && loginUserResult.GroupId > 0) {
      console.log("Login Type: Group (2)");
      return this.getLoginPermissionGroup(loginUserResult.GroupId);
    } else {
      console.log("Login Type: User (1)");
      return this.getLoginPermissionUser(loginUserResult.LoginUserId);
    }
  };

  getLoginPermissionUser = (userId) => {
    console.log(userId);
    var userSession = this.sessionData.session;

    var warehouseId = userSession.WarehouseId;
    if (warehouseId === "0") {
      warehouseId = userSession.DefaultWarehouseId;
    }

    return this.loginUserService
      .GetLoginPermissionForUser(this.sessionData.session, userId)
      .then(
        (resultSet) => {
          let error = resultSet.error;
          if (error) {
            this.setState({
              showSelectBranch: false,
              errorWithServices: true,
            });
            this.onLoginError();
          } else {
            this.getWarehouse(userSession, warehouseId).then((result) => {
              this.sessionData.permission = resultSet.data;
            });
          }
        },
        (error) => {
          console.log("Error:");
          console.log(error);
          this.onLoginError();
        }
      );
  };

  getLoginPermissionGroup = (groupId) => {
    return this.loginUserService
      .GetLoginPermissionForGroup(this.sessionData.session, groupId)
      .then(
        (resultSet) => {
          let error = resultSet.error;
          if (error) {
            this.setState({ showSelectBranch: false, errorWithServices: true });
            this.onLoginError();
          } else {
            var userSession = this.sessionData.session;
            var warehouseId = userSession.WarehouseId;
            if (warehouseId === "0") {
              warehouseId = userSession.DefaultWarehouseId;
            }
            this.getWarehouse(userSession, warehouseId).then((result) => {
              this.sessionData.permission = resultSet.data;
            });
          }
        },
        (error) => {
          console.log("Error:");
          console.log(error);
          this.onLoginError();
        }
      );
  };

  getAllwarehouses = () => {
    console.log(this.sessionData.session);
    return this.warehouseService
      .GetAllWarehouses(this.sessionData.session)
      .then(
        (result) => {
          let error = result.error;
          if (error) {
            this.setState({ errorWithServices: true });
            this.onLoginError();
          } else {
            this.sessionData.warehouseList = result.data;
          }
        },
        (error) => {
          console.log("Error:");
          console.log(error);
          this.onLoginError();
        }
      );
  };

  getWarehouse = (session, warehouseNo) => {
    console.log("getWarehouse");
    return this.warehouseService
      .GetWarehouse(session, warehouseNo)
      .then((warehouseResult) => {
        let error = warehouseResult.error;
        var currentWarehouse = warehouseResult.data;
        if (error) {
          this.onLoginError();
          this.setState({ wait: false });
        } else {
          //set current warehouse
          console.log("set warehouse");
          this.props.setCurrentWarehouse(currentWarehouse);
        }
      });
  };

  getAllWarehouseLocationsForWarehouse = () => {
    var session = this.sessionData.session;
    var warehouseId = session.WarehouseId;

    return this.warehouseService
      .GetAllWarehouseLocationsForWarehouse(session, warehouseId)
      .then((warehouseLocationsResult) => {
        let error = warehouseLocationsResult.error;
        let allWarehouseLocationList = warehouseLocationsResult.result;
        if (error) {
          this.setState({ wait: false });
        } else {
          //update warehouse Location
          this.sessionData.allWarehouseLocationList = allWarehouseLocationList;
        }
      });
  };

  logout = (session) => {
    return this.userSessionService.Logout(session).then((userResultSet) => {
      console.log("sessionLogout");
      console.log(userResultSet);
      let error = userResultSet.error;
      if (error) {
        console.log("Could not auto logout web service session.");
      }
    });
  };

  onLoginError = (error) => {
    this.props.logInError();
    this.setState({
      isWarningDialog: false, 
      wait: false,
      showDialog: true,
      dialogMessage: error ? error : MESSAGES_CONSTANTS.LOGIN_FAILED,
      dialogType: MESSAGES_CONSTANTS.MODAL_ERROR_TITLE,
    });
    setTimeout((e) => {
      this.modalOkButton.focus();
    }, 100);
  };

  hideErrorDialog = (event) => {
    this.setState({ showDialog: false, showSelectBranch: false, branchId:"" });
    setTimeout((e) => {
      this.lastInputFocused.focus();
    }, 200);
  };

  onChangeInput = (event) => {
    var name = event.target.name;
    let value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (event) => {
    let targetName = event.target.name;
    let value = event.target.value;
    console.log(value);
    this.setState({
      [targetName]: value,
      wait: false,
      disableSelect: false,
      editingDropdown: false,
    });
  };
}

function mapStateToProps(state) {
  return {
    isLogged: state.auth.isLogged,
    isAutoLogout: state.auth.isAutoLogout,
    sessionData: state.auth.sessionData,
    logginError: state.auth.logginError,
    currentWarehouse: state.menuInfo.currentWarehouse,
    previousPage: state.menuInfo.previousPage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logInSuccess,
      logInError,
      logOut,
      setCurrentWarehouse,
      setMenuInfo,
      setUserMenuAccess,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
