
const menuTypes = [
    {
      text: "Routing",
      items: [
        { text: "Routing Screen", path: "/routing"},  
        { text: "Lookup Stop by Sales Order", path: "/lookupstops"} 
      ],
    },
    {
      text: "Pickup",
      items: [{ text: "Pickup Screen", path: "/pickup" }],
    },
    {
      text: "Warehouse",
      items: [{ text: "Change Warehouse", path: "/warehouse" }],
    },
    {
      text: "Settings",
      items: [{ text: "Configure", path: "/configuration"}],      
    },
    {
      text: "About",
      items: [{ text: "Version ", path: "/about"}],      
    },
  ];

export { menuTypes };
