import { BaseSoapService } from "./BaseSoapService.js";

export class FileAttachmentService extends BaseSoapService {  
  constructor(baseUrl, capstoneVersion) {
    super(baseUrl, "FileAttachmentService.asmx", "FileAttachmentService", capstoneVersion);
  }

  DeleteTemporaryFile(session, fileName) {
    var args = {
      session: session,
      fileName: fileName
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("DeleteTemporaryFile", args).then(
      (result) => {
        console.log("DeleteTemporaryFile");
        //console.log(result);
        if (result.DeleteTemporaryFileResponse && result.DeleteTemporaryFileResponse.DeleteTemporaryFileResult) {          
            resultResponse.result = result.DeleteTemporaryFileResponse.DeleteTemporaryFileResult
        }        
        return resultResponse;
      },
      (error) => {
        resultResponse.error = error;
        console.log("Error on DeleteTemporaryFile:");
        console.log(error);
        return resultResponse;
      }
    );
  }
  
}
