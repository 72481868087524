import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import Login from "./login/Login";
import Menu from "./menu/Menu";
import RoutingScreen from "./routingScreen/RoutingScreen";
import Footer from "./footer/Footer";
import Warehouse from "./warehouse/Warehouse";
import Configuration from "./settings/Configuration";
import LookupStops from "./lookupStops/LookupStops";
import PickupScreen from "./pickupScreen/PickupScreen";
import About from "./about/About";
import { logInSuccess, logOut } from "../reducers/auth";
import {
  setCurrentWarehouse,
  setPreviousPage,
  setUserMenuAccess,
  setRoutingConfig,
  setFiltersReducer,
} from "../reducers/menuInfo";
import { history } from "../Store";

import { UserSessionService } from "../soapServices/UserSessionService";

export default function CustomRouter(props){

  const dispatch = useDispatch();
  const autoLogoutTimer = React.useRef(null);
  const userSessionService = React.useRef();
  const session = useSelector(state => state.auth.sessionData.session);

  //On Mount Router
  React.useEffect(() => {
    let isLogged = sessionStorage.getItem("isLogged");
    if (isLogged) {
      let sessionData = JSON.parse(sessionStorage.getItem("sessionData"));
      let userMenuAccess = JSON.parse(sessionStorage.getItem("userMenuAccess"));
      let currentWarehouse = JSON.parse(sessionStorage.getItem("currentWarehouse"));            
      dispatch(logInSuccess(sessionData));
      dispatch(setUserMenuAccess(userMenuAccess, false));
      dispatch(setCurrentWarehouse(currentWarehouse));     
    }

    let previousPage = localStorage.getItem("previousPage");     
    if(previousPage && previousPage != null){
      dispatch(setPreviousPage(previousPage));
    }

    let filters = JSON.parse(localStorage.getItem("filters"));     
    if(filters && filters != null){
      console.log(filters)
      dispatch(setFiltersReducer(filters));
    }

    let routesOrientation = JSON.parse(localStorage.getItem("routesOrientation")) ?? "Horizontal";
    dispatch(setRoutingConfig(routesOrientation));    

    userSessionService.current = new UserSessionService(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);

  }, []);

  const onLogout = React.useCallback(() => { 
    console.log("onLogout")
    console.log(session)
    if(session){
      return userSessionService.current.Logout(session).then((userResultSet) => {
        console.log("sessionLogout");
        console.log(userResultSet);
        dispatch(logOut());
        history.push("/login/auto"); 
      });
    }
  }, [session, dispatch]);

  const startTimerForLogout = React.useCallback(() => {
    if(session !== null){
      if (autoLogoutTimer.current) {
        clearTimeout(autoLogoutTimer.current);
      }
      autoLogoutTimer.current = setTimeout(() => {
        onLogout();
      }, Number(process.env.REACT_APP_AUTOLOGOUT_MINUTES) * 60000);
    }
  }, [session, onLogout]);

  React.useEffect(() => {
    window.addEventListener('click', startTimerForLogout);
    window.addEventListener('unload', onLogout);
    startTimerForLogout();

    return () => {
      window.removeEventListener('click', startTimerForLogout);
      window.removeEventListener('unload', onLogout);
    };

  }, [session, startTimerForLogout, onLogout]);

  return (
    <div className="full-size">
      <Menu capstoneVersion={props.capstoneVersion}/>
      <Switch>
        <Route exact path="/" 
          render={routeProps => <Login {...routeProps} capstoneVersion={props.capstoneVersion}/>}
        />
        <Route exact path="/login/:auto?" 
          render={routeProps => <Login {...routeProps} capstoneVersion={props.capstoneVersion}/>}
        />          
        <PrivateRoute
          exact
          path="/routing"
          component={RoutingScreen}
          capstoneVersion={props.capstoneVersion}
        />
        <PrivateRoute
          exact
          path="/lookupstops"
          component={LookupStops}
          capstoneVersion={props.capstoneVersion}
        />
        <PrivateRoute
          exact
          path="/lookupstops/invoice/:invoiceNumber"
          component={LookupStops}
          capstoneVersion={props.capstoneVersion}
        />
        <PrivateRoute
          exact
          path="/lookupstops/order/:orderNumber"
          component={LookupStops}
          capstoneVersion={props.capstoneVersion}
        />
        <PrivateRoute
          exact
          path="/pickup"
          component={PickupScreen}
          capstoneVersion={props.capstoneVersion}
        />
        <PrivateRoute
          exact
          path="/warehouse"
          component={Warehouse}
          capstoneVersion={props.capstoneVersion}
        />
        <PrivateRoute
          exact
          path="/configuration"
          component={Configuration}
          capstoneVersion={props.capstoneVersion}
        />
        <PrivateRoute
          exact
          path="/about"
          component={About}
          appVersion={props.appVersion}
          capstoneVersion={props.capstoneVersion}
        />
      </Switch>
      <Footer/>
    </div>
  );  
}