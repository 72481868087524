import { BaseSoapService } from "./BaseSoapService.js";

function toISOStringWithoutTime(date) {

  let d = date ?? new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-') + 'T00:00:00.000Z';
}

export class SalesInvoiceServiceSOAP extends BaseSoapService {  
  constructor(baseUrl, capstoneVersion) {
    super(baseUrl, "SalesInvoiceService.asmx", "SalesInvoiceService", capstoneVersion);
  }

  PrintPackingList(session, salesInvoiceHeaderId, directPrint = false, printPrices = false) {
    var args = {
      session: session,
      salesInvoiceHeaderId: salesInvoiceHeaderId,
      directPrintFlag: directPrint,
      documentWillBeEmailedFlag: false,
      printPricesFlag: printPrices,
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("PrintPackingList", args).then(
      (result) => {
        //console.log("PrintPackingList");
        //console.log(result);
        if (result.PrintPackingListResponse && result.PrintPackingListResponse.PrintPackingListResult) {          
            resultResponse.result = result.PrintPackingListResponse.PrintPackingListResult
        }        
        return resultResponse;
      },
      (error) => {
        resultResponse.error = error;
        console.log("Error on PrintPackingList:");
        console.log(error);
        return resultResponse;
      }
    );
  }

  GetTestReportForSalesInvoice(session, salesInvoiceHeaderId) {
    var args = {
      session: session,
      salesInvoiceHeaderId: salesInvoiceHeaderId,
      printType: 2,
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("GetTestReportForSalesInvoice", args).then(
      (result) => {
        //console.log("GetTestReportForSalesInvoice");
        //console.log(result);
        if (result.GetTestReportForSalesInvoiceResponse && result.GetTestReportForSalesInvoiceResponse.GetTestReportForSalesInvoiceResult) {          
            resultResponse.result = result.GetTestReportForSalesInvoiceResponse.GetTestReportForSalesInvoiceResult
        }        
        return resultResponse;
      },
      (error) => {
        resultResponse.error = error;
        console.log("Error on GetTestReportForSalesInvoice:");
        console.log(error);
        return resultResponse;
      }
    );
  }

  GetSalesInvoiceHeader(session, salesInvoiceHeaderId) {
    var args = {
      session: session,
      salesInvoiceHeaderId: salesInvoiceHeaderId,
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("GetSalesInvoiceHeader", args).then(
      (result) => {
        console.log("GetSalesInvoiceHeader");
        console.log(result);
        if (result.GetSalesInvoiceHeaderResponse && result.GetSalesInvoiceHeaderResponse.GetSalesInvoiceHeaderResult) {          
            resultResponse.result = result.GetSalesInvoiceHeaderResponse.GetSalesInvoiceHeaderResult
        }        
        return resultResponse;
      },
      (error) => {
        resultResponse.error = error;
        console.log("Error on GetSalesInvoiceHeader:");
        console.log(error);
        return resultResponse;
      }
    );
  }
  
  ShipPackingList(session, salesInvoiceHeaderId) {
    var args = {
      session: session,
      salesInvoiceHeaderId: salesInvoiceHeaderId,      
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("SetShipmentToShippedOrPickUpInProgress", args).then(
      (result) => {
        console.log("SetShipmentToShippedOrPickUpInProgress");
        console.log(result);        
        resultResponse.result = result.SetShipmentToShippedOrPickUpInProgressResponse?.SetShipmentToShippedOrPickUpInProgressResult;   
        return resultResponse;
      },
      (error) => {
        resultResponse.error = error;
        console.log("Error on SetShipmentToShippedOrPickUpInProgress:");
        console.log(error);
        return resultResponse;
      }
    );
  }

  DeliverPackingList(session, salesInvoiceHeaderId) {
    var args = {
      session: session,
      salesInvoiceHeaderId: salesInvoiceHeaderId,      
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("SetShipmentToDeliveredOrPickedUp", args).then(
      (result) => {
        console.log("SetShipmentToDeliveredOrPickedUp");
        console.log(result);        
        resultResponse.result = result.SetShipmentToDeliveredOrPickedUpResponse?.SetShipmentToDeliveredOrPickedUpResult;   
        return resultResponse;
      },
      (error) => {
        resultResponse.error = error;
        console.log("Error on SetShipmentToDeliveredOrPickedUp:");
        console.log(error);
        return resultResponse;
      }
    );
  }

  EmailShipmentDocumentsPackage(session, salesInvoiceHeaderId, includePricingFlag) {
    var args = {
      session: session,
      salesInvoiceHeaderId: salesInvoiceHeaderId,
      includePricingFlag: includePricingFlag
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("EmailShipmentDocumentsPackage", args)
    .then(emailResult => {
        console.log("EmailShipmentDocumentsPackage:");
        console.log(emailResult);
        var emailData = emailResult.EmailShipmentDocumentsPackageResponse.EmailShipmentDocumentsPackageResult;
        resultResponse.result = emailData;
        return resultResponse;
      },
      (error) => {
        console.log("Error on EmailShipmentDocumentsPackage: ");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  UpdateShipmentDate(session, salesInvoiceHeaderId, shipmentDate) {
    var args = {
      session: session,
      salesInvoiceHeaderId: salesInvoiceHeaderId,
      shipmentDate: toISOStringWithoutTime(shipmentDate),
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("UpdateShipmentAndInvoiceDateForSalesInvoice", args)
    .then(updateResult => {
        console.log("UpdateShipmentAndInvoiceDateForSalesInvoice:");
        console.log(updateResult);
        resultResponse.result = updateResult.UpdateShipmentAndInvoiceDateForSalesInvoiceResponse.UpdateShipmentAndInvoiceDateForSalesInvoiceResult;        
        return resultResponse;
      },
      (error) => {
        console.log("Error on UpdateShipmentAndInvoiceDateForSalesInvoice: ");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }
  
}
