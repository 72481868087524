import { BaseRestService } from "./BaseRestService.js";
import utils from "../utils/utils.js";
var shortid = require('shortid');

function mapSalesInvoices(invoices, result, forShipDate){
  let planningDateWithoutTime = new Date((forShipDate ?? new Date()).toDateString());
  invoices.forEach(invoice => {  
    let invoiceShipDate = new Date(invoice.ship_Date);                
    let invoiceMapped = {
      id: invoice.salesInvoiceHeader_Id,
      secondaryId: invoice.salesOrderHeader_Id,
      label : "PL#" + invoice.salesInvoiceHeader_Id,
      cityAndState: invoice.deliveryCity + (invoice.deliveryState ? ", " + invoice.deliveryState : undefined),
      uid: shortid.generate(),
      totalWeight: 0,
      deliveryAddressName: invoice.deliveryAddressName ?? '',
      runNumber: invoice.runNumber,
      lat: invoice.latitude, 
      lng: invoice.longitude,
      sequenceNumber: invoice.stopSequenceNumber,
      hasLoad: invoice.hasLoad,
      shipDate: invoiceShipDate,
      overdue: invoiceShipDate < planningDateWithoutTime,
      future: invoiceShipDate > planningDateWithoutTime,
      lockedBy: invoice.lockedBy,
      note: invoice.routingNote,
      statusType: invoice.status_Type,
      deliverTestReport: invoice.deliverTestReports_Flag,
      loadNumber: invoice.loadHeader_Id,
      includePricing: invoice.includePricingOnShippingDocuments_Flag,
      routeId: invoice.warehouseDeliveryRoute_Id,
      deliveryAddressType: invoice.deliveryAddress_Type,
      isCommonCarrier: invoice.commonCarrier_Flag,
      deliveryMethodId: invoice.warehouseDeliveryMethod_Id,
      processThroughAccountsReceivable: invoice.processThroughAccountsReceivable_Flag,
      deliveryTimeWindows: invoice.deliveryTimeWindows,
      deliveryNote: invoice.deliveryNote,
      truckVin: invoice.truckVin,
      truckName: invoice.truckName
    };
    invoice.salesInvoiceDetails.forEach(detail => {
      invoiceMapped.totalWeight += detail.totalLineWeight;
    });
    result.push(invoiceMapped);
  });
}

export class SalesInvoiceService extends BaseRestService {

  GetSalesInvoicesForRouting(session, warehouseId, forShipDate, includePriorDates, includeFutureDates) {
    var resultResponse = {
      result: [],
      error: undefined,
    };
    return super.CustomFetch("SalesInvoice/GetSalesInvoicesForRouting", ("?warehouseId=" + warehouseId + 
      "&forShipDate=" + utils.formatDate(forShipDate) + "&includePriorDates=" + includePriorDates + 
      "&includeFutureDates=" + includeFutureDates), 'POST', session)
      .then(invoicesResult => {
        if(invoicesResult.error){
          resultResponse.error = invoicesResult.error;
        }
        else if(invoicesResult.result){          
          mapSalesInvoices(invoicesResult.result, resultResponse.result, forShipDate);          
        }
        return resultResponse;      
      }); 
  }

  GetSalesInvoicesForPickup(session, warehouseId, includeOtherStatus, search) {
    var resultResponse = {
      result: [],
      error: undefined,
    };
    return super.CustomFetch("SalesInvoice/GetSalesInvoicesForPickup", ("?warehouseId=" + warehouseId + 
      "&includeOtherStatus=" + includeOtherStatus + "&search=" + (search ?? "")), 'POST', session)
      .then(invoicesResult => {
        if(invoicesResult.error){
          resultResponse.error = invoicesResult.error;
        }
        else if(invoicesResult.result){          
          mapSalesInvoices(invoicesResult.result, resultResponse.result);          
        }
        return resultResponse;      
      }); 
  }

  GetSalesInvoices(session, salesInvoiceHeaderId, queryLoadHeaderId = false) {
    return super.CustomFetch("SalesInvoice/Get", ("?salesInvoiceHeaderId=" + salesInvoiceHeaderId + 
      "&queryLoadHeaderId=" + queryLoadHeaderId), 'POST', session);      
  }

  Update(session, invoices) {

    var invoiceModel = {
      session: session,
      SalesInvoiceHeaders: []
    };    

    invoices.forEach(invoice => {
      invoiceModel.SalesInvoiceHeaders.push({
        SalesInvoiceHeader_Id: invoice.id,
        WarehouseDeliveryRoute_Id: invoice.routeId,
        RunNumber: invoice.runNumber,
        StopSequenceNumber: invoice.stopSequenceNumber
      })
    });

    return super.CustomFetch("SalesInvoice/Update", "", 'POST', invoiceModel);
  }

  GetSalesInvoicesBySalesOrder(session, salesOrderHeaderId) {

    return super.CustomFetch("SalesInvoice/GetSalesInvoicesBySalesOrder", "?salesOrderHeaderId=" + salesOrderHeaderId, 'POST',session);
  }

  GetPlanningDate(session, todayDate) {

    return super.CustomFetch("SalesInvoice/GetPlanningDate", "?today=" + todayDate, 'POST', session);
  }

}
