import { useState, useRef, useEffect } from "react";

import { SalesOrderServiceSOAP } from "../../soapServices/SalesOrderService.js";
import { SalesInvoiceServiceSOAP } from "../../soapServices/SalesInvoiceService.js";
import { FileAttachmentService } from "../../soapServices/FileAttachmentService.js";

export default function usePrintPreview(capstoneVersion){

    const fileAttachmentService = useRef();
    const salesInvoiceServiceSOAP = useRef();
    const salesOrderServiceSOAP = useRef();

    const intialModalData = {
        show: false,
        fileLocation: "",
        fileName: ""
    };    
    const [modalData, setModalData] = useState(intialModalData);

    useEffect(() => {
        var capstoneServiceUrl = process.env.REACT_APP_CAPSTONE_SERVICE_URL;
        fileAttachmentService.current = new FileAttachmentService(capstoneServiceUrl, capstoneVersion);
        salesInvoiceServiceSOAP.current = new SalesInvoiceServiceSOAP(capstoneServiceUrl, capstoneVersion);
        salesOrderServiceSOAP.current = new SalesOrderServiceSOAP(capstoneServiceUrl, capstoneVersion);
    }, []);

    function hidePrintPreview(session, fileName){
        return fileAttachmentService.current.DeleteTemporaryFile(session, fileName)
        .then(deleteResult =>{
            setModalData(intialModalData);
            return true;
        });        
    }

    function showPrintPreview(session, item, loadingTicket = false){
        
        if(item.label.startsWith("PL#")){  
            if(loadingTicket){
                return salesOrderServiceSOAP.current.PrintLoadingTicket(session, item.id, item.secondaryId, false)
                .then(printResult =>{
                    return onPrintDone(printResult.result);
                })
            }
            else{                                       
                return salesInvoiceServiceSOAP.current.PrintPackingList(session, item.id, false, item.includePricing)
                .then(printResult =>{
                    return onPrintDone(printResult.result);
                })
            }
        }
        else{ //Sales Orders
            if(loadingTicket){
                return salesOrderServiceSOAP.current.PrintLoadingTicket(session, undefined, item.id, false)
                .then(printResult =>{
                    return onPrintDone(printResult.result);
                })
            }
            else{           
                return salesOrderServiceSOAP.current.PrintShipper(session, item.id, item.includePricing)
                .then(printResult =>{
                    return onPrintDone(printResult.result);
                });
            }
        }    
    }

    function onPrintDone(result){
        setModalData({
            show: true,
            fileLocation: process.env.REACT_APP_CAPSTONE_SERVICE_URL + "Storage/" + result,
            fileName: result,
        });
        return true;
    }

    return [modalData, showPrintPreview, hidePrintPreview]
};