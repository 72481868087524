import React, { useState, useEffect } from 'react';
import DropTarget from '../dragDropContainer/DropTarget';
import DragDropContainer from '../dragDropContainer/DragDropContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip';
import { faCalendarAlt, faEdit, faInfoCircle, faLock, faMapMarkerAlt, faNote, faTimesCircle, faTruck} from '@fortawesome/pro-solid-svg-icons';
import './Item.css';

export default function Item(props) {

  const [loadCreated, setLoadCreated] = useState(props.loadCreated);
  useEffect(() => {
    setLoadCreated(props.loadCreated);
  }, [props.loadCreated]);

  const [hidden, setHidden] = useState(props.hidden);
  useEffect(() => {
    setHidden(props.hidden);
  }, [props.hidden]);

  const [deliveryWindowInDays, setDeliveryWindowInDays] = useState(0);
  useEffect(() => { 
    if(props.itemData.deliveryWindowFromDate && props.itemData.deliveryWindowToDate){
      const difference = props.itemData.deliveryWindowToDate.getTime() - props.itemData.deliveryWindowFromDate.getTime();
      var days = Math.ceil(difference / (1000 * 3600 * 24));
      setDeliveryWindowInDays(days);
    }
  }, []);

    
  function handleDrop(e){
    console.log("handleDrop");
    e.stopPropagation(); 
    props.swap(e.dragData.index, props.index, e.dragData);
    e.containerElem.style.visibility="hidden"; 
  };
  
  function deleteMe(e) {
    console.log("delete Item");
    props.kill(props.uid);
  };

  function showMore(e){
    console.log("showMore Item");
    e.preventDefault();
    props.showMoreInfo(props.itemData);
  }

  function showLoadingTicket(e){
    console.log("show loading ticket");
    e.preventDefault();
    props.showMoreInfo(props.itemData, true);
  }

  function editNote(e){
    console.log("editNote Item");
    e.preventDefault();
    props.editNote(props.itemData.secondaryId, props.itemData.note);
  }

  function selectItem(e){
    props.selectItem(props.itemData.id);
  }

  function remove(e){
    props.remove(props.itemData);
  }

  function updateShipDate(e){
    props.updateShipDate(props.itemData);
  }

  function updateTargetedShipDate(e){
    props.updateTargetedShipDate(props.itemData);
  }

  function formatTime(time){
    if(time == null) return;

    let timeArray = time.split(":");
    let hours = Number(timeArray[0]);
    let minutes = timeArray[1];

    return (hours > 12 ? hours - 12 : hours) + ":" + minutes + (hours > 12 ? "pm" : "am");
  }

  return (
    <div className={"box_item_component" + (hidden ? " hidden" : "")}>
      <DragDropContainer
          targetKey="boxItem"
          dragHandleClassName="item"
          dragData={{
            ...props.itemData,
            index: props.index, 
          }}
          onDrop={deleteMe}
          disappearDraggedElement={true}
          noDragging={loadCreated || props.itemData.lockedBy ? true : false}
        >
          <DropTarget
            onHit={handleDrop}
            targetKey={loadCreated ? "no-drop" : "boxItem"}
          >
            <div className={"outer " + (props.itemData.overdue ? "overdue" : props.itemData.future ? "future" : "")}>
            {props.itemData.lockedBy && 
            (<span title={"Locked by " + props.itemData.lockedBy} className="box-item-option"><FontAwesomeIcon icon={faLock} /></span>)}
            {!props.itemData.lockedBy && (<span className="box-item-option box-item-click" title="Edit routing note"
              onClick={editNote}><FontAwesomeIcon icon={faEdit} /></span>)} 
            <div className={"item" + (props.itemData.lockedBy ? " locked" : "")}
              id={props.uid} title={props.itemData.deliveryAddressName}>
                <span>
                  {props.itemData.label + 
                  (props.itemData.cityAndState ? " - " + props.itemData.cityAndState : "")}
                </span>
                &nbsp;
                <span className="float-right">{Math.round(props.itemData.totalWeight).toLocaleString('en')} lbs
                  {props.stopNumber ? " - " + props.stopNumber: ""}</span>
              {props.itemData.backOrder ? "***BACKORDER***" : ""}
              {props.itemData.note?.length > 0 && (<><br/>{props.itemData.note}</>)}
              {props.itemData.deliveryTimeWindows?.length > 0 && (
                <>
                  <br/>
                  {props.itemData.deliveryTimeWindows.map((window, index) => {
                    return <span title="Delivery window" key={index}>{index > 0 ? " | " : ""}{formatTime(window.startTime)}-{formatTime(window.endTime)}</span>
                  })}
                </>                
              )}
            </div>
            <div className="item-options float-right">
            {!props.itemData.manualStop && <span className="box-item-option box-item-click" title="Show loading ticket"
                    onClick={showLoadingTicket}>                    
                    <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
              </span>}
              {props.itemData.lat && props.itemData.lng &&
              (<span className="box-item-option box-item-click" title="Select in map"
                    onClick={selectItem}>                    
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
              </span>)}
              {!loadCreated && props.itemData.label.startsWith("PL#") && 
              <span className="box-item-option box-item-click" title="Update ship date" onClick={updateShipDate}>                    
                <FontAwesomeIcon icon={faCalendarAlt} />
              </span>}
              {!loadCreated && props.itemData.label.startsWith("SO#") && 
              <span  className={"box-item-option " + (deliveryWindowInDays > 1 ? "box-item-click " : "box-item-grayed-out")} 
                title={deliveryWindowInDays > 1 ? "Update targeted ship date" : "Targeted Ship/Delivery date can only be changed with the delivery window spans more than 1 day."}
                onClick={deliveryWindowInDays > 1 ? updateTargetedShipDate : null}>                    
                <FontAwesomeIcon icon={faCalendarAlt} />
              </span>}
              {!props.itemData.manualStop && <span className="box-item-option box-item-click" title="Show more info"
                    onClick={showMore}>                    
                    <FontAwesomeIcon icon={faInfoCircle} />
              </span>}                       
              {loadCreated && !props.itemData.manualStop && (<span style={{display:"block"}} className="box-item-option box-item-click" title="Remove from load"
                onClick={remove}>                    
                <FontAwesomeIcon icon={faTimesCircle} />
              </span>)}
              {props.itemData.manualStop && (<span style={{display:"block"}} className="box-item-option box-item-click" title="Remove stop"
                onClick={remove}>                    
                <FontAwesomeIcon icon={faTimesCircle} />
              </span>)}
              {props.itemData.deliveryNote?.length > 0 && (<span className="box-item-option box-item-click" title="Delivery note"
                data-tooltip-id={"delivery-note-tooltip-" + props.itemData.id}
                data-tooltip-content={props.itemData.deliveryNote}>
                <FontAwesomeIcon icon={faNote} />
              </span>)}
            </div>                
          </div>
        </DropTarget>
      </DragDropContainer>
      <Tooltip id={"delivery-note-tooltip-" + props.itemData.id} openOnClick={true} delayShow={0} 
        style={{zIndex:3, maxWidth: "400px"}}/>
    </div>
  );  
}