import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import useModal from '../useModal/useModal';
import CustomModal from '../useModal/CustomModal';
import { Button, Modal, Spinner } from "react-bootstrap";
import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";
import { ShippingService } from "../../soapServices/ShippingService";
import { LoginUserService } from "../../soapServices/LoginUserService";
import { LoadService } from "../../restServices/LoadService";

export default function CreateLoad(props){

  const shippingService = useRef();
  const loginUserService = useRef();
  const loadService = useRef();

  const session = useSelector(state => state.auth.sessionData.session);
  const currentWarehouse = useSelector(state => state.menuInfo.currentWarehouse);
  const [modalData, showModal, hideModal] = useModal();
  const [showCreateLoadDialog, setShowCreateLoadDialog] = useState(false);
  const [shipmentDate, setShipmentDate] = useState("");
  const [loadNumber, setLoadNumber] = useState(props.loadNumber); 
  const [driver, setDriver] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [truck, setTruck] = useState("");
  const [trucks, setTrucks] = useState([]);
  const [wait, setWait] = useState(false);

  useEffect(() => {
    shippingService.current = new ShippingService(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);
    loginUserService.current = new LoginUserService(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);
    loadService.current = new LoadService(process.env.REACT_APP_CAPSTONE_REST_API); 
  }, []);   

  useEffect(() => {
    if (props.showCreateLoadDialog === true) {
      onShowCreateLoadDialog();
    } else {
      setShowCreateLoadDialog(props.showCreateLoadDialog);
    }
  }, [props.showCreateLoadDialog]); 

  useEffect(() => {
    setLoadNumber(props.loadNumber);
  }, [props.loadNumber]); 

  const onShowCreateLoadDialog = () => {
    setShowCreateLoadDialog(true);
    if(loadNumber){
      setWait(true);
      loadService.current.GetLoad(session, loadNumber).then(loadResult => {
        console.log(loadResult.result);
        setWait(false);
        if(loadResult.result){
          setWait(true);
          setShipmentDate(new Date(loadResult.result.delivery_Date).toLocaleDateString());
          Promise.all([getDrivers(loadResult.result.driver_Id),
            getTrucks(loadResult.result.truck_Id)])
          .then(allDone => {
            setWait(false);
          });
        }
      });
    }
    else{
      let inputPlaningDate = document.getElementById("inputPlaningDate");
      if(inputPlaningDate && inputPlaningDate.value !== ""){
        setShipmentDate(inputPlaningDate.value);
      }
      setWait(true);
      Promise.all([getDrivers(), getTrucks()]).then(allDone => {
        setWait(false);
      });
    }
    
  };

  const validateLoad = (event) =>{
    event.preventDefault();

    if(loadNumber){
      updateLoad();
    }
    else{
      let packingLists = props.items.filter(i => i.label.startsWith("PL#"));
      if(packingLists.length === 0){
        return false;
      }
      let currentTruck = trucks.find(i => i.TruckId === truck);
      let loadTotalWeight = 0; 
      packingLists.forEach(i => {
        loadTotalWeight += i.totalWeight;
      });
      if(currentTruck && currentTruck.MaxLoadWeight != null && currentTruck.MaxLoadWeight < loadTotalWeight){
        showModal("Truck max load weight exceeded.", MESSAGES_CONSTANTS.MODAL_WARNING_TITLE);
        return false;
      }
      createLoad(event);
    }
  }

  const createLoad = (event) => { 
    setWait(true);
    hideModal();
    let packingLists = props.items.filter(i => i.label.startsWith("PL#"));    
    loadService.current.CreateLoad(
        session,
        currentWarehouse.WarehouseId,
        shipmentDate,
        driver,
        truck,
        packingLists,
        props.routeId,
        props.runNumber
      )
      .then((createloadResult) => {
        console.log(createloadResult);
        setWait(false);
        if (createloadResult.error) {
          showModal(
            createloadResult.error.exceptionMessage,
            MESSAGES_CONSTANTS.MODAL_ERROR_TITLE,
            MESSAGES_CONSTANTS.MODAL_OK_OPTION,
            hideAllDialogs
          );          
        }
        else{
          setShowCreateLoadDialog(false);
          var driverObject = drivers.find(d => d.LoginUserId === driver);
          var truckObject = trucks.find(t => t.TruckId === truck);
          console.log(driverObject)
          props.onCreateLoadDone(createloadResult.result, driverObject.Name, truckObject.Name, shipmentDate);
        }        
      });
  };

  const updateLoad = (event) => { 
    setWait(true);
    hideModal();
    let packingLists = props.items.filter(i => i.label.startsWith("PL#"));

    loadService.current.UpdateLoad(
        session,
        loadNumber,
        driver,
        truck,
        packingLists
      )
      .then((updateloadResult) => {
        console.log(updateloadResult);
        setWait(false);
        if (updateloadResult.error) {
          showModal(updateloadResult.error, MESSAGES_CONSTANTS.MODAL_ERROR_TITLE);          
        }
        else{
          setShowCreateLoadDialog(false);
          props.onUpdateLoadDone(driver.Name, truck.Name);
        }        
      });
  };

  const hideCreateLoadDialog = () => {    
    props.onCancelCreateLoad();
    setShowCreateLoadDialog(false);
  };  

  const getDrivers = (selectedDriver) => {
    return loginUserService.current.GetAllDriverLoginUsers(session).then((driversResult) => {
        console.log(driversResult);
        if (driversResult.error &&
          driversResult.error.detail &&
          driversResult.error.detail.CapstoneException) {
          setWait(false);
          showModal(driversResult.error.detail.CapstoneException.ErrorMessage, MESSAGES_CONSTANTS.MODAL_ERROR_TITLE);
        } else if (driversResult.result && driversResult.result.length > 0) {
          setDrivers(driversResult.result);
          setDriver(selectedDriver ?? driver);
        }
      });
  };

  const getTrucks = (selectedTruck) => {
    return shippingService.current.GetAllTrucks(session).then((trucksResult) => {
        console.log(trucksResult);
        if (trucksResult.error && trucksResult.error.detail && trucksResult.error.detail.CapstoneException) {
          setWait(false);
          showModal(trucksResult.error.detail.CapstoneException.ErrorMessage, MESSAGES_CONSTANTS.MODAL_ERROR_TITLE);
        } else if (trucksResult.result && trucksResult.result.length > 0) {
          setTrucks(trucksResult.result);
          setTruck(selectedTruck);
        }
      });
  };

  const hideAllDialogs = () =>{
    hideModal();
    setShowCreateLoadDialog(false);
    props.refreshRoutesData();
  }

  return (
    <div>
      <Modal show={showCreateLoadDialog} onHide={(e) => {}} centered>
        <form onSubmit={validateLoad}>
        <Modal.Header>
          <Modal.Title>{loadNumber ? "Edit Load #" + loadNumber : "Create Load"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 col-12">
            <div className="row">        
              <label className="label-title-forInput col-4">Shipment: </label>
              <input
              className={"form-control col-4"}
              name="shipmentDate"
              value={shipmentDate}
              disabled={true}></input>
            </div>
            <br/>
            <div className="row">
              <label className="label-title-forInput col-4">Driver: </label>
              <select
                className={"form-control col-8 "}
                name="driver"
                value={driver}
                required={true}
                onChange={(e) => setDriver(e.target.value)}
              >
                <option value={""}>Select a driver</option>
                {drivers.map((driver, index) => {
                  return driver.RetiredFlag === "false" ? (
                    <option
                      key={index}
                      value={driver.LoginUserId}
                      label={driver.Name}
                    >
                      {driver.Name}
                    </option>
                  ) : null;
                })}
              </select>
            </div>
            <br/>
            <div className="row">
              <label className="label-title-forInput col-4">Truck: </label>
              <select
                className={"form-control col-8 "}
                name="truck"
                value={truck}
                required={true}
                onChange={(e) => setTruck(e.target.value)}
              >
                <option value={""}>Select a truck</option>
                {trucks.map((truck, index) => {
                  return truck.RetiredFlag === "false" ? (
                    <option
                      key={index}
                      value={truck.TruckId}
                      label={truck.Name}
                    >
                      {truck.Name}
                    </option>
                  ) : null;
                })}
              </select>
            </div>
            <br/>
            <div className="row">
              <label className="label-title-forInput col-4">Total Weight: </label>
              <label className="label-title-forInput col-8">{props.totalWeight} lbs</label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ width: "7rem" }} onClick={hideCreateLoadDialog}>
            Cancel
          </Button>
          <Button
            style={{ width: "8rem" }}
            type="submit"
          >
            {loadNumber ? "Save Load" : "Create Load"}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
      <Modal
        className="modal-spinner"
        show={wait}
        centered
        onHide={(e) => {}}
        backdropClassName={"custom-backdrop"}
      >
        <Spinner animation="border" role="status" />
        <span className="modal-spinner-text">
          {MESSAGES_CONSTANTS.MODAL_SPINNER_MESSAGE}
        </span>
      </Modal>
      <CustomModal modalData={modalData} hideModal={hideModal}/>
    </div>
  );
}
