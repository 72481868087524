import React, { useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function CustomModal(props){

    const firstButtonRef = useRef(null);
    useEffect(() => {
      if(props.modalData.show && firstButtonRef.current){
        firstButtonRef.current.focus();
      }
    }, [props.modalData.show]);
  
    const secondButtonRef = useRef(null);
    useEffect(() => {
      if(props.modalData.show && secondButtonRef.current){
        secondButtonRef.current.focus();
      }
    }, [props.modalData.show]); 

    return (
        <Modal
            show={props.modalData.show}
            onHide={() => {}}
            centered
            autoFocus={true}
            size="m"
            backdropClassName={"custom-backdrop"}
        >
            <Modal.Header>
            <Modal.Title>{props.modalData.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{props.modalData.message}</p>
            </Modal.Body>
            <Modal.Footer>
            <Button
                onClick={props.modalData.firstButtonCallback ?? props.hideModal}
                ref={firstButtonRef}
            >
                {props.modalData.firstButtonText}
            </Button>
            {props.modalData.secondButtonText && (<Button
                onClick={props.modalData.secondButtonCallback}
                ref={secondButtonRef}
            >
                {props.modalData.secondButtonText}
            </Button>)}
            </Modal.Footer>
        </Modal>);
}