import React, { useState, useEffect, useRef } from "react";
import "./RoutingScreen.css";
import "./Switch.css";

import { useSelector, useDispatch } from "react-redux";
import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";
import { setMenuInfo, setFiltersReducer } from "../../reducers/menuInfo";
import { logOut } from "../../reducers/auth";
import { initServices, loadRoutesAndItems } from "../../reducers/routesReducer";
import { history } from "../../Store";
import Route from "../routeContainer/Route";
import DateInput from "../common/DateInput";
import DatePicker from"react-datepicker";

import useModal from '../useModal/useModal';
import CustomModal from '../useModal/CustomModal';
import usePrintPreview from '../printPreviewModal/usePrintPreview';
import PrintPreviewModal from '../printPreviewModal/PrintPreviewModal';
import UpdatePackingListDialog from '../updatePackingList/UpdatePackingListDialog';

import { UserSessionService } from "../../soapServices/UserSessionService";
import { SalesOrderService } from "../../restServices/SalesOrderService.js";

import { Button, Container, Modal, Spinner} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync} from '@fortawesome/pro-solid-svg-icons';

import { GoogleApiWrapper} from 'google-maps-react-17';
import"react-datepicker/dist/react-datepicker.css";
import UpdateSalesOrderDialog from "../updateSalesOrder/UpdateSalesOrderDialog";
import AddManualStopDialog from "../addManualStop/AddManualStopDialog";
import RoutingMap, { useRoutingMap } from "./RoutingMap";

function RoutingScreen(props)  {

  const session = useSelector(state => state.auth.sessionData.session);
  const currentWarehouse = useSelector(state => state.menuInfo.currentWarehouse);
  const routesOrientation = useSelector(state => state.menuInfo.routesOrientation);
  const filtersSaved = useSelector(state => state.menuInfo.filters);
  const routesData = useSelector(state => state.routesReducer.routesData);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setMenuInfo("Routing Screen (Delivery Mode)"));
    initServices(process.env.REACT_APP_CAPSTONE_REST_API, process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);
  }, [dispatch]);

  const [modalData, showModal, hideModal] = useModal();
  const [printPreviewData, showPrintPreview, hidePrintPreview] = usePrintPreview(props.capstoneVersion);
  const [planningDate, setPlanningDate] = useState(new Date());
  const [preventCalendarOpenOnFocus, setPreventCalendarOpenOnFocus] = useState(false);
  const [focusPlaningDate, setFocusPlaningDate] = useState(false);
  const [validPlaningDate, setValidPlaningDate] = useState(false);
  const filtersDefault = {
    includeInvoicesPriorDates: true,
    includeInvoicesFutureDates: false,
    showSalesOrders: true,
    queryOrdersWithinDeliveryWindow: false,
    includeOverdueOrders: true
  }
  const [filters, setFilters] = useState(filtersSaved?.routing ?? filtersDefault);
  const [showEditNoteDialog, setShowEditNoteDialog] = useState(false);
  const [editingRoutingNote, setEditingRoutingNote] = useState("");
  const [currentItemNote, setCurrentItemNote] = useState(undefined);
  const [wait, setWait] = useState(false);
  const [showUpdatePackingList, setShowUpdatePackingList] = useState(false);
  const [showUpdateSalesOrder, setShowUpdateSalesOrder] = useState(false);
  const [showAddManualStopDialog, setShowAddManualStopDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState();  
  const routingMap = useRoutingMap({...props, setWait, planningDate});

  useEffect(() => {
    console.log("load data")
    loadRoutesAndOrders();    
  }, [filters]);

  const textareaEditNote = useRef(null);
  const userSessionService = new UserSessionService(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);
  const salesOrderService = new SalesOrderService(process.env.REACT_APP_CAPSTONE_REST_API);
  const refreshDataTimer = useRef(null);

  const clearRefreshDataTimer = () => {
    if (refreshDataTimer.current) {
      clearTimeout(refreshDataTimer.current);
    }
  }

  const resetRefreshDataTimer = () => {     
    clearRefreshDataTimer();   
    refreshDataTimer.current = setTimeout(e => {
      showModal("The data displayed may be out of date. Would you like to refresh?", MESSAGES_CONSTANTS.MODAL_INFO_TITLE,
      "Refresh", (e => loadRoutesAndOrders()), "Logout", logout);
    }, Number(process.env.REACT_APP_REFRESH_DATA_INTERVAL_MINUTES) * 60000);    
  }

  const logout = () => {
    hideModal();
    setWait(true);
    return userSessionService.Logout(session).then((userResultSet) => {
      setWait(false);
      let error = userResultSet.error;
      if (error) {
        console.log("Could not logout");
      } else {
        clearRefreshDataTimer();
        logOut();
        console.log("logout finished");
        history.push("/login");
      }
    });
  };

  const showMoreInfoDialog = (item, isLoadingTicket=false) => {
    setWait(true);
    showPrintPreview(session, item, isLoadingTicket).then(result => {
      setWait(false);
    });
  }

  const onHidePrintPreview = () => {
    setWait(true);
    hidePrintPreview(session, printPreviewData.fileName).then(result => {
      setWait(false);
    });
  }

  const updateShipDate = (item) => {
    setShowUpdatePackingList(true);
    setCurrentItem(item);
  }

  const updateTargetedShipDate = (item) => {
    setShowUpdateSalesOrder(true);
    setCurrentItem(item);
  }

  const onShowEditNoteDialog = (orderId, note) => {
    console.log(orderId + ": " + note);    
    setShowEditNoteDialog(true);
    setEditingRoutingNote(note);
    setCurrentItemNote({
      orderId: orderId,
      note: note
    });
    setTimeout(e=> {
      if(textareaEditNote.current)
        textareaEditNote.current.focus();
    }, 100);
  }

  const saveRoutingNote = (e) =>{
    e.preventDefault();    
    setWait(true);
    setShowEditNoteDialog(false);

    setTimeout(e => {
      salesOrderService.UpdateRoutingNote(session, currentItemNote.orderId, editingRoutingNote)
      .then(updateResult =>{
        setWait(false);
        if(!updateResult.error){
          setCurrentItemNote({ 
            ...currentItemNote,
            note: editingRoutingNote
          });
        }
      });
    }, 100);
  }

  /*Load default Planning date, Routes and PL/SO items*/
  const loadRoutesAndOrders = (planningDateParam) => {
    console.log("loadRoutesAndOrders")
    hideModal();            

    if(!currentWarehouse){
      showModal("Please select a warehouse and save it.");
      return;
    }

    if(!planningDateParam){
      planningDateParam = planningDate;
    }

    setWait(true);
    resetRefreshDataTimer();    
    
    dispatch(loadRoutesAndItems(planningDateParam, currentWarehouse.WarehouseId, session, filters)).then(response =>{
      setWait(false);          
      if(response.error){
        showModal(response.error);
      }
      else if(response.info){
        showModal(response.info, MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
      }
      routingMap.resetMapData(response.mapLocations, planningDateParam);
      routingMap.getTrucksLocationsForToday(planningDateParam, response.routes);
    });
  }

  const setPlaningDate = (valid, dateString) => {
    if (valid) {
      let date = new Date(dateString);
      setPlanningDate(date); 
      loadRoutesAndOrders(date);     
    }
    else if(dateString === ""){
      setPlanningDate(null);     
    }
    setValidPlaningDate(valid);
  };

  const onCalendarClose = () => {
    setPreventCalendarOpenOnFocus(true);
    setTimeout((e) => {
      setPreventCalendarOpenOnFocus(true);
      setFocusPlaningDate(true);
    }, 100);
  };

  const onChangeDate = (date) => {
    setPlanningDate(date);
    loadRoutesAndOrders(date);
    setValidPlaningDate(true);
    setFocusPlaningDate(false);
  };

  const customHideModal = (e) =>{
    if(!currentWarehouse){
      history.push("/warehouse");
    }
    else{
      hideModal();
    }
  }

  const onChangeFilters = (e) => {
    let filtersValue = {
      ...filters, 
      [e.target.name]: e.target.checked
    };
    setFilters(filtersValue);

    dispatch(setFiltersReducer({
       ...filtersSaved, 
      routing: filtersValue
    }));
  };

  const addManualStop = (routeId, runNumber, items, color) => {
    setShowAddManualStopDialog(true);
    setCurrentItem({
      routeId: routeId,
      runNumber: runNumber, 
      sequenceNumber: items.length,
      targetDeliveryDate: planningDate,
      items: items, 
      color: color
    });
  }

  return (
    <Container fluid className="page-container">
      <button style={{position: "absolute", right: "10px", zIndex:"2"}}
        onClick={ e =>  loadRoutesAndOrders()}
        className="btn btn-primary"> 
        <FontAwesomeIcon icon={faSync} />          
      </button>        
      <div className="row">
        <label className="label-title-forInput col-md-3 col-sm-12">Logistics Planning for</label>
        <DatePicker
          style={{zIndex:"3"}}
          id="inputPlaningDate"
          name="planningDate"
          required={true}            
          selected={planningDate}
          onChange={onChangeDate}
          dateFormat={"M/d/yyyy"}
          disabled={wait}
          preventOpenOnFocus={preventCalendarOpenOnFocus}
          onCalendarClose={onCalendarClose}
          customInput={
          <DateInput              
            focusDate={focusPlaningDate}
            setDate={(valid, date) => {
              setPlaningDate(valid, date);
            }}
            onEnterKeyDown={e => {
              if(validPlaningDate) loadRoutesAndOrders();
            }}
          />            
          }
          autoComplete={"off"}
        />
        <label className="label-title-forInput col-md-4 col-sm-12">for {currentWarehouse &&
                currentWarehouse.BriefName} Warehouse</label>
      </div>
      <br/>
      <RoutingMap google={props.google} loadRoutesAndOrders={loadRoutesAndOrders} 
        mapState={routingMap} showModal={showModal} hideModal={hideModal}/>
      <br/> 
      <div className="row">
        <div className="col-12 checkbox-group">
          <input 
            className={"form-control"} 
            type="checkbox"
            checked
            readOnly
          />
          <label>Show Packing Lists</label>
        </div>
      </div>    
      <div className="row">
        <div className="col-12 checkbox-group">
          &nbsp;&nbsp;&nbsp;
          <input 
            className={"form-control"} 
            type="checkbox"
            name="includeInvoicesPriorDates"
            checked={filters.includeInvoicesPriorDates}
            onChange={onChangeFilters}
          />
          <label>Include packing lists from prior dates not associated with a load or marked shipped</label>
        </div>
      </div>  
      <div className="row">
        <div className="col-12 checkbox-group">
          &nbsp;&nbsp;&nbsp;
          <input 
            className={"form-control"} 
            type="checkbox"
            name="includeInvoicesFutureDates"
            checked={filters.includeInvoicesFutureDates}
            onChange={onChangeFilters}
          />
          <label>Show packing lists for future dates</label>
        </div>
      </div>
      <hr/> 
      <div className="float-right box_item_component orders-labels">
        <div>Ship Date</div>
        <div className="outer overdue order-label"></div><span>Overdue&nbsp;</span>
        <div className="outer today order-label"></div>Today&nbsp;
        <div className="outer future order-label"></div>Future
      </div>       
      <div className="row">
        <div className="col-12 checkbox-group">
          <input 
            className={"form-control"} 
            type="checkbox"
            name="showSalesOrders"
            onChange={onChangeFilters}
            checked={filters.showSalesOrders}
          />
          <label>Show Sales Orders</label>
        </div>
      </div>    
      <div className="row">
        <div className="col-12 checkbox-group">
          &nbsp;&nbsp;&nbsp;
          <label>By target ship date</label>
          <label className="switch">
            <input 
              type="checkbox"
              name="queryOrdersWithinDeliveryWindow"
              checked={filters.queryOrdersWithinDeliveryWindow}
              onChange={onChangeFilters}
            />
            <span className="slider round"></span>
          </label>
          <label>Planning date is within delivery window</label>
        </div>
      </div> 
      <div className="row">
        <div className="col-12 checkbox-group">
          &nbsp;&nbsp;&nbsp;
          <input 
            className={"form-control"} 
            type="checkbox"
            name="includeOverdueOrders"
            checked={filters.includeOverdueOrders}
            onChange={onChangeFilters}
          />
          <label>Include overdue sales orders</label>
        </div>
      </div>
      <div className={"routes-panel " + (routesOrientation === "Horizontal" ? "row" : "")}>                 
          {routesData.map((route, index) => {
            return (
            <Route key={index} index={index} routeData={route} google={props.google} orientation={routesOrientation} 
            editNote={onShowEditNoteDialog} currentItemNote={currentItemNote} selectItem={routingMap.selectItemInMap} 
            showMoreInfo={showMoreInfoDialog} refreshRoutesData={loadRoutesAndOrders} filterMapLocations={routingMap.onRouteFilterChange} 
            updateShipDate={updateShipDate} updateTargetedShipDate={updateTargetedShipDate} addManualStop={addManualStop}
            capstoneVersion={props.capstoneVersion}/>)
          })}
      </div>
      <Modal
          show={showEditNoteDialog}
          onHide={() => setShowEditNoteDialog(false)}
          centered
          autoFocus={true}
        >
        <Modal.Header>
          <Modal.Title>Edit Routing Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input 
            className={"edit-routing-note"}
            maxLength="50"
            name="editingRoutingNote"
            value={editingRoutingNote ?? ""}
            onChange={(e) => setEditingRoutingNote(e.target.value)}
            ref={textareaEditNote}              
          >
          </input>
        </Modal.Body>
        <Modal.Footer>  
        <Button onClick={() => setShowEditNoteDialog(false)}>Cancel</Button>            
          <Button onClick={saveRoutingNote}>Save</Button>
        </Modal.Footer>
      </Modal>     
      <Modal
        className="modal-spinner"
        show={wait}
        centered
        onHide={(e) => {}}
      >
        <Spinner animation="border" role="status" />
        <span className="modal-spinner-text">
          {MESSAGES_CONSTANTS.MODAL_SPINNER_MESSAGE}
        </span>
      </Modal>
      <CustomModal modalData={modalData} hideModal={customHideModal}/>
      <PrintPreviewModal modalData={printPreviewData} hideModal={onHidePrintPreview}/> 
      <UpdatePackingListDialog currentItem={currentItem} show={showUpdatePackingList} shipDate={true}
          hide={() => setShowUpdatePackingList(false)} setWait={setWait} onUpdateDone={() => loadRoutesAndOrders()}
          capstoneVersion={props.capstoneVersion}/>
      <UpdateSalesOrderDialog currentItem={currentItem} show={showUpdateSalesOrder} 
          hide={() => setShowUpdateSalesOrder(false)} setWait={setWait} onError={(error) => showModal(error)}
          onUpdateDone={() => loadRoutesAndOrders()} capstoneVersion={props.capstoneVersion}/>
      <AddManualStopDialog google={props.google} show={showAddManualStopDialog} currentItem={currentItem}
        hide={() => setShowAddManualStopDialog(false)} setWait={setWait} />
    </Container>
  );
}
export default GoogleApiWrapper(
  (props) => ({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  }
))(RoutingScreen);