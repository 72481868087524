import React from "react";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";

var previousPath = "/login";

const PrivateRoute = ({
  component: Component,
  path: currentPath,
  isLogged,
  menuItems,
  showErrorDialog,
  capstoneVersion,
  ...rest
}) => {
  let isLoggedInSession = sessionStorage.getItem("isLogged");
  let pathPermission = getPathPermission(currentPath, menuItems);
  //console.log(currentPath + " permission: " + pathPermission);

  if ((isLogged === true || isLoggedInSession) && pathPermission === "N") {
    showErrorDialog("You’re not authorized to use this program.");
  } else {
    previousPath = currentPath;
  }

  return (isLogged === true || isLoggedInSession) && pathPermission === "N" ? (
    <Route {...rest} render={(props) => <Redirect to={previousPath} />} />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        isLogged === true || isLoggedInSession ? (
          <Component {...props} capstoneVersion={capstoneVersion} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const getPathPermission = (path, menuItemsParam1) => {
  let currentPermission = "Y";
  let permissionFound = false;

  searchMenuItem(menuItemsParam1, path);

  function searchMenuItem(menuItemsParam2, itemPath) {
    for (let menuItem of menuItemsParam2) {
      if (menuItem.items === undefined) {
        //console.log(menuItem);
        if (
          menuItem.path &&
          menuItem.path === itemPath &&
          menuItem.permission
        ) {
          // console.log("Permission found");
          // console.log(menuItem.permission);
          permissionFound = true;
          currentPermission = menuItem.permission;
          break;
        }
      } else if (!permissionFound) {
        searchMenuItem(menuItem.items, itemPath);
      }
    }
  }
  return currentPermission;
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.auth.isLogged,
    menuItems: state.menuInfo.menuItems,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
