import React from "react";
import "./Footer.css";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class Footer extends React.Component {

  render() {
    return (
      this.props.isLogged && (
        <footer className="custom-footer">
          <div>
            <span className="custom-span-footer span-customLeft">
              Warehouse:
              {" " +
                (this.props.currentWarehouse &&
                  this.props.currentWarehouse.BriefName)} 
            </span>

            <span className="custom-span-footer span-customRight">
              User: {" " + this.props.sessionData.loginUser.Name}
            </span>
          </div>
        </footer>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    isLogged: state.auth.isLogged,
    sessionData: state.auth.sessionData,
    currentWarehouse: state.menuInfo.currentWarehouse,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
