var aesjs = require('aes-js');

export class BaseSoapService {
  url = "";
  serviceName = "";
  capstoneVersion = "";

  constructor(baseUrl, wsdl, serviceName, capstoneVersion) {
    this.url = baseUrl + wsdl;
    this.serviceName = serviceName;
    this.capstoneVersion = capstoneVersion;
  }

  makeSoapCall(call, args, nodeNameForArrayItem) {
    console.log("makeSoapCall");
    let serviceKey = this.generateServiceKey();
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.timeout = 120000;
      xhr.open("POST", this.url, true);
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          //console.log(xhr.responseXML);
          resolve(
            this.xmlToJson(xhr.responseXML)["soap:Envelope"]["soap:Body"]
          );
        } else {
          var errorFault = "Error on request status";
          console.log(errorFault);
          if (xhr.responseXML) {
            errorFault = this.xmlToJson(xhr.responseXML)["soap:Envelope"][
              "soap:Body"
            ]["soap:Fault"];
          }
          console.log(errorFault);
          reject(errorFault);
        }
      };
      xhr.onerror = () => {
        console.log("Error ocurred in server");
        console.log(xhr);
        var error = {};
        error.Message =
          "Could not connect to the Capstone SOAP service.";
        console.log(error);
        reject(error);
      };
      xhr.ontimeout = () => {
        console.log("Time Out Error");
        console.log(xhr);
        var error = {};
        error.Message = "Timeout. The server did not respond on time";
        console.log(error);
        reject(error);
      };

      xhr.setRequestHeader("Content-Type", "text/xml");
      xhr.setRequestHeader("ServiceKey", serviceKey);
      xhr.setRequestHeader(
        "SOAPAction",
        "http://www.bayernsoftware.com/soap/" + this.serviceName + "/" + call
      );

      var requestBody =
        '<?xml version="1.0" encoding="utf-8"?>' +
        '<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
        'xmlns:xsd="http://www.w3.org/2001/XMLSchema" ' +
        'xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"> ' +
        "<soap:Body><" +
        call +
        ' xmlns="http://www.bayernsoftware.com/soap/' +
        this.serviceName +
        '/">' +
        this.objectToXml(args, nodeNameForArrayItem) +
        "</" +
        call +
        "></soap:Body></soap:Envelope>";

      xhr.send(requestBody);
    });
  }

  arrayName = "";
  objectToXml(obj, nodeNameForArrayItem) {
    var xml = "";

    for (var prop in obj) {
      if (!obj.hasOwnProperty(prop)) {
        continue;
      }

      if (Array.isArray(obj[prop])) {
        this.arrayName = prop;
      }

      if (obj[prop] === undefined) continue;
      var nodeName = undefined;
      if (Array.isArray(obj) && nodeNameForArrayItem) {
        nodeName = nodeNameForArrayItem[this.arrayName];
      } else {
        nodeName = prop;
      }

      xml += "<" + nodeName + ">";
      if (typeof obj[prop] == "object") {
        var newObject = {};
        newObject = obj[prop];
        xml += this.objectToXml(newObject, nodeNameForArrayItem);
      } else {
        xml += obj[prop];
      }
      xml += "</" + nodeName + ">";
    }
    return xml;
  }

  xmlToJson(xml) {
    var obj = {};
    if (xml.nodeType === 3) {
      obj = xml.nodeValue;
    }

    if (xml.hasChildNodes()) {
      for (var i = 0; i < xml.childNodes.length; i++) {
        var item = xml.childNodes.item(i);
        var nodeName = item.nodeName;
        if (typeof obj[nodeName] === "undefined") {
          if (nodeName === "#text") {
            obj = this.xmlToJson(item);
          } else {
            obj[nodeName] = this.xmlToJson(item);
          }
        } else {
          if (typeof obj[nodeName].push === "undefined") {
            var old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(this.xmlToJson(item));
        }
      }
    } else if (xml.nodeType === 1) {
      obj = xml.nodeValue;
    }
    return obj;
  }

  generateServiceKey(){
    const textEncoder = new TextEncoder();
    var key = textEncoder.encode("G&#%pO7UsjFrNup5");
    var text = 'DragonesALMS' + this.capstoneVersion.replace('.', '');
    var textBytes = aesjs.utils.utf8.toBytes(text);
    var aesCtr = new aesjs.ModeOfOperation.ctr(key);
    var encryptedBytes = aesCtr.encrypt(textBytes);
    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    return encryptedHex;
  }
}
