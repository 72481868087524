import React from "react";
import "./LookupStops.css";

import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setMenuInfo } from "../../reducers/menuInfo";

import { SalesInvoiceService } from "../../restServices/SalesInvoiceService.js";
import { LoadService } from "../../restServices/LoadService";
import { SalesInvoiceServiceSOAP } from "../../soapServices/SalesInvoiceService.js";
import { FileAttachmentService } from "../../soapServices/FileAttachmentService.js";

import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react-17';
import { faWarehouse } from '@fortawesome/pro-solid-svg-icons';

class LookupStops extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesOrderNumber: this.props.match.params.orderNumber ?? "",
      salesOrderDisabled: this.props.match.params.invoiceNumber ? true : false,
      salesInvoiceNumber: this.props.match.params.invoiceNumber ?? "",
      salesInvoiceDisabled: this.props.match.params.orderNumber ? true : false,
      showMap: false,
      locations: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      driver: "",
      truck: "",
      loadDate: "",
      invoiceInfo: {},
      showMoreInfoDialog: false,
      wait:false,
    };
  }

  componentDidMount() {    
    this.props.setMenuInfo("Lookup Stops by Sales Order"); 
    this.salesInvoiceServiceSOAP = new SalesInvoiceServiceSOAP(process.env.REACT_APP_CAPSTONE_SERVICE_URL, this.props.capstoneVersion); 
    this.fileAttachmentService = new FileAttachmentService(process.env.REACT_APP_CAPSTONE_SERVICE_URL, this.props.capstoneVersion);
    this.salesInvoiceService = new SalesInvoiceService(process.env.REACT_APP_CAPSTONE_REST_API);
    this.loadService = new LoadService(process.env.REACT_APP_CAPSTONE_REST_API);  
    this.warehouseLocation = {
      lat: Number(this.props.currentWarehouse.Latitude),
      lng: Number(this.props.currentWarehouse.Longitude)
    };
    this.getSalesInvoices(this.state.salesInvoiceNumber);
    this.getSalesInvoicesBySalesOrder();
  }

  render() {
    let stops = {};
    let stopCounter = 1;

    return (
      <div
        className="container-fluid page-container"
        style={{ cursor: this.state.wait ? "wait" : "default" }}
        disabled={this.state.wait}
      >
        
          <div className="row">
            <label className="label-title-forInput col-2">Sales Order #: </label>
            <input
              className={"form-control col-3"}
              name="salesOrderNumber"
              type="number"
              value={this.state.salesOrderNumber}
              disabled={this.state.salesOrderDisabled}
              onChange={this.onChangeInput}
              onKeyDown={this.onKeyDownOrder}/>
            &nbsp;
            <Button 
              onClick={this.getSalesInvoicesBySalesOrder}
              disabled={this.state.salesOrderDisabled}>
                Go
            </Button>
          </div>
          <br/>
          <div className="row">
            <label className="label-title-forInput col-2">Packing List #: </label>
            <input
              className={"form-control col-3"}
              name="salesInvoiceNumber"
              type="number"
              value={this.state.salesInvoiceNumber}
              disabled={this.state.salesInvoiceDisabled}
              onChange={this.onChangeInput}
              onKeyDown={this.onKeyDownInvoice}/>
            &nbsp;
            <Button 
              onClick={e => this.getSalesInvoices(this.state.salesInvoiceNumber)} 
              disabled={this.state.salesInvoiceDisabled}>
                Go
            </Button>
          </div>
          <br/>
          <div id="map-container" className="row map-container" style={{display: this.state.showMap ? 'block' : 'none'}}>          
          <Map
            google={this.props.google}            
            style={{ width: '100%', height: '300px', position: 'relative'}}
            zoom={7}
            onClick={this.onMapClicked}
            ref={(ref) => {
              this.mapRef = ref;
            }}
          >
            <Marker
              position={this.warehouseLocation} 
              name={this.props.currentWarehouse.BriefName + " Warehouse"}
              title={this.props.currentWarehouse.BriefName + " Warehouse"}
              icon={this.pinIcon(faWarehouse, "gray")}
              onClick={this.onMarkerClick}/>

            {this.state.locations.map((position, index) => {
              let location = position.lat + "-" + position.lng + position.deliveryAddressName;
              if(!stops[location]){
                stops[location] = stopCounter++;
              }              
              if(position.lat && position.lng){
                return (<Marker
                  key={index}
                  position={position} 
                  center={position}
                  label={stops[location].toString()}
                  title={position.label + 
                    position.cityAndState + "\n" + position.deliveryAddressName}
                  name={position.label + 
                    position.cityAndState + "\n" + position.deliveryAddressName}
                  onClick={this.onMarkerClick}/>)
              }
              return null;
            })}
            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}>
                <div>
                  <p style={{whiteSpace: "pre-line"}} >{this.state.selectedPlace.name}</p>
                </div>
            </InfoWindow>
          </Map>
        </div>
        <br/>
        <div style={{display: this.state.showMap ? 'block' : 'none'}}>          
          <strong>Planned Date: </strong>{this.state.loadDate}<br/>
          <strong>Packing List #: </strong>{this.state.salesInvoiceNumber}<br/>
          <strong>Address Name: </strong>{this.state.invoiceInfo.deliveryAddressName}<br/>
          <span>{stops[this.state.invoiceInfo.latitude + "-" + 
            this.state.invoiceInfo.longitude + this.state.invoiceInfo.deliveryAddressName]} / 
             {Object.keys(stops).length}</span><br/>
          <strong>Truck: </strong>{this.state.truck}<br/>
          <strong>Driver: </strong>{this.state.driver}<br/>
          <span className="invoice-pdf-link" onClick={this.showPackingListPdf}>View Packing List PDF</span><br/>
        </div>
        <br/>
        <br/>
        <Modal
          show={this.state.showDialog}
          onHide={this.hideMessageDialog}
          centered
        >
          <Modal.Header>
            <Modal.Title>{this.state.dialogMessageTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.state.dialogMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            {this.state.firstButtonDialog &&
            (<Button
              onClick={this.state.firstButtonDialogCallback}
              ref={(ref) => {
                this.firstButtonDialog = ref;
              }}>
              {this.state.firstButtonDialog}
            </Button>)}

            {this.state.secondButtonDialog &&
            (<Button
              onClick={this.state.secondButtonDialogCallback}
              ref={(ref) => {
                this.secondButtonDialog = ref;
              }}>
              {this.state.secondButtonDialog}
            </Button>)}
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showMoreInfoDialog}
          onHide={this.hideMoreInfoDialog}
          centered
          autoFocus={true}
          size="xl"
        >
          <Modal.Body>
            <div className="pdf-viewer-container">
              <object data={this.state.itemMoreInfoFileLocation} className="pdf-viewer">
                <p>Your browser doesn't support PDFs.</p>
                <p><a href={this.state.itemMoreInfoFileLocation} 
                target="_blank" rel="noopener noreferrer">Download Instead</a></p>
              </object>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideMoreInfoDialog}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> 
        <Modal
          className="modal-spinner"
          show={this.state.wait}
          centered
          onHide={(e) => {}}
        >
          <Spinner animation="border" role="status" />
          <span className="modal-spinner-text">
            {MESSAGES_CONSTANTS.MODAL_SPINNER_MESSAGE}
          </span>
        </Modal>
      </div>
    );
  }

  onKeyDownInvoice = (e) => {
    if(e.key === "Enter"){
      this.getSalesInvoices(this.state.salesInvoiceNumber);
    }
  }

  getSalesInvoices = (salesInvoiceNumber) => {
    if(salesInvoiceNumber.length > 0){
      this.setState({wait: true});
      this.salesInvoiceService.GetSalesInvoices(this.props.sessionData.session, salesInvoiceNumber, true)
      .then(getInvoicesResult => {
        this.setState({wait: false});
        console.log(getInvoicesResult);
        if(!getInvoicesResult.error && getInvoicesResult.result.length > 0){
          if(getInvoicesResult.result.filter(item => item.latitude && item.longitude).length > 0){
            this.setItemsInMap(getInvoicesResult.result);
            let invoiceData =  getInvoicesResult.result.find(i => i.salesInvoiceHeader_Id === Number(salesInvoiceNumber));
            if(invoiceData){
              if(invoiceData.loadHeader_Id){
                this.getLoadData(invoiceData.loadHeader_Id);
              }
              this.setState({
                invoiceInfo : invoiceData
              });
            }
            if(salesInvoiceNumber !== this.state.salesInvoiceNumber){
              this.setState({salesInvoiceNumber: salesInvoiceNumber, salesOrderDisabled: false});
            }
          }
          else{
            this.showDialog("No coordinates specified for this packing list.");
          }
        }
        else{
          this.showDialog("Packing list not found.");
        }
      });
    }
  }

  getLoadData = (loadNumber) => {    
    this.setState({ wait: true});
    this.loadService.GetLoad(this.props.sessionData.session, loadNumber)
    .then(loadResult => {
      console.log(loadResult.result);
      this.setState({ wait: false});
      if(loadResult.result){
        this.setState({ 
          driver: loadResult.result.driverName,
          truck: loadResult.result.truckName,
          loadDate: new Date(loadResult.result.delivery_Date).toLocaleDateString() 
        });
      }
    });
  }

  showPackingListPdf = () =>{
    this.setState({wait: true});
    this.salesInvoiceServiceSOAP.PrintPackingList(this.props.sessionData.session, 
      this.state.invoiceInfo.salesInvoiceHeader_Id, false, 
      this.state.invoiceInfo.includePricingOnShippingDocuments_Flag)
      .then(printResult =>{
        this.setState({
          wait: false,
          showMoreInfoDialog: true, 
          itemMoreInfoFileLocation: process.env.REACT_APP_CAPSTONE_SERVICE_URL + "Storage/" + printResult.result,
          fileName: printResult.result
        });
      })
  }

  hideMoreInfoDialog = (e) =>{
    this.setState({showMoreInfoDialog: false, wait : true});
    this.fileAttachmentService.DeleteTemporaryFile(this.props.sessionData.session, this.state.fileName)
    .then(deleteResult =>{
      console.log("file deleted: " + deleteResult.result);
      this.setState({wait : false});
    });
  }

  onKeyDownOrder = (e) => {
    if(e.key === "Enter"){
      this.getSalesInvoicesBySalesOrder(e);
    }
  }

  getSalesInvoicesBySalesOrder = (e) => {
    if(this.state.salesOrderNumber.length > 0){
      this.setState({wait: true});
      this.salesInvoiceService.GetSalesInvoicesBySalesOrder(this.props.sessionData.session, this.state.salesOrderNumber)
      .then(invoicesResult => {
        this.setState({wait: false});
        console.log(invoicesResult);
        if(!invoicesResult.error && invoicesResult.result.length > 0){
          let invoices = invoicesResult.result;
          if(invoices.length > 1){
            let invoicesHtml = invoices.map((item, index) => {
              return (<li key={index} className="invoice-list-item"
                onClick={e => this.getSalesInvoices(item.salesInvoiceHeader_Id.toString())}>
                  {item.salesInvoiceHeader_Id}
                </li>);
            });
            this.showDialog(invoicesHtml, "Select a packing list for the order", "Cancel");
          }
          else{
            this.getSalesInvoices(invoices[0].salesInvoiceHeader_Id.toString());
          }   
        }
        else{
          this.showDialog("No packing lists associated to this order.");
        }        
      });
    }
  }

  setItemsInMap = (invoices) => {
    var mapBounds = new this.props.google.maps.LatLngBounds();
    let locations = [];
    invoices.forEach(invoice => {           
      let position = {
        id: invoice.salesInvoiceHeader_Id,
        lat: invoice.latitude, 
        lng: invoice.longitude,
        label : "PL#" + invoice.salesInvoiceHeader_Id,
        cityAndState:  (invoice.deliveryCity ? " - " + invoice.deliveryCity : "") +
          (invoice.deliveryState ? ", " + invoice.deliveryState : ""),
        deliveryAddressName: invoice.deliveryAddressName ?? '',
      }
      locations.push(position);
      if(position.lat && position.lng){         
        mapBounds.extend(position);        
      }
    });

    mapBounds.extend(this.warehouseLocation);
    this.mapFitBounds(mapBounds);
    console.log(locations)
    this.displayRouteDirections(locations);
    this.setState({
      locations: locations,       
      showMap: true,
      showDialog: false
    });
    setTimeout(e => {
      if(this.mapRef && this.state.showMap){
        console.log(this.state.invoiceInfo)
        this.mapRef.map.setCenter({lat: this.state.invoiceInfo.latitude, lng: this.state.invoiceInfo.longitude});
        this.mapRef.map.setZoom(12);       
      } 
    }, 500);
  }

  displayRouteDirections = (locations) => {
    console.log("displayRouteDirections");
    if(locations.length <= 1) {
      return;
    }
    this.directionsDisplay = new this.props.google.maps.DirectionsRenderer({suppressMarkers: true});
    this.directionsService = new this.props.google.maps.DirectionsService();

    this.directionsDisplay.setMap(this.mapRef.map);
    let waypoints = [];
    locations.forEach(item => {
      if(item.lat && item.lng){
        waypoints.push({
          location: { lat: item.lat, lng: item.lng },
          stopover: true,
        });
      } 
    });
    let request = {
      origin: this.warehouseLocation,
      waypoints: waypoints,
      destination: this.warehouseLocation,
      travelMode: "DRIVING",
    };      
    this.directionsService.route(request).then(result => {       
      if (result.status === "OK") {
        this.directionsDisplay.setDirections(result);
        this.directionsDisplay.setOptions({
          suppressMarkers: true
        });
        console.log(result);
      }
    });
  }

  mapFitBounds = (mapBounds) => {
    setTimeout(e => {
      if(this.mapRef){
        this.mapRef.map.fitBounds(mapBounds);
        //this.mapRef.map.setZoom(17);
      }
    }, 200);
  }

  onChangeInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if(name === "salesOrderNumber"){
      this.setState({
        salesOrderNumber: value, 
        salesInvoiceDisabled: value === "" ? false : true,
        salesInvoiceNumber: "", 
        showMap: false
      });
    }
    else if(name === "salesInvoiceNumber"){
      this.setState({
        salesInvoiceNumber: value, 
        salesOrderDisabled: value === "" ? false : true,
        salesOrderNumber: "", 
        showMap: false});
    }
  };

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  }

  onMapClicked = (props) => {    
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,        
      })
    }
  };

  pinIcon = (faIcon, color) => {
    return {
      path: faIcon.icon[4],
      fillColor: color,
      fillOpacity: 1,
      anchor: new this.props.google.maps.Point(
        faIcon.icon[0] / 2, // width
        faIcon.icon[1] // height
      ),
      strokeWeight: 0.2,
      strokeColor: "black",
      scale: 0.05,
    };
  }

  showDialog = (message, messageTitle, firstButtonDialog, firstButtonDialogCallback,
    secondButtonDialog, secondButtonDialogCallback) =>{
    this.setState({
      wait: false, 
      showDialog: true, 
      dialogMessage: message,
      dialogMessageTitle: messageTitle ? messageTitle : MESSAGES_CONSTANTS.MODAL_ERROR_TITLE,
      firstButtonDialog: firstButtonDialog ? firstButtonDialog : MESSAGES_CONSTANTS.MODAL_OK_OPTION,
      firstButtonDialogCallback: firstButtonDialogCallback ? firstButtonDialogCallback : this.hideMessageDialog,
      secondButtonDialog: secondButtonDialog,
      secondButtonDialogCallback: secondButtonDialogCallback,
    });
    setTimeout(e => {
      this.firstButtonDialog.focus();
    }, 200);
  }

  hideMessageDialog = (e) =>{
    this.setState({showDialog : false});
  }

}

function mapStateToProps(state) {
  return {
    isLogged: state.auth.isLogged,
    currentPageName: state.menuInfo.currentPageName,
    currentWarehouse: state.menuInfo.currentWarehouse,
    sessionData: state.auth.sessionData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setMenuInfo,
    },
    dispatch
  );
}

export default GoogleApiWrapper(
  (props) => ({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  }
))(connect(mapStateToProps, mapDispatchToProps)(LookupStops));
