import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faWarehouse, faSquare, faTruck } from '@fortawesome/pro-solid-svg-icons';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react-17';
import { setRoutes, setDirectionsDisplay, updateItems } from "../../reducers/routesReducer";
import { LoadService } from "../../restServices/LoadService";
import useGPSInsight from "./useGPSInsight";
import useERoad from "./useERoad";

export function useRoutingMap(props) {

  const dispatch = useDispatch();
  const session = useSelector(state => state.auth.sessionData.session);
  const currentWarehouse = useSelector(state => state.menuInfo.currentWarehouse);
  const mapLocations = useSelector(state => state.routesReducer.mapLocations);
  const routesData = useSelector(state => state.routesReducer.routesData);
  const directionsDisplay = useSelector(state => state.routesReducer.directionsDisplay);  
  const trafficLayer = useRef(null);
  const mapRef = useRef(null);
  const loadService = useRef(null);
  const gpsInsight = useGPSInsight();
  const eRoad = useERoad();

  useEffect(() => {
    loadService.current = new LoadService(process.env.REACT_APP_CAPSTONE_REST_API);
    if(props.google){
      dispatch(setDirectionsDisplay(new props.google.maps.DirectionsRenderer({suppressMarkers: true})));
      trafficLayer.current = new props.google.maps.TrafficLayer();       
    }    
  }, []);

  useEffect(() => {
    console.log("mapLocations changed in reducer")
    let filterResult = filterMapLocations(mapData.currentRouteForFilter, mapLocations);
    setMapData(prevMapData => ({
      ...prevMapData,
      filteredMapLocations: filterResult
    }));    
  }, [mapLocations]);

  const initialMapData = {
    selectedItemId: "",
    dropdownMapFilter: "View All",
    currentRouteForFilter: "",
    filteredMapLocations: [],
    itemsWithoutCoordinates: [],
    showMap: true,
    warehouseLocation: {
      lat: Number(currentWarehouse.Latitude),
      lng: Number(currentWarehouse.Longitude)
    },
    trucksLocations: []
  }
  const [mapData, setMapData] = useState(initialMapData);

  const selectItemInMap = (id) =>{
    let locations = mapLocations.slice();
    let positionFound = undefined;
    locations.forEach(item => {
      if(item.id === id){
        positionFound = item;
        item.selected = true;
        item.size = 1.5;
      }
      else{
        item.size = 1;
        item.selected = false;
      }
    });
    if(positionFound){   
      setMapData(prevMapData => ({
        ...prevMapData, 
        selectedItemId: id
      }));
      dispatch(setRoutes(routesData, locations));
      setTimeout(e => {
        if(mapRef && mapRef.current && mapData.showMap){
          mapRef.current.map.setCenter(positionFound);
          mapRef.current.map.setZoom(12);          
          window.scrollTo(0, 0);          
        } 
      }, 200);
    }
  }

  const setMapTraffic = (date) => {
    if(mapRef?.current?.map && trafficLayer.current){
      const today = new Date().toDateString();      
      if(date?.toDateString() === today){        
        trafficLayer.current.setMap(mapRef.current.map);
      }
      else{
        trafficLayer.current.setMap(null);
      }
    }
  }

  const onRouteFilterChange = (value) => {
    let filterValue = value !== undefined ? value : mapData.currentRouteForFilter;
    let filterResult = filterMapLocations(filterValue, mapLocations);
    setMapData(prevMapData => ({
      ...prevMapData,
      currentRouteForFilter: filterValue,
      filteredMapLocations: [...filterResult]
    }));
  }

  const filterMapLocations = (filter, locations) => {
    console.log("filterMapLocations");
    setMapData(prevMapData => ({
      ...prevMapData,
      trucksLocations: []
    }));
    let filteredLocations = [];
    var mapBounds = props.google ? new props.google.maps.LatLngBounds() : undefined;
    
    if(directionsDisplay){
      directionsDisplay.setMap(null);
    }
    if(mapData.warehouseLocation){
      mapBounds?.extend(mapData.warehouseLocation);
    } 

    if(!locations){
      mapFitBounds(mapBounds, filteredLocations.length);
      return filteredLocations;
    }

    if(!filter || filter === ""){
      filteredLocations = locations;
      filteredLocations.forEach(position => position.lat && position.lng ? mapBounds?.extend(position) : null);
      mapFitBounds(mapBounds, filteredLocations.length);
    }
    else if(filter.includes("-")){
      let filters = filter.split("-");
      filteredLocations = locations.filter(position => position.routeId === filters[0] && position.runNumber === Number(filters[1]));
      filteredLocations = filteredLocations.sort((a,b) => a.sequenceNumber > b.sequenceNumber ? 1 : 
      (b.sequenceNumber > a.sequenceNumber ? -1 : 0));    
      let notMappedLocations = filteredLocations.filter(position => !position.lat || !position.lng);            
      if(notMappedLocations.length === 0){
        displayRouteDirections(filteredLocations);
      }
      else{
        filteredLocations.forEach(position => {
          if(position.lat && position.lng){
            mapBounds.extend(position);
          }
        });
        mapFitBounds(mapBounds, filteredLocations.length);
      }
      getTruckLocationForFilter(filters[0], Number(filters[1]));    
    }
    else {
      filteredLocations = locations.filter(position => position.routeId === filter);
      filteredLocations.forEach(position => position.lat && position.lng ? mapBounds.extend(position) : null);
      mapFitBounds(mapBounds, filteredLocations.length);
    }    
    return filteredLocations;
  }

  const getTruckLocationForFilter = async (routeId, runNumber) => {    
    const loadNumber = routesData.find(route => route.routeId === routeId)?.runs[runNumber]?.items[0]?.loadNumber;
    const today = new Date().toDateString();
    if(loadNumber && props.planningDate.toDateString() === today){
      props.setWait(true);
      return loadService.current.GetLoad(session, loadNumber).then(loadResponse => {        
        if(!loadResponse.error && !loadResponse.result){ 
          props.setWait(false);
          props.showModal("This load no longer exists.", "Warning", "OK", 
            (() => {
              props.hideModal();
              props.refreshRoutesData();
            }));
        }
        else if(!loadResponse.error && loadResponse.result){
          getGPSInsightTrucksLocations([{
            truckName:loadResponse.result.truckName, 
            vinNumber: loadResponse.result.truckVin 
          }]);
        }
      });
    }
  }

  const getTrucksLocationsForToday = (planningDateParam, routesParam) => {
    console.log("getTrucksLocationsForToday")
    const trucksData = [];
    const today = new Date().toDateString();
    if(planningDateParam.toDateString() === today){ 
      routesParam.forEach(route => {
        route.runs.forEach(run => {
          if(run.isLoad && run.items[0]?.loadNumber){
            trucksData.push({ 
              truckName: run.items[0]?.truckName,
              vinNumber: run.items[0]?.truckVin
            });
          }        
        });
      });
      console.log(trucksData)
      // if(eRoadApiKey){
      //   getERoadTrucksLocations(trucksData);
      // }
      // else{
        getGPSInsightTrucksLocations(trucksData);
      //}      
    }
  }

  const getGPSInsightTrucksLocations = (trucksData) => {
    props.setWait(true);
    gpsInsight.getTrucksLocations(trucksData.map(truck => truck.vinNumber)).then(locationResponses => {
      props.setWait(false);
      if(!locationResponses.error && locationResponses.result){
        console.log(locationResponses)
        const trucksLocations = [];
        locationResponses.result.forEach(locationResponse => {
          trucksLocations.push({
            name: trucksData.find(truck => truck.vinNumber === locationResponse.vin)?.truckName,
            location: { lat: locationResponse.latitude, lng: locationResponse.longitude },
            averageSpeed: locationResponse.avg_speed
          })
        });
        setMapData(prevMapData => ({
          ...prevMapData,
          trucksLocations: trucksLocations
        }));
      }
    });
  }

  const getERoadTrucksLocations = (trucksData) => {
    props.setWait(true);
    eRoad.getTrucksLocations(trucksData.map(truck => truck.vinNumber)).then(locationResponses => {
      props.setWait(false);
      if(!locationResponses.error){
        console.log(locationResponses)
        const trucksLocations = [];
        locationResponses.result.forEach(locationResponse => {
          trucksLocations.push({
            name: trucksData.find(truck => truck.vinNumber === locationResponse.vin)?.truckName,
            location: { lat: locationResponse.latitude, lng: locationResponse.longitude },
            averageSpeed: locationResponse.avg_speed
          })
        });
        setMapData(prevMapData => ({
          ...prevMapData,
          trucksLocations: trucksLocations
        }));
      }
    });
  }

  const displayRouteDirections = (filteredLocations) => { 
    directionsDisplay.setMap(mapRef.current.map);
    let waypoints = [];
    filteredLocations.forEach(item => {
      if(item.lat && item.lng){
        waypoints.push({
          location: { lat: item.lat, lng: item.lng },
          stopover: true,
        });
      } 
    });
    let request = {
      origin: mapData.warehouseLocation,
      waypoints: waypoints,
      destination: mapData.warehouseLocation,
      travelMode: "DRIVING",
    };      

    var directionsService = new props.google.maps.DirectionsService();
    directionsService.route(request).then(result => {       
      if (result.status === "OK") {
        directionsDisplay.setDirections(result);
        directionsDisplay.setOptions({
          suppressMarkers: true
        });
      }
    });
  }

  const mapFitBounds = (mapBounds, numberOfLocations) => {
    if(mapRef && mapRef.current){
      mapRef.current.map?.fitBounds(mapBounds);          
      if(numberOfLocations <= 0){
        mapRef.current.map?.setZoom(18);
      } 
    }
  }

  const resetMapData = (mapLocationsParam, planningDateParam) => {
    let allLocations = filterMapLocations("", mapLocationsParam);
    setMapData(prevMapData => ({
      ...prevMapData,
      currentRouteForFilter: "",
      dropdownMapFilter: "View All",
      filteredMapLocations: [...allLocations],
      itemsWithoutCoordinates : mapLocationsParam ? mapLocationsParam.filter(position => !position.lat || !position.lng) : [],
      trucksLocations: []
    }));
    setMapTraffic(planningDateParam);
  }

  const setMapRef = (mapRefParam) => {
    mapRef.current = mapRefParam;
  }

  const toggleMap = (value) =>{
    setMapData(prevMapData => ({
      ...prevMapData,
      showMap: value
    }));
  }

  const setDropdownMapFilter = (value) =>{
    setMapData(prevMapData => ({
      ...prevMapData,
      dropdownMapFilter: value
    }));
  }

  const setSelectedItemId = (value) =>{
    setMapData(prevMapData => ({
      ...prevMapData,
      selectedItemId: value
    }));
  }

  return { setMapRef, mapData, resetMapData, selectItemInMap, onRouteFilterChange, mapFitBounds, 
    toggleMap, setDropdownMapFilter, setSelectedItemId, getTrucksLocationsForToday };

}

//Google map component for routing
export default function RoutingMap(props)  {

  const session = useSelector(state => state.auth.sessionData.session);
  const currentWarehouse = useSelector(state => state.menuInfo.currentWarehouse);
  const routesData = useSelector(state => state.routesReducer.routesData);
  const mapLocations = useSelector(state => state.routesReducer.mapLocations);
  const mapFilterOptions = useSelector(state => state.routesReducer.mapFilterOptions);
  const itemsOutOfRouteDate = useSelector(state => state.routesReducer.itemsOutOfRouteDate);
  const allRoutes = useSelector(state => state.routesReducer.allRoutes);
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});

  useEffect(() => {
    props.mapState.setMapRef(mapRef.current);
  }, [mapRef]);

  useEffect(() => {
    console.log(props.mapState.mapData)
  }, [props.mapState.mapData]);

  const showItemsWithoutCoordinates = (e) => {
    let itemsHtml = props.mapState.mapData.itemsWithoutCoordinates.map((item, index) => {
      return (<li key={index}>{item.label}</li>);
    });
    props.showModal(itemsHtml, "Not Mappped Stops");
  }

  const showItemsOutOfRouteDate = (e) => {
    let itemsHtml = (<table>
      <thead>
        <tr>
          <th>PL#/SO#</th>
          <th style={{textAlign: "center"}}>Current Route</th>
          <th>Target Delivery Date</th>
          <th>Change Route</th>
        </tr>
      </thead>
      <tbody>
        {itemsOutOfRouteDate.map((item, index) =>{ 
          return <tr key={index}>
                  <td>{item.label}</td>
                  <td style={{textAlign: "center"}}>{allRoutes.find(r => r.RouteId === item.routeId.toString())?.Name}</td>
                  <td>{item.targetDeliveryDate?.toLocaleDateString() ?? item.shipDate?.toLocaleDateString()}</td>
                  <td><select defaultValue={item.routeId} onChange={(e) => onChangeItemRoute(e, item)}>
                    {allRoutes.map(route => {
                      return <option key={route.RouteId} value={route.RouteId}>{route.Name}</option>
                    })}
                    </select>
                  </td>
                </tr>})
        }
      </tbody>        
    </table>);
    props.showModal(itemsHtml, "Items on Other Routes", "Close", () => {    
      props.hideModal();
      props.loadRoutesAndOrders();
    });
  }

  const onChangeItemRoute = (e, item) => {
    Promise.all(updateItems(session, [item], e.target.value, null)).then(onDone => {
      console.log(onDone)
    });
  }

  const toggleMap = () => {
    props.mapState.toggleMap(!props.mapState.mapData.showMap);  
    setTimeout(() => {
      if(!props.mapState.mapData.showMap){
        var mapBounds = new props.google.maps.LatLngBounds();
        if(props.mapState.mapData.warehouseLocation) mapBounds.extend(props.mapState.mapData.warehouseLocation);
        mapLocations.forEach(position => position.lat && position.lng ? mapBounds.extend(position) : null);
        props.mapState.mapFitBounds(mapBounds, mapLocations.length);
      }
    }, 100);    
  }

  const pinSymbol = (position) => {
    return {
      path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
      fillColor: position.color,
      fillOpacity: 0.85,
      strokeColor: 'black',
      strokeWeight: 0.2,
      strokeOpacity: 1,
      scale: position.size ? position.size : 1,
      labelOrigin: props.google ? new props.google.maps.Point(0, -30) : undefined,
   };
  }

  const customPin = (icon, color) => {
    return {
      path: icon.icon[4],
      fillColor: color,
      fillOpacity: 1,
      anchor: props.google ? new props.google.maps.Point(
        icon.icon[0] / 2, // width
        icon.icon[1] // height
      ) : undefined,
      strokeWeight: 0.2,
      strokeColor: "black",
      scale: 0.05,
    };
  }

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  }

  const onMapClicked = () => {    
    let locations = mapLocations.slice();
    let selectedItem = locations.find(i => i.id === props.mapState.mapData.selectedItemId);
    if(selectedItem){
      selectedItem.size = 1;
      props.mapState.setSelectedItemId("");
      dispatch(setRoutes(routesData, locations));
    } 

    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  };

  //Variables to calculate the stops numbers every render in case run/load filter is selected
  let previousLocation = "";
  let stopCounter = 0;
  return (
    <>
      <div className="row">
        <button onClick={toggleMap}
          className="btn col-12"> 
          <FontAwesomeIcon icon={faMapMarkedAlt} /> {props.mapState.mapData.showMap ? "Hide Map" : "Show Map"}
        </button>
      </div>
      <div className="row" style={{display: props.mapState.mapData.showMap ? 'flex' : 'none'}}>
        <label className="label-title-forInput col-md-3 col-sm-12">Filter by Route/Run/Load: </label>
        <DropdownButton className={"col-sm-12 col-md-" + (itemsOutOfRouteDate?.length > 0 ? "3 " : "6 ") }
          variant='Secondary' title={props.mapState.mapData.dropdownMapFilter} 
          onSelect={e => props.mapState.setDropdownMapFilter(e)}>
          <Dropdown.Item eventKey='View All' onClick={() => props.mapState.onRouteFilterChange("")}>View All</Dropdown.Item>
          {mapFilterOptions.map((option, index) => {
              return (
                <Dropdown.Item key={index} eventKey={option.label} onClick={() => props.mapState.onRouteFilterChange(option.id)}>
                  <span style={{color:option.color}}><FontAwesomeIcon icon={faSquare} /></span>{option.label}
                </Dropdown.Item>
              );                
            })}                    
        </DropdownButton>
        <div className="col-md-3 col-sm-6">
          <button onClick={showItemsWithoutCoordinates} style={{width:"200px"}}
            className="btn btn-primary" disabled={props.mapState.mapData.itemsWithoutCoordinates.length === 0}>
            Not Mapped Stops {props.mapState.mapData.itemsWithoutCoordinates.length > 0 ?
             "(" + props.mapState.mapData.itemsWithoutCoordinates.length + ")" : ""}
          </button>
        </div>
        {itemsOutOfRouteDate?.length > 0 && 
        (<div className="col-md-3 col-sm-6">
          <button onClick={showItemsOutOfRouteDate} style={{width:"250px"}}
            className="btn btn-primary">
            Items on Other Routes ({itemsOutOfRouteDate?.length})
          </button>
        </div>)}
      </div>
      {props.mapState.mapData.trucksLocations[0]?.lat}
      <div id="map-container" className="row map-container" style={{display: props.mapState.mapData.showMap ? 'block' : 'none'}}> 
        <Map
          google={props.google}            
          style={{ width: '100%', height: '300px', position: 'relative'}}
          zoom={8}
          onClick={onMapClicked}
          ref={mapRef}
        >
          <Marker
            position={props.mapState.mapData.warehouseLocation} 
            name={currentWarehouse.BriefName + " Warehouse"}
            title={currentWarehouse.BriefName + " Warehouse"}
            icon={customPin(faWarehouse, props.mapState.mapData.filteredMapLocations.length === 0 ? "#B80000": "gray")}
            onClick={onMarkerClick}/>

          {props.mapState.mapData.trucksLocations.length > 0 && props.mapState.mapData.trucksLocations.map((truck, index) => {
            return <Marker key={index}
              position={truck.location} 
              name={truck.name}
              title={truck.name}
              icon={customPin(faTruck, truck.averageSpeed > 20 ? "green" : truck.averageSpeed > 0 ? "yellow" : "red")}
              onClick={onMarkerClick}/>})}

          {props.mapState.mapData.filteredMapLocations.map((position, index) => {
            let currentLocation = position.lat + "-" + position.lng + position.deliveryAddressName;
            if(previousLocation !== currentLocation){
              stopCounter++;
              previousLocation = currentLocation;
            }
            if(position.lat && position.lng){         
              return <Marker key={index} id={index} 
                position={position} 
                name={position.label + (position.deliveryAddressName ? "\n" + position.deliveryAddressName : "")}
                title={position.label + (position.deliveryAddressName ? "\n" + position.deliveryAddressName : "")}
                icon={pinSymbol(position)}
                label= {props.mapState.mapData.currentRouteForFilter.includes("-") ? stopCounter.toString() : ""}	
                onClick={onMarkerClick}
                animation={props.mapState.mapData.selectedItemId === position.id ? props.google.maps.Animation.BOUNCE : null} />
            }
            return null;     
          })}
          <InfoWindow
            marker={activeMarker}
            visible={showingInfoWindow}>
              <div>
                <p style={{whiteSpace: "pre-line"}} >{selectedPlace.name}</p>
              </div>
          </InfoWindow>
        </Map>
      </div>
    </>
  );
}