import useFetch from "./useFetch";

export default function useERoad() {

  const [callERoad] = useFetch("https://api.na.eroad.com/v1/");
  
  const getTrucksLocations = (plateNumbers) => {
    let promises = [];
    console.log(plateNumbers)
    plateNumbers.forEach(plate => {
      promises.push(getVehicleCurrentState(plate));
    });
    return Promise.all(promises).then(locationResponses => {
        console.log(locationResponses);
        let resultResponse = { result: [], error: undefined };
        locationResponses.forEach(locationResponse => {
          if(locationResponse.error){
            resultResponse.error =  locationResponse.error;
          }
          else{
            resultResponse.result.push(locationResponse.result?.results[0]?.gpsFix);
          }
        });
        return resultResponse;
      });
  }

  const getVehicleCurrentState = (vehiclePlate) => {
      return callERoad("vehicleCurrentState", (`?searchString=${"17T22148"}&distanceUnit=MI`), "GET", "eRoadApiKey");
  }

  return { getTrucksLocations };
}