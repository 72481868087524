import React from "react";
import TreeNode from "./TreeNode";
import "./Menu.css";
import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faLock, faBars } from '@fortawesome/pro-solid-svg-icons';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logOut } from "../../reducers/auth";
import { setMenuInfo, setPreviousPage } from "../../reducers/menuInfo";

import { history } from "../../Store";

import { UserSessionService } from "../../soapServices/UserSessionService.js";

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      search: "",
      searching: false,
      wait: false,
      standardMenu: this.props.menuItems,
      showErrorDialog: false,
    };    
  }

  componentDidMount() {
    this.userSessionService = new UserSessionService(process.env.REACT_APP_CAPSTONE_SERVICE_URL, this.props.capstoneVersion);
  }

  logout = (e) => {
    if (this.props.sessionData) {
      this.sessionLogout(this.props.sessionData.session);
    }
  };

  sessionLogout = (session) => {
    console.log("logging out")
    this.setState({ wait: true });
    this.userSessionService.Logout(session)
      .then((userResultSet) => {
        this.setState({ wait: false, showMenu: false });
        console.log("sessionLogout");
        console.log(userResultSet);
        this.props.logOut();
        console.log("logout finished");
        history.push("/login");      
    });
  };

  render() {    
    return (
      this.props.isLogged && (
        <div>
          <div
            className={
              "sidenav " + (this.state.showMenu ? "show-menu" : "hide-menu")
            }
          >
            <span className={"arrow-menu"} onClick={this.toogleMenu}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </span>
            <div style={{ display: this.state.showMenu ? "block" : "none" }} className="full-size">
              <div id="standardMenuTreeView" className="Menu-treeView">
                {this.state.standardMenu.map((node, index) => {
                  return (
                    <TreeNode
                      classPrefix={"Menu"}
                      key={index}
                      defaultExpanded={false}
                      nodeLabel={node.text}
                      children={node.items}
                      onItemClick={this.onItemClick}
                      disabled={this.state.wait}
                    />
                  );
                })}
              </div>
              <button
                className="btn btn-primary Logout-button"
                onClick={this.logout}
              >
                Log out&ensp; <FontAwesomeIcon icon={faLock} />
              </button>
            </div>
          </div>
          <div
            className={
              "Menu top-menu row " +
              (this.state.showMenu ? "hide-screen" : "show-screen")
            }
          >
            {this.props.showBackOption ? (
              <span
                className={"btn-menu col-2 col-menu "}
                onClick={this.backActionHandler}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>
            ) : (
              <span
                className={"btn-menu col-2 col-menu "}
                onClick={this.toogleMenu}
              >
                <FontAwesomeIcon icon={faBars} />
              </span>
            )}
            <div className="current-screen col-7 col-menu ">
              <label className="current-screen-text">
                {this.props.currentPageName}
              </label>
            </div>
          </div>
          <Modal
            className="modal-spinner"
            show={this.state.wait}
            centered
            onHide={(e) => {}}
          >
            <Spinner animation="border" role="status" />
            <span className="modal-spinner-text">
              {MESSAGES_CONSTANTS.MODAL_SPINNER_MESSAGE}
            </span>
          </Modal>
          <Modal
            show={this.state.showErrorDialog}
            onHide={this.hideErrorDialog}
            centered
            autoFocus={true}
          >
            <Modal.Header>
              <Modal.Title>{MESSAGES_CONSTANTS.MODAL_INFO_TITLE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{this.state.errorMessage}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={this.hideErrorDialog}
                ref={(ref) => {
                  this.modalOkButton = ref;
                }}
              >
                {MESSAGES_CONSTANTS.MODAL_OK_OPTION}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )
    );
  }

  hideErrorDialog = (event) => {
    this.setState({ showErrorDialog: false });
    this.toogleMenu();
  };

  showErrorDialog = (errorMessage) => {
    this.setState({ showErrorDialog: true, errorMessage: errorMessage });
    setTimeout((e) => {
      this.modalOkButton.focus();
    }, 100);
  };

  handleChange = (event) => {
    let targetName = event.target.name;
    this.setState({
      [targetName]: event.target.value,
    });
  };

  toogleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
    if (!this.state.showMenu && this.state.standardMenu.length === 0) {
      this.setState({ standardMenu: this.props.menuItems });
    }
  };

  backActionHandler = () => {
    history.goBack();
  };

  onItemClick = (item) => {
    
    if (item.path) {
      this.props.setPreviousPage(item.path);
      history.push(item.path);      
    } 
    // else {
    //   this.setState({ showErrorDialog: true });
    //   this.props.setMenuInfo(" ");
    //   history.push("/blank");
    // }
    this.toogleMenu();
  };
}

function mapStateToProps(state) {
  return {
    isLogged: state.auth.isLogged,
    sessionData: state.auth.sessionData,
    currentPageName: state.menuInfo.currentPageName,
    showBackOption: state.menuInfo.showBackOption,
    menuItems: state.menuInfo.menuItems,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logOut,
      setMenuInfo,
      setPreviousPage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
