import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from "react-redux";
import { setMenuInfo, setFiltersReducer } from "../../reducers/menuInfo";
import { loadPickUpData, printInvoice, markInvoicesAsShipped, printLoadingTicket,
  exportTagsForSalesOrder, deleteTempFile, initServices, emailShipmentDocumentsPackage, sendEmailForShipment,
  checkBalanceDueForOrder } from "../../reducers/pickupReducer";
import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";

import {Button, Container, InputGroup, Form} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  
import { faSearch, faSync } from '@fortawesome/pro-solid-svg-icons';
import useModal from '../useModal/useModal';
import CustomModal from '../useModal/CustomModal';
import usePrintPreview from '../printPreviewModal/usePrintPreview';
import PrintPreviewModal from '../printPreviewModal/PrintPreviewModal';
import PickupItem from './PickupItem';
import UpdatePackingListDialog from '../updatePackingList/UpdatePackingListDialog';
import WaitModal from '../useModal/WaitModal';

function PickupScreen(props) {

    const filtersSaved = useSelector(state => state.menuInfo.filters);
    const session = useSelector(state => state.auth.sessionData.session);
    const loginUser = useSelector(state => state.auth.sessionData.loginUser);    
    const currentWarehouse = useSelector(state => state.menuInfo.currentWarehouse);
    const commonCarrierContainers = useSelector(state => state.pickupReducer.commonCarrierContainers);
    const willCallData = useSelector(state => state.pickupReducer.willCallData);
    const commonCarrierData = useSelector(state => state.pickupReducer.commonCarrierData);

    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(setMenuInfo("Pickup Screen (Will Call Mode)"));
      initServices(process.env.REACT_APP_CAPSTONE_REST_API, process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);
    }, [dispatch]); 
    
    const [modalData, showModal, hideModal] = useModal();
    const [printPreviewData, showPrintPreview, hidePrintPreview] = usePrintPreview(props.capstoneVersion);
    const [wait, setWait] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const filtersDefault = {
      includeMarkedShipped: true,
      showSalesOrders: true,
    }
    const [filters, setFilters] = useState();    
    const [showUpdatePackingList, setShowUpdatePackingList] = useState(false);
    const [currentItem, setCurrentItem] = useState();
        
    useEffect(() => {
      loadData();
    }, [filters]);

    useEffect(() =>{
      if(filtersSaved?.pickup){
        setFilters(filtersSaved?.pickup);
      }
      else{
        setFilters(filtersDefault);
      }
    }, [filtersSaved])

    const loadData = () => {
      if(!currentWarehouse || !session || !filters){
        return;
      }

      setWait(true);
      console.log(filters)
      dispatch(loadPickUpData(currentWarehouse.WarehouseId, session, !filters.includeMarkedShipped, filters.showSalesOrders, searchValue))
        .then(response =>{
          setWait(false);
          if(response.error){
            showModal(response.error);
            return;
          }
          else if(response.info){
            showModal(response.info, MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
            return;
          }
        }); 
    }

    const submitSearch = (e) => {
      e.preventDefault(); 
      loadData();
    }

    const showMoreInfo = (item, isLoadingTicket=false) => {
      setWait(true);
      showPrintPreview(session, item, isLoadingTicket).then(result => {
        setWait(false);
      });
    }

    const onHidePrintPreview = () => {
      setWait(true);
      hidePrintPreview(session, printPreviewData.fileName).then(result => {
        setWait(false);
      });
    }

    const checkBalanceDue = async (item) => {      
      return item.processThroughAccountsReceivable === false ?
        dispatch(checkBalanceDueForOrder(session, item.secondaryId)) : Promise.resolve({result: 0});
    }

    const USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    const printInvoiceClick = (item) => {
      setWait(true);      
      checkBalanceDue(item).then(balanceDueResponse =>{
        setWait(false);
        console.log(balanceDueResponse);
        if(balanceDueResponse.result !== 0){          
          showModal("This shipment has a balance of "+ USDollar.format(balanceDueResponse.result) +" due. How would you like to proceed?", MESSAGES_CONSTANTS.MODAL_INFO_TITLE,
          "Continue Printing", (() => {return printSingleInvoice(item)}), "Cancel", hideModal);
        }
        else{
          return printSingleInvoice(item);
        }
      })
    }

    const printOrderClick = (item) => {
      return printSingleLoadingTicket(item);
    }

    const getSelectedInvoices = () =>{
      let selectedInvoices = [];
      selectedInvoices = willCallData.filter(item => item.selected && item.label.startsWith("PL#"));
      selectedInvoices = selectedInvoices.concat(commonCarrierData.filter(item => item.selected && item.label.startsWith("PL#")));
      return selectedInvoices;
    }

    const printSingleInvoice = async (item, index = 0, totalCount = 0) => {
      hideModal();
      setWait(true);
      await dispatch(printInvoice(session, item, false, Number(process.env.REACT_APP_WAIT_BETWEEN_PRINT_LOADDOCS_SECONDS))).then(response => {
        if(index === totalCount){        
          setWait(false);
          let itemsToMark = [];
          if(totalCount > 0){
            itemsToMark = getSelectedInvoices();
          }
          else{
            itemsToMark = [item]
          }
          showModal("Document(s) Printed.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE, "OK", (() => confirmMarkAsShipped(itemsToMark)));
        }
      });
    }

    const printSingleLoadingTicket = async (item, index = 0, totalCount = 0) => {
      hideModal();
      setWait(true);
      await dispatch(printLoadingTicket(session, item, false, Number(process.env.REACT_APP_WAIT_BETWEEN_PRINT_LOADDOCS_SECONDS))).then(response => {
        if(index === totalCount){        
          setWait(false);
          let invoicesSelected = getSelectedInvoices();
          console.log(invoicesSelected)
          if(invoicesSelected.length > 0){            
            showModal("Document(s) Printed.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE, "OK", (() => confirmMarkAsShipped(invoicesSelected)));
          }
          else{
            showModal("Document(s) Printed.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
          }
        }
      });
    }

    const printSelectedInvoices = async (startAtIndex = 0) => {  
      console.log("startAtIndex: " + startAtIndex)    
      let itemsToPrint = willCallData.filter(item => item.selected);
      itemsToPrint = itemsToPrint.concat(commonCarrierData.filter(item => item.selected));
      console.log(itemsToPrint);
      if(itemsToPrint.length > 0){
        setWait(true);
        try {                  
          for (let index = startAtIndex; index < itemsToPrint.length; index++) {
            let item = itemsToPrint[index];
            console.log(item)
            if(item.label.startsWith("PL#")){
              await checkBalanceDue(item).then(balanceDueResponse =>{
                console.log(balanceDueResponse);
                if(balanceDueResponse.result !== 0){
                  setWait(false);                  
                  showModal("This shipment "+ item.label +" has a balance of "+ USDollar.format(balanceDueResponse.result) +" due. How would you like to proceed?", MESSAGES_CONSTANTS.MODAL_INFO_TITLE,
                  "Continue Printing", () => {
                    return printSingleInvoice(item, index, itemsToPrint.length-1).then(() => {
                      printSelectedInvoices(index+1);
                    })
                  }, 
                  "Cancel", () =>{
                    hideModal();
                    printSelectedInvoices(index+1);
                  });
                  throw("break");
                }
                else{
                  return printSingleInvoice(item, index, itemsToPrint.length-1);
                }
              }); 
            }
            else{
              await printSingleLoadingTicket(item, index, itemsToPrint.length-1);
            }         
          }
        } catch (error) {
            console.log("Printing suspended")
        }
      }
      else{
        showModal("No items were selected.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
      }
    }

    const showMoney = (item) => {
      showModal("Feature not yet available.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
    }

    const confirmMarkAsShipped = (items) => {
      hideModal();
      let invoices = items.filter(item => item.label.startsWith("PL#"));
      if(invoices.length > 0 && invoices.filter(i => i.statusType === 1).length > 0){
        showModal(MESSAGES_CONSTANTS.MARK_AS_SHIPPED_CONFIRMATION, MESSAGES_CONSTANTS.MODAL_INFO_TITLE,
          "Yes", (() => markAsShipped(invoices)), "No", hideModal);
      }
    }

    const markAsShipped = (invoices) => {
      hideModal();
      setWait(true);
      dispatch(markInvoicesAsShipped(session, invoices)).then(responses =>{   
        setWait(false);             
        responses.forEach(response => {
          if(response.error){
            showModal(response.error);
            return;
          }
        });
        showModal(MESSAGES_CONSTANTS.ADVANCE_SHIPMENT_NOTICE_MESSAGE, MESSAGES_CONSTANTS.MODAL_INFO_TITLE,
          "Yes", (() => sendEmailShipmentDocumentsPackage(invoices)), "No", hideModal);
        
      });
    }

    const sendEmailShipmentDocumentsPackage = async (items) => {
      hideModal();
      if(!loginUser.EmailAddress){
        showModal(MESSAGES_CONSTANTS.ASN_FAILED_MISSING_EMAIL);
        return;
      }
      setWait(true);
      for (let index = 0; index < items.length; index++) {
        let packingList = items[index];
        await dispatch(emailShipmentDocumentsPackage(session, packingList)).then(response => {      
          console.log(response);
          if(response.error && response.result.ErrorMessage.length === 0){
            setWait(false);
            showModal("Error has occurred on Email Advance Shipment Notice.");
            return;
          }
          else if(response.result){
            let data = response.result;
            let attachmentUrl = process.env.REACT_APP_CAPSTONE_SERVICE_URL + "Storage/" + data.AttachmentFilePath;
            dispatch(sendEmailForShipment(session, loginUser, data, attachmentUrl, packingList))
            .then(apiResponse => {
              console.log(apiResponse);
              if(apiResponse.error){
                setWait(false);
                showModal("Error has occurred on Email Advance Shipment Notice.");
                return;
              }
              else{
                if(index === items.length - 1){
                  setWait(false);
                  showModal("Email(s) Advance Shipment Notice sent.", "Info");
                }
                dispatch(deleteTempFile(session, data.AttachmentFilePath)).then(deleteResponse =>{
                  console.log(deleteResponse);
                })
              }
            });
          }
        });
      }
    }

    const clickExportTags = (salesOrderHeaderId) => {
      setWait(true);
      dispatch(exportTagsForSalesOrder(session, salesOrderHeaderId, process.env.REACT_APP_CAPSTONE_SERVICE_URL)).then(response =>{
        setWait(false);
        if(response.error){
          showModal("Error has ocurred on exporting tags.");
        }
        else if(response.result){
          const link = document.createElement('a');
          link.target= "_blank";
          link.rel= "noopener noreferrer";
          link.href = response.result;
          link.click();
          link.remove();
          dispatch(deleteTempFile(session, response.result)).then(done => {});
        }
        else{
          showModal("No tags available for this order.");
        }                       
      });
    }

    const updateShipDate = (item) => {
      setShowUpdatePackingList(true);
      setCurrentItem(item);
    }

    const onChangeFilters = (e) => {
      console.log(filters)
      let filtersValue = {
        ...filters, 
        [e.target.name]: e.target.checked
      };
      console.log(filtersValue)
      setFilters(filtersValue);
  
      dispatch(setFiltersReducer({
         ...filtersSaved, 
        pickup: filtersValue
      }));
    };

    return filters ? (
    <Container fluid className="page-container">
        <div className="text-center">        
            <strong>Customer Pickup for {new Date().toLocaleDateString()} for {currentWarehouse.Name} Warehouse</strong>
        </div>
        <Form onSubmit={submitSearch}>
          <InputGroup className="mb-3">
              <Form.Control title="Search by: Customer Name, Customer Code, Buyer Name, Customer PO#, SO#, PL# or Job Name"
                placeholder="Search" value={searchValue} onChange={e => setSearchValue(e.target.value)}/>
              <Button type="submit"><FontAwesomeIcon icon={faSearch}/></Button>
          </InputGroup>
        </Form>
        <Button onClick={loadData} className="float-right"><FontAwesomeIcon icon={faSync}/></Button>        
        <div className="row">
          <div className="col-12 checkbox-group">
            <input 
              className={"form-control"} 
              type="checkbox"
              checked
              readOnly
            />
            <label>Show Packing Lists</label>
          </div>
        </div>    
        <div className="row">
          <div className="col-12 checkbox-group">
            &nbsp;&nbsp;&nbsp;
            <input 
              className={"form-control"} 
              type="checkbox"
              name="includeMarkedShipped"
              checked={filters?.includeMarkedShipped}
              onChange={onChangeFilters}
            />
            <label>Only show shipments with a Ready status</label>
          </div>
        </div>
        <hr/>  
        <div className="float-right box_item_component orders-labels">
          <div>Ship Date</div>
          <div className="outer overdue order-label"></div><span>Overdue&nbsp;</span>
          <div className="outer today order-label"></div>Today&nbsp;
          <div className="outer future order-label"></div>Future&nbsp;
          <br></br><div className="outer shipped order-label"></div>Being Picked Up/Picked Up/Shipped/Delivered 
        </div>        
        <div className="row">
          <div className="col-12 checkbox-group">
            <input 
              className={"form-control"} 
              type="checkbox"
              name="showSalesOrders"
              onChange={onChangeFilters}
              checked={filters?.showSalesOrders}
            />
            <label>Show Sales Orders</label>
          </div>
        </div>         
        <div className="row">                                   
          <div className="box">                  
              <div className="box-title" style={{backgroundColor:"lightblue"}}>
                  Will Call
              </div>
              <hr/>
              <div className="box-body ">
                {willCallData.map((item, index) => {
                    return <PickupItem key={item.uid} uid={item.uid} itemData={item} index={index}
                      showMoreInfo={showMoreInfo} showMoney={showMoney} printInvoice={printInvoiceClick} exportTags={clickExportTags}
                      updateShipDate={updateShipDate} printOrder={printOrderClick}/>
                })}                     
              </div>
          </div>
          {commonCarrierContainers.map((container, index) => {
            return (
            <div className="box" key={index}>                  
              <div className="box-title" style={{backgroundColor:"lightblue"}}>
                  {container.Description}
              </div>
              <hr/>
              <div className="box-body ">
                {commonCarrierData.map((item, index) => {
                    return item.deliveryMethodId.toString() === container.MethodId ? 
                      <PickupItem key={item.uid} uid={item.uid} itemData={item} index={index}
                        showMoreInfo={showMoreInfo} showMoney={showMoney} printInvoice={printInvoiceClick} exportTags={clickExportTags}
                        updateShipDate={updateShipDate} printOrder={printOrderClick}/> 
                      : null;
                })}                     
              </div>
            </div>)
          })}
        </div>        
        <Button className="float-right" onClick={() => printSelectedInvoices()}>Print All Selected</Button>
        <br/><br/>
        <WaitModal wait={wait}/>
        <CustomModal modalData={modalData} hideModal={hideModal}/>
        <PrintPreviewModal modalData={printPreviewData} hideModal={onHidePrintPreview}/> 
        <UpdatePackingListDialog currentItem={currentItem} show={showUpdatePackingList} 
          hide={() => setShowUpdatePackingList(false)} setWait={setWait} onUpdateDone={()=>{}}
          capstoneVersion={props.capstoneVersion}/>
    </Container>)
    :(<WaitModal wait={wait}/>);
}

export default PickupScreen;