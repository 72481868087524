import { SalesOrderService } from "../restServices/SalesOrderService.js";
import { SalesInvoiceService } from "../restServices/SalesInvoiceService.js";
import { EmailService } from "../restServices/EmailService.js";
import { SalesInvoiceServiceSOAP } from "../soapServices/SalesInvoiceService";
import { SalesOrderServiceSOAP } from "../soapServices/SalesOrderService";
import { WarehouseService } from "../soapServices/WarehouseService.js";
import { TagService } from "../soapServices/TagService.js";
import { FileAttachmentService } from "../soapServices/FileAttachmentService.js";

var salesInvoiceService = undefined;
var salesOrderService = undefined;
var warehouseService = undefined;
var salesInvoiceServiceSOAP = undefined;
var tagService = undefined;
var fileAttachmentService = undefined;
var emailService = undefined;
var salesOrderServiceSOAP = undefined;

export const initServices = (capstoneWebAPI, capstoneServiceUrl, capstoneVersion) => {
  salesInvoiceService = new SalesInvoiceService(capstoneWebAPI);
  salesOrderService = new SalesOrderService(capstoneWebAPI);
  emailService = new EmailService(capstoneWebAPI);
  salesInvoiceServiceSOAP = new SalesInvoiceServiceSOAP(capstoneServiceUrl, capstoneVersion);
  warehouseService = new WarehouseService(capstoneServiceUrl, capstoneVersion); 
  tagService = new TagService(capstoneServiceUrl, capstoneVersion); 
  fileAttachmentService = new FileAttachmentService(capstoneServiceUrl, capstoneVersion); 
  salesOrderServiceSOAP = new SalesOrderServiceSOAP(capstoneServiceUrl, capstoneVersion);
  console.log("Pickup services init") 
};

const initialState = {  
  willCallData: [],
  commonCarrierData: [],
  commonCarrierContainers: [],
};

const pickupReducer = (state = initialState, action) => {
  switch (action.type) {    
    case "setPickupData":
      return {
        ...state,
        willCallData: action.willCallData,
        commonCarrierData: action.commonCarrierData 
      };
    case "setCommonCarrierContainers":
      return {
        ...state,
        commonCarrierContainers: action.commonCarrierContainers,
      };
    default:
      return state;
  }
};

export const setPickupData = (willCallData, commonCarrierData) => {
  return (dispatch) => {
    dispatch({
      type: "setPickupData",
      willCallData: willCallData,
      commonCarrierData: commonCarrierData
    });
  };
};

export const loadPickUpData = (warehouseId, session, includeOtherStatus, showSalesOrders, search) => {

  return (dispatch, getState) => {
    const state = getState();
    const commonCarrierContainersState = state.pickupReducer.commonCarrierContainers;

    let promises = [];
    if(commonCarrierContainersState.length === 0){
      console.log("querying common carrier")
    //Query common carrier containers from warehouse delivery methods if it has not been set
      promises.push(queryCommonCarriers(session, warehouseId));
    }

    //Always query for Sales Invoices
    promises.push(salesInvoiceService.GetSalesInvoicesForPickup(session, warehouseId, includeOtherStatus, search));

    //Check Show Sales Orders filter
    if(showSalesOrders){
      promises.push(salesOrderService.GetOpenSalesOrders(session, warehouseId, null, false, true, true, true, search));
    }

    var resultResponse = {
      result: undefined,
      error: undefined,
      info: undefined,
    };
    return Promise.all(promises).then(responses => {
      console.log(responses);
      let pickupData = [];
      responses.forEach((response, index) => {
        if(response.error){
          resultResponse.error = response.error.exceptionMessage;
          dispatch({
            type: "setPickupData",
            willCallData: [],
            commonCarrierData: []
          }); 
          dispatch({
            type: "setCommonCarrierContainers",
            commonCarrierContainers: []
          });   
          return resultResponse;
        }        
      });

      let indexData = 0;
      if(commonCarrierContainersState.length === 0){
        dispatch({
          type: "setCommonCarrierContainers",
          commonCarrierContainers: responses[0]
        });
        indexData++;
      }

      pickupData = pickupData.concat(responses[indexData].result);
      if(showSalesOrders){
        pickupData = pickupData.concat(responses[indexData+1].result);
      }
      
      let willCall = [];
      let commonCarrier = [];
      pickupData.forEach(item => {
        if(item?.deliveryAddressType === 1){
          willCall.push({
            ...item,
            willCall: true
          });
        }
        else if(item?.isCommonCarrier){
          commonCarrier.push({
            ...item,
            commonCarrier: true
          });
        }
      });      

      dispatch({
        type: "setPickupData",
        willCallData: willCall,
        commonCarrierData: commonCarrier
      });
          
      if(willCall.length === 0 && commonCarrier.length === 0){
        resultResponse.info = "No orders or packing lists available for this date.";
        return resultResponse;
      }            

      resultResponse.result = pickupData;
      return resultResponse;
    });
  }
}

export const printInvoice = (session, invoice, wait = true, delay) => {    
  return (dispatch, getState) => {
    console.log("Printing packing list " + invoice.id);
    return salesInvoiceServiceSOAP.PrintPackingList(session, invoice.id, true, invoice.includePricing).then(printPackingListResult => {
      console.log("Packing list done " + invoice.id);
      return wait ? 
      sleep((delay * 1000)).then(() => {
        return printTestReport(invoice, session, wait, delay);
      }) :
      printTestReport(invoice, session, wait, delay);                           
    });
  }
}

export const printLoadingTicket = (session, order, wait = true, delay) => {    
  return (dispatch, getState) => {
    console.log("Printing loading ticket " + order.id);
    return salesOrderServiceSOAP.PrintLoadingTicket(session, undefined, order.id, true).then(printLoadingTicketResult => {
      console.log("Loading ticket done " + order.id);
      return wait ? 
      sleep((delay * 1000)).then(() => {
        console.log("wait done")
        return true;
      }) : true;
    });
  }
}

const printTestReport = (invoice, session, wait, delay) => { 
  if(invoice.deliverTestReport){
    console.log("Printing test report " + invoice.id);             
    return salesInvoiceServiceSOAP.GetTestReportForSalesInvoice(session, invoice.id).then(testReportResult =>{              
      console.log("Test report done " + invoice.id);
      return wait ? sleep((delay * 1000)).then(() => { 
        return true; 
      }) : true;              
    });
  }
  else{
    return true;
  }   
}

const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const selectItem = (item, value) => {
  return (dispatch, getState) => {
    const state = getState();
    let willCallDataCopy = state.pickupReducer.willCallData.slice();
    let commonCarrierDataCopy = state.pickupReducer.commonCarrierData.slice();
    let itemIndex = -1;
    if(item.willCall){
      itemIndex = willCallDataCopy.findIndex(i => i.id === item.id);
      if(itemIndex !== -1){
        willCallDataCopy[itemIndex].selected = value;
      }
    }
    else if(item.commonCarrier){
      itemIndex = commonCarrierDataCopy.findIndex(i => i.id === item.id);
      if(itemIndex !== -1){
        commonCarrierDataCopy[itemIndex].selected = value;
      }
    }

    dispatch({
      type: "setPickupData",
      willCallData: willCallDataCopy,
      commonCarrierData: commonCarrierDataCopy
    });
  };
};

export const markInvoicesAsShipped = (session, invoices) =>{
  return (dispatch, getState) => {
    const state = getState();
    let willCallDataCopy = state.pickupReducer.willCallData.slice();
    let commonCarrierDataCopy = state.pickupReducer.commonCarrierData.slice();

    let willCallToMark = [];
    let commonCarrierToMark = [];

    invoices.forEach(item => {
      let itemIndex = -1;    
      if(item.willCall){
        itemIndex = willCallDataCopy.findIndex(i => i.id === item.id);
        if(itemIndex !== -1){
          willCallDataCopy[itemIndex].statusType = 5;
          willCallToMark.push(item);
        }
      }
      else if(item.commonCarrier){
        itemIndex = commonCarrierDataCopy.findIndex(i => i.id === item.id);
        if(itemIndex !== -1){
          commonCarrierDataCopy[itemIndex].statusType = 4;
          commonCarrierToMark.push(item);
        }
      }
    });

    dispatch({
      type: "setPickupData",
      willCallData: willCallDataCopy,
      commonCarrierData: commonCarrierDataCopy
    });
    
    let promises = []
    //Common carrier items to use SetShipmentToShippedOrPickUpInProgress
    for (let index = 0; index < commonCarrierToMark.length; index++) {
      promises.push(salesInvoiceServiceSOAP.ShipPackingList(session, commonCarrierToMark[index].id));
    };
    // Will call items to use SetShipmentToDeliveredOrPickedUp
    for (let index = 0; index < willCallToMark.length; index++) {
      promises.push(salesInvoiceServiceSOAP.DeliverPackingList(session, willCallToMark[index].id));
    };
    return Promise.all(promises);
  };
}

const queryCommonCarriers = (session, warehouseId) => { 
  return warehouseService.GetAllActiveWarehouseDeliveryMethodsForWarehouse(session, warehouseId)
  .then(deliveryMethodsResults =>{
    console.log(deliveryMethodsResults);
    let commonCarriers = [];
    deliveryMethodsResults.result.forEach(method => {
      if(method.CommonCarrierFlag === "true" && method.TransactionType !== "3"){
        commonCarriers.push(method);
      }
    });
    return commonCarriers;
  });
}

export const exportTagsForSalesOrder = (session, salesOrderId, capstoneServiceUrl) =>{
  return (dispatch, getState) => {
         
    return tagService.ExportTagsForSalesOrder(session, salesOrderId)
      .then(exportResult => {
        if(!exportResult.error && exportResult.result){
          exportResult.result = exportResult.result;
        }
        return exportResult;
      });

  };  
}

export const deleteTempFile = (session, fileLocation) => {
  return (dispatch, getState) => {
    return fileAttachmentService.DeleteTemporaryFile(session, fileLocation)
    .then(deleteResult =>{
        return true;
    }); 
  }
}

export const emailShipmentDocumentsPackage = (session, packingList) => {
  return (dispatch, getState) => {
    return salesInvoiceServiceSOAP.EmailShipmentDocumentsPackage(session, packingList.id, packingList.includePricing);
  }   
}

export const sendEmailForShipment = (session, loginUser, data, attachmentUrl, packingList) => {
  return (dispatch, getState) => {    
    return emailService.SendEmailForShipment(session, loginUser.EmailAddress, loginUser.Name,
      data.EmailTo ?? data.EmailCarbonCopy, data.EmailCarbonCopy, data.EmailSubject, data.EmailBody, attachmentUrl, packingList);
  }   
}

export const checkBalanceDueForOrder = (session, orderId) => {
  return (dispatch, getState) => {
    return salesOrderServiceSOAP.GetTotalUnitPriceExtendedForOrder(session, orderId).then(totalPriceResponse => {
      if(totalPriceResponse.result){
        let totalPricePlusTax = Number(totalPriceResponse.result.TotalUnitPriceExtended) + 
        Number(totalPriceResponse.result.TotalSalesTaxAmount);

        return salesOrderServiceSOAP.GetAllSalesOrderPaymentDetailsForSalesOrder(session, orderId).then(paymentResponse => {
          if(paymentResponse.result){
            let totalPayment = 0;
            paymentResponse.result.forEach(payment => {
              totalPayment += Number(payment.Amount);
            });
            console.log("totalPricePlusTax= " + totalPricePlusTax)
            console.log("totalPayment= " + totalPayment)
            return { result: totalPricePlusTax - totalPayment };
          }
        })
      }
      return { error: totalPriceResponse.error };
    });
    
  }   
}

export default pickupReducer;