import { BaseSoapService } from "./BaseSoapService.js";

export class TagService extends BaseSoapService {
  constructor(baseUrl, capstoneVersion) {
    super(baseUrl, "TagService.asmx", "TagService", capstoneVersion);
  }

  ExportTagsForSalesOrder(session, salesOrderHeaderId) {
    var args = {
      session: session,
      salesOrderHeaderId: salesOrderHeaderId,
    };

    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    return super.makeSoapCall("ExportTagsForSalesOrder", args).then(
      (result) => {
        console.log("ExportTagsForSalesOrder Result:");
        if (result.ExportTagsForSalesOrderResponse) {
          resultResponse.result =
            result.ExportTagsForSalesOrderResponse.ExportTagsForSalesOrderResult;
        }
        return resultResponse;
      },
      (error) => {
        console.log("Error on ExportTagsForSalesOrder:");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

}
