import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from "react-bootstrap";
import useModal from '../useModal/useModal';
import CustomModal from '../useModal/CustomModal';
import { useSelector, useDispatch } from "react-redux";
import { updateRun } from "../../reducers/routesReducer";

import "react-datepicker/dist/react-datepicker.css";
var shortid = require('shortid');

export default function AddManualStopDialog(props) {

    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const place = useRef();
    const autoCompleteOptions = {
      componentRestrictions: { country: "us" },
      //fields: ["address_components", "geometry", "icon", "name"],
      //types: ["establishment"]
    };
    
    const session = useSelector(state => state.auth.sessionData.session);
    const dispatch = useDispatch();
    const currentWarehouse = useSelector(state => state.menuInfo.currentWarehouse);
    const [modalData, showModal, hideModal] = useModal();
    const [show, setShow] = useState(false);
    const [address, setAddress] = useState("");
    const [currentItem, setCurrentItem] = useState();

    useEffect(() => {
      if(show && inputRef.current){
        setAddress("");
        inputRef.current.focus();

        const warehouseLocation = {
          lat: Number(currentWarehouse.Latitude),
          lng: Number(currentWarehouse.Longitude)
        };
        console.log(warehouseLocation)
        autoCompleteOptions.bounds = {
          north: warehouseLocation.lat + 0.1,
          south: warehouseLocation.lat - 0.1,
          east: warehouseLocation.lng + 0.1,
          west: warehouseLocation.lng - 0.1,
        };

        autoCompleteRef.current = new props.google.maps.places.Autocomplete(
          inputRef.current,
          autoCompleteOptions
        );

        autoCompleteRef.current.addListener("place_changed", async () => {
          place.current = await autoCompleteRef.current.getPlace();
          setAddress(inputRef.current?.value)
          console.log(place.current);
         });
      }
    }, [show]);

    useEffect(() => {
      setCurrentItem(props.currentItem);
  }, [props.currentItem]);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    const hide = () => {
        setShow(false);
        props.hide();
    }

    const save = (e) => {     
      e.preventDefault();
      hide();
      const id = shortid.generate();
      let newStop = {
        id: id,
        uid: id,
        label: place.current?.name,
        cityAndState: place.current?.address_components?.find(a => a.types[0] === "locality")?.short_name + 
          ", " + place.current?.address_components?.find(a => a.types[0] === "administrative_area_level_1")?.short_name,        
        manualStop: true, 
        totalWeight: 0,
        runNumber: currentItem?.runNumber,
        lat: place.current?.geometry?.location?.lat(), 
        lng: place.current?.geometry?.location?.lng(), 
        sequenceNumber: currentItem.sequenceNumber,
        targetDeliveryDate: currentItem.targetDeliveryDate,
        routeId: currentItem.routeId,
      };
      currentItem.items.push(newStop);

      let runToUpdate = {
        routeId: currentItem.routeId,
        runIndex: currentItem?.runNumber, 
        items: currentItem.items,
        color: currentItem.color,
      }
      props.setWait(true);
      dispatch(updateRun(runToUpdate, false, session)).then(response => {
        console.log(response)
        props.setWait(false);        
      });
    }

    return (<>
      <Modal
          show={show}
          onHide={() => {}}
          centered
          autoFocus={true}
        >
          <Modal.Header>
            <Modal.Title>Add Manual Stop</Modal.Title>
          </Modal.Header>
          <Form onSubmit={save}>
            <Modal.Body>
              <label className="form-label">Address: </label>
              <input 
                value={address} 
                onChange={(e) => setAddress(e.target.value)}
                className={"form-control"}
                required={true}
                autoComplete='off'
                ref={inputRef}
              />                      
            </Modal.Body>
            <Modal.Footer>          
              <Button
                onClick={hide}>
                Cancel
              </Button>
              <Button type='submit'>
                Save
              </Button>          
            </Modal.Footer>
          </Form>
        </Modal>
        <CustomModal modalData={modalData} hideModal={hideModal}/>
      </>
    )
}