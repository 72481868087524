import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createHashHistory as createHistory } from "history";
import rootReducer from "./reducers";

export const history = createHistory();

const initialState = {};

const asyncFunctionMiddleware = storeAPI => next => action => {
  // If the "action" is actually a function instead...
  if (typeof action === 'function') {
    // then call the function and pass `dispatch` and `getState` as arguments
    return action(storeAPI.dispatch, storeAPI.getState)
  }

  // Otherwise, it's a normal action - send it onwards
  return next(action)
}

const middleware = [thunk, routerMiddleware(history), asyncFunctionMiddleware];

const composedEnhancers = compose(applyMiddleware(...middleware));

const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers
);

export default store;
