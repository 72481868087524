import React, { useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function CustomModal(props){

    const closeButtonRef = useRef(null);
    useEffect(() => {
      if(props.modalData.show && closeButtonRef.current){
        closeButtonRef.current.focus();
      }
    }, [props.modalData.show]); 

    return (
    <Modal
      show={props.modalData.show}
      onHide={() => {}}
      centered
      autoFocus={true}
      size="xl"
    >
      <Modal.Body>
        <div className="pdf-viewer-container">
          <object data={props.modalData.fileLocation} className="pdf-viewer">
            <p>Your browser doesn't support PDFs.</p>
            <p><a href={props.modalData.fileLocation} 
            target="_blank" rel="noopener noreferrer">Download Instead</a></p>
          </object>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.hideModal} ref={closeButtonRef}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>);
}