import React, { Component } from "react";
import utils from "../../utils/utils.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logInSuccess } from "../../reducers/auth";
import {
  setCurrentWarehouse,
  setMenuInfo,
} from "../../reducers/menuInfo";
import { history } from "../../Store";

import "bootstrap/dist/css/bootstrap.css";
import Table from "react-bootstrap/Table";

import { WarehouseService } from "../../soapServices/WarehouseService.js";

class Warehouse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warehouses: [],
      session: {},
      warehouseSelected: {},
    };
  }

  componentDidMount() {
    utils.calculateTableHeight();
    console.log(this.props.sessionData);
    this.setState({
      warehouses: this.props.sessionData.warehouseList,
    });

    setTimeout((e) => {
      this.onClickRow(undefined, this.state.warehouses[0], "warehouseRowId0");
    }, 200);

    this.props.setMenuInfo("Change Warehouse");
    this.warehouseService = new WarehouseService(process.env.REACT_APP_CAPSTONE_SERVICE_URL, this.props.capstoneVersion);
  }

  render() {
    return (
      <div
        className="container-fluid page-container"
        style={{ cursor: this.state.wait ? "wait" : "default", textAlign: "-webkit-center" }}
        disabled={this.state.wait}
      >
        <div className="full-size" style={{ width: "90%" }} id="divContainerWithTable">
          <div id="divFields" className="mb-3 col-12">
            <form id="currentForm" onSubmit={this.onSave}>
              <div className="lookup-table">
                <Table
                  striped
                  bordered
                  className="lookup-table"
                  style={{ marginTop: "1rem" }}
                >
                  <tbody id="tableBodyId">
                    {this.state.warehouses.map((warehouse, index) => {
                      let rowId = "warehouseRowId" + index;
                      return (
                        <tr
                          className="lookup-row"
                          id={rowId}
                          key={index}
                          onClick={(e) => {
                            this.onClickRow(e, warehouse, rowId);
                          }}
                        >
                          <td>{warehouse.Name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <button className="btn btn-primary float-right" type="submit">Save</button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  handleChange = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({
      [name]: value,
      searchResult: "",
    });
  };

  componentWillUnmount() {
    // cancel click callback
    if (this.clickTimeout) clearTimeout(this.clickTimeout);
  }

  clickCounter = 0;
  onClickRow = (event, warehouse, rowId) => {
    console.log("onClickRow");
    let currentRow = document.getElementById(rowId);
  
    if (this.rowSelected !== currentRow) {
      if (this.rowSelected) {
        this.rowSelected.classList.remove("selected");
      }
      currentRow.classList.add("selected");
      this.rowSelected = currentRow;
    }

    this.setState({ warehouseSelected: warehouse });

    // cancel previous callback
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }
    this.clickCounter++;
    // schedule new callback  [timeBetweenClicks] ms after last click
    this.clickTimeout = setTimeout(() => {
      // listen for double clicks
      if (this.clickCounter === 2) {
        // Double Click
        console.log("Double Click");
        this.onSave(event);
      }
      // reset count
      this.clickCounter = 0;
    }, 250);
  };

  onSave = (e) => {
    //e.preventDefault();
    console.log("On Save");
    var currentSession = this.props.sessionData;

    //Set current SessionStorage with warehouse updated
    this.props.setCurrentWarehouse(this.state.warehouseSelected);

    if (
      this.state.warehouseSelected &&
      this.state.warehouseSelected.WarehouseId > 0
    )
      this.getAllWarehouseLocationsForWarehouse(
        currentSession.session,
        this.state.warehouseSelected.WarehouseId
      );

    //this.props.setMenuInfo("Welcome", false, "");
    history.push("/routing");
  };

  handleKeyDown = (event) => {
    if (event.key === "Tab" || event.key === "Enter") {
      event.preventDefault();
      this.setState({
        searchResult: this.state.purchaseOrderNumber,
      });
    }
  };

  searchTag = (event) => {
    this.setState({
      searchResult: this.state.purchaseOrderNumber,
    });
  };

  getAllWarehouseLocationsForWarehouse = (session, warehouseId) => {
    return this.warehouseService
      .GetAllWarehouseLocationsForWarehouse(session, warehouseId)
      .then((warehouseLocationsResult) => {
        let error = warehouseLocationsResult.error;
        let allWarehouseLocationList = warehouseLocationsResult.result;
        if (error) {
          this.setState({ wait: false });
        } else {
          var currentSession = this.props.sessionData;
          //update warehouse Location
          currentSession.allWarehouseLocationList = allWarehouseLocationList;
          //Set current SessionStorage with warehouseLocation updated
          this.props.logInSuccess(currentSession);
        }
      });
  };
}

function mapStateToProps(state) {
  return {
    isLogged: state.auth.isLogged,
    sessionData: state.auth.sessionData,
    currentWarehouse: state.menuInfo.currentWarehouse,
    currentPageName: state.menuInfo.currentPageName,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logInSuccess,
      setCurrentWarehouse,
      setMenuInfo,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Warehouse);
