import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function useMapImageDriverManifest(props){

  const currentWarehouse = useSelector(state => state.menuInfo.currentWarehouse);
  const [warehouseLocation, setWarehouseLocation] = useState();
  const [encodedDirections, setEncodedDirections] = useState("");
  const [itemsCopy, setItemsCopy] = useState(props.items); 

  useEffect(() => {
    setWarehouseLocation({
      lat: Number(currentWarehouse.Latitude),
      lng: Number(currentWarehouse.Longitude)
    });
  }, []); 
  
  const displayRouteDirections = () => {
    const directionsService = new props.google.maps.DirectionsService();
    console.log(props.items)
    let waypoints = [];
    props.items.forEach(item => {
      if(item.lat && item.lng){
        waypoints.push({
          location: { lat: item.lat, lng: item.lng },
          stopover: true,
        });
      } 
    });
    let request = {
      origin: warehouseLocation,
      waypoints: waypoints,
      destination: warehouseLocation,
      travelMode: "DRIVING",
    };      
    return directionsService.route(request).then(result => {       
      if (result.status === "OK") {
        return result;
      }
    });
  }

  const generateMapImage = () => {
    return new Promise((resolve) => {
      if(encodedDirections.length === 0 || props.items?.filter(item1 => !itemsCopy.some(item2 => item1.id === item2.id))?.length > 0){
        props.setWait(true);
        setItemsCopy(props.items);
        return displayRouteDirections().then(directions => {
          props.setWait(false);
          setEncodedDirections(directions.routes[0].overview_polyline);
          return resolve(generateMapImageUrl(directions.routes[0].overview_polyline))
        });
      }
      else{
        return resolve(generateMapImageUrl(encodedDirections));
      }  
    });
  }

  const generateMapImageUrl = (encodedDirectionsParam) => {
    let googleMapsUrl = "https://maps.googleapis.com/maps/api/staticmap?size=1000x300&scale=1&format=png&maptype=roadmap&key=" + process.env.REACT_APP_GOOGLE_API_KEY;
    googleMapsUrl += `&markers=size:normal|color:gray|label:W|${warehouseLocation.lat},${warehouseLocation.lng}`;

    let stops = {};
    let stopCounter = 1;
    props.items.forEach((item) => {
      let location = item.lat + "-" + item.lng + item.deliveryAddressName;
      if(!stops[location]){
        stops[location] = stopCounter++;
      }
      if(item.lat && item.lng ){
        googleMapsUrl += `&markers=size:normal|label:${stops[location]}|${item.lat},${item.lng}`;
      }
    });
    
    googleMapsUrl += "&path=weight:5|enc:" 
    googleMapsUrl = encodeURI(googleMapsUrl);
    return googleMapsUrl + encodedDirectionsParam;
  }

  return [generateMapImage];
}