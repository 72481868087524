export class BaseRestService{

  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

    CustomFetch(apiCall, urlParams, httpMethod, model){

        var resultResponse = {
          result: undefined,
          error: undefined,
        };
    
        return fetch(this.baseUrl + apiCall + urlParams, { 
          method: httpMethod, 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(model)
        })
        .then(response => {      
          if(response.status === 200){
            return response.json();
          }
          else{
            return response.json().then(error => {
              throw error;
            }); 
          }
        })
        .then(jsonResult => {
          console.log(apiCall);
          console.log(jsonResult);
          resultResponse.result = jsonResult;   
          return resultResponse;
        })
        .catch(error => {
          console.log("Error on " + apiCall);
          console.log(error);
          if(error.exceptionMessage === undefined){
            error.exceptionMessage = "Unexpected error in " + apiCall;
          }
          resultResponse.error = error;
          return resultResponse;
        });
    }
}