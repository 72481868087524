import { BaseSoapService } from "./BaseSoapService.js";

export class ShippingService extends BaseSoapService {
  constructor(baseUrl, capstoneVersion) {
    super(baseUrl, "ShippingService.asmx", "ShippingService", capstoneVersion);
  }


  GetAllTrucks(session) {
    var args = {
      session: session
    };
    var resultResponse = {
      result: [],
      error: undefined
    };

    return super.makeSoapCall("GetAllTrucks", args).then(
      result => {
        let trucks = result.GetAllTrucksResponse.GetAllTrucksResult;
        console.log(trucks);
        let truckList = trucks !== null ? trucks.TruckExt : trucks;
        if (trucks && Array.isArray(truckList)) {
          resultResponse.result = truckList;
        } else if (trucks && trucks !== null) {
          resultResponse.result = [truckList];
        }
        return resultResponse;
      },
      error => {
        console.log("Error on GetAllTrucks:");
        console.error(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  // Trailer Section

  GetAllTrailers(session) {
    var args = {
      session: session
    };
    var resultResponse = {
      result: [],
      error: undefined
    };

    return super.makeSoapCall("GetAllTrailers", args).then(
      result => {
        let trailers = result.GetAllTrailersResponse.GetAllTrailersResult;
        let trailersList = trailers !== null ? trailers.Trailer : trailers;
        if (trailers && Array.isArray(trailersList)) {
          resultResponse.result = trailersList;
        } else if (trailers && trailers !== null) {          
          resultResponse.result = [trailersList];
        }
        return resultResponse;
      },
      error => {
        console.log("Error on GetAllTrailers:");
        console.error(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  
}
