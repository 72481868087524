import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import CustomRouter from "./components/Router";
//import * as serviceWorker from "./serviceWorker";
import reportWebVitals from './reportWebVitals';

import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import store, { history } from "./Store";

import packageJson from '../package.json';

console.log(packageJson.customVersion)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <CustomRouter appVersion={packageJson.customVersion} capstoneVersion={packageJson.capstoneVersion} />
    </ConnectedRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
