import React, { useState, useEffect } from 'react';
import { flushSync } from 'react-dom'; 
import DropTarget from '../dragDropContainer/DropTarget';
import Item from './Item';
import CreateLoad from '../routingScreen/CreateLoad';
import PrintLoad from '../routingScreen/PrintLoadDialog';

import { useSelector, useDispatch } from "react-redux";
import { updateRun, removeFromLoad, deleteLoad, removeManualStop } from "../../reducers/routesReducer";

import { GithubPicker  } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltFromTop, faArrowAltToTop, faTimesCircle, faPalette, faWindowMaximize, faWindowMinimize, faTag } from '@fortawesome/pro-solid-svg-icons';

import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { LoadService } from "../../restServices/LoadService";
import CustomModal from '../useModal/CustomModal';
import useModal from '../useModal/useModal';
import PrintSODocs from '../routingScreen/PrintSODocs';

var shortid = require('shortid');
const SUPER_MINIMIZED = "super-minimized";
const MINIMIZED = "minimized";

export default function Run(props) {

  //Services
  const loadService = new LoadService(process.env.REACT_APP_CAPSTONE_REST_API);

  const session = useSelector(state => state.auth.sessionData.session);
  const currentWarehouse = useSelector(state => state.menuInfo.currentWarehouse);
  const dispatch = useDispatch();

  //States
  const compareItem = (item1, item2) =>{
    if ( item1.sequenceNumber < item2.sequenceNumber ){
      return -1;
    }
    if ( item1.sequenceNumber > item2.sequenceNumber ){
      return 1;
    }
    return 0;
  }
  const initialItems = props.items ? props.items.sort(compareItem) : [];
  const [items, setItems] = useState(initialItems);  
  const [totalWeight, setTotalWeight] = useState(0);
  useEffect(() => {
    setTotalWeight(calculateTotalWeight(props.items));
  }, [props.items]);

  const calculateTotalWeight = (items) =>{
    let totalWeight = 0;
    items.forEach(item => {
      totalWeight += item.totalWeight;
    });
    return totalWeight;
  }

  const [color, setColor] = useState(props.color);
  useEffect(() => {
    setColor(props.color);
  }, [props.color]); 

  const [loadCreated, setLoadCreated] = useState(props.isLoad);
  const initialLoadNumber = props.isLoad && props.items.length > 0 ? props.items[0].loadNumber : undefined;
  const [loadNumber, setLoadNumber] = useState(initialLoadNumber);
  const [loadDriver, setLoadDriver] = useState(undefined);
  const [loadTruck, setLoadTruck] = useState(undefined);
  const [loadDate, setLoadDate] = useState(undefined);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showCreateLoadDialog, setShowCreateLoadDialog] = useState(false);
  const [showPrintSODocsDialog, setShowPrintSODocsDialog] = useState(false);  

  const [showPrintLoadDialog, setShowPrintLoadDialog] = useState(false);
  useEffect(() => {
    setShowPrintLoadDialog(props.showPrintLoadDialog);
  }, [props.showPrintLoadDialog]);

  const [minimized, setMinimized] = useState(props.minimized);
  const [title, setTitle] = useState(props.title);
  const [editingTitle, setEditingTitle] = useState("");
  const [showEditTitle, setShowEditTitle] = useState(false);

  const updateNoteItem = (item) =>{
    if(item){
      let itemsCopy = items;
      let itemToUpdate = itemsCopy.find(i => i.secondaryId === item.orderId);
      if(itemToUpdate){
        itemToUpdate.note = item.note;
        setItems([...itemsCopy]);
      } 
    }     
  }
  useEffect(() => {
    updateNoteItem(props.currentItemNote);
  }, [props.currentItemNote]);

  const [modalData, showModal, hideModal] = useModal();
  const [wait, setWait] = useState(false);

  //Functions
  const removeItem = (item) => {
    if(item.manualStop){
      dispatch(removeManualStop(item));
      let itemsCopy = items.slice();
      const index = itemsCopy.findIndex(i => i.id === item.id);
      if (index !== -1) {
        itemsCopy.splice(index, 1);
        setItems([...itemsCopy]);
      }
    }
    else{
      if(items.length > 1){
        showModal("Are you sure you want to remove this packing list from the load?", MESSAGES_CONSTANTS.MODAL_WARNING_TITLE,
        "No", hideModal, "Yes", (e => removeItemFromLoad(item)));
      }
      else{
        removeItemFromLoad(item);
      }
    }
  }

  const removeItemFromLoad = (item) =>{
    hideModal();
    setWait(true);    
    dispatch(removeFromLoad(item, loadNumber, session)).then(response => {
      setWait(false);
      if(response.error){
        showModal(response.error, MESSAGES_CONSTANTS.MODAL_ERROR_TITLE);
      }
      else if(response.packingListAlreadyRemoved){
        showModal(response.packingListAlreadyRemoved, MESSAGES_CONSTANTS.MODAL_ERROR_TITLE, "OK", 
        () => {
          hideModal();
          props.refreshRoutesData();
        });
      }
      else if(response.deleteLoadError && response.result){
        showModal(response.deleteLoadError, MESSAGES_CONSTANTS.MODAL_WARNING_TITLE, "No", hideModal, 
          "Yes", () => callDeleteLoad(item.routeId, response.result));
      }
      else{
        let itemsCopy = items.slice();
        const index = itemsCopy.findIndex(i => i.id === item.id);
        if (index !== -1) {
          itemsCopy.splice(index, 1);
          setItems([...itemsCopy]);
          setTotalWeight(calculateTotalWeight(itemsCopy));
        }
      }
    });
  }

  const callDeleteLoad = (routeId, invoice) => {
    setWait(true);
    dispatch(deleteLoad(routeId, loadNumber, invoice, session)).then(response =>{
      //setWait(false);
      console.log(response);
      props.filterMapLocations();
    })
  }

  const editLoad = () => {
    setShowPrintLoadDialog(false);
    updateRunReducer(items, color, false, true, minimized, title, false);
    setShowCreateLoadDialog(true);
  }

  const viewLoad = () =>{    
    if(loadCreated && loadNumber){
      setWait(true);
      loadService.GetLoad(session, loadNumber)
      .then(loadResult => {
        setWait(false);
        if(!loadResult.error && !loadResult.result){ 
          showModal("This load no longer exists.", "Warning", "OK", 
            (() => {
              hideModal();
              props.refreshRoutesData();
            }));
        }
        else if(!loadResult.error && loadResult.result){
          setShowPrintLoadDialog(true);
        }
      });
    }
    else{
      setShowPrintLoadDialog(true);
    }
  }

  const OnDoneMarkAllAsShipped = (e) => {
    let itemsCopy = items.slice();
    itemsCopy.forEach(i => i.statusType = 4);
    setItems([...itemsCopy]);
    updateRunReducer(itemsCopy, color, false, loadCreated, minimized, title, false);
  }

  const editTitle = (e) => {
    setEditingTitle(title);
    setShowEditTitle(true);
  }

  const saveTitle = () => {
    console.log("onSaveTitle");
    setShowEditTitle(false);
    setTitle(editingTitle);
    updateRunReducer(items, color, false, loadCreated, minimized, editingTitle);
  }

  const onChangeTitleInput = (e) => {
    console.log(e.target.value)
    setEditingTitle(e.target.value);
  }

  const updateRunReducer = (items, newColor, updateDb = true, loadCreated, newMinimized, title, showPrintLoadDialog) => {
    let runToUpdate = {
      routeId: props.routeId,
      runIndex: props.index, 
      items: items, 
      color: newColor ? newColor : color,
      loadCreated: loadCreated,
      minimized: newMinimized !== undefined ? newMinimized : minimized,
      title: title,
      showPrintLoadDialog: showPrintLoadDialog,
    }
    setWait(true);
    dispatch(updateRun(runToUpdate, updateDb, session))
    .then(responses => {
      setWait(false);
      console.log(responses);
      if (responses.length > 0 && responses[0].error) {
        showModal(responses[0].error.exceptionMessage, MESSAGES_CONSTANTS.MODAL_ERROR_TITLE,
          MESSAGES_CONSTANTS.MODAL_OK_OPTION, 
          () => {
            hideModal(); 
            props.refreshRoutesData();
          });          
      }
      else
      {
        props.filterMapLocations();
      }
    });
  }

  const handleDrop = (e) => {
    console.log("drop in run");
    let itemsCopy = items.slice(); 
    console.log(e.dragData)
    itemsCopy.push({
      ...e.dragData, 
      uid: shortid.generate(),
      runNumber: props.index,
      routeId: props.routeId
    });
    e.containerElem.style.visibility="hidden";
    setItems([...itemsCopy]);
    setTotalWeight(calculateTotalWeight(itemsCopy));
    updateRunReducer(itemsCopy);      
  };

  const swap = (fromIndex, toIndex, dragData) => {
    console.log("swap in run to " + toIndex);
    let itemsCopy = items.slice();
    const item = {
      ...dragData,
      uid: shortid.generate(),
      runNumber: props.index,
      routeId: props.routeId
    };
    console.log(item)
    itemsCopy.splice(toIndex, 0, item);
    setItems([...itemsCopy]);
    updateRunReducer(itemsCopy);
  };

  const kill = (uid) => {
    console.log("kill in run");
    let itemsCopy = items.slice();
    const index = itemsCopy.findIndex((item) => item.uid === uid);
    if (index !== -1) {
      itemsCopy.splice(index, 1);
    }
    //Fix to Force useState update in React 18      
    flushSync(() => {
      setItems([...itemsCopy]);
    });
    setTotalWeight(calculateTotalWeight(itemsCopy));
    updateRunReducer(itemsCopy);    
  };

  const deleteRun = (event) =>{   
    if(items.length === 0){
      console.log("delete run: " + props.index);
      props.deleteOnEmpty(props.index);
    }
  }

  const confirmAutoRoute = (e) => {
    if(items.length > items.filter(item => item.lat && item.lng).length){
      showModal(
        "Some stops cannot be mapped. If you continue all unmappable stops will be placed at the end of the run. Do you want to continue?",
        MESSAGES_CONSTANTS.MODAL_WARNING_TITLE,
          "No", hideModal, "Yes", autoRoute,
      );
    }
    else{
      showModal(
        "This may change your existing stop order. Are you sure you want to continue?",
        MESSAGES_CONSTANTS.MODAL_WARNING_TITLE,
        "No", hideModal, "Yes", autoRoute,
      );
    }
  }

  const autoRoute = (e) => {
    console.log("autoRoute");
    hideModal();

    let itemsCoordinates = []
    let itemsNotMapped = [];
    items.forEach(item => {
      if(item.lat && item.lng){
        itemsCoordinates.push(item);
      }
      else{
        itemsNotMapped.push(item);
      }
    });

    optimizeRoute(itemsCoordinates).then(response =>{
      console.log(response);      
      if(response.status === "OK"){
        let newOrder = response.routes[0].waypoint_order;
        let sortedItems = [];
        newOrder.forEach(newOrderIndex => {
          sortedItems.push(itemsCoordinates[newOrderIndex]);
        });
        itemsNotMapped.forEach(item => {
          sortedItems.push(item);
        });
        console.log(sortedItems);
        setItems([...sortedItems]);
        updateRunReducer([...sortedItems]);        
      }       
    });  
  }

  const optimizeRoute = (items) => {
    let waypoints = [];
    let warehouseCoordinates = { lat: Number(currentWarehouse.Latitude), lng: Number(currentWarehouse.Longitude) };
    const directionsService = new props.google.maps.DirectionsService();
    
    items.forEach(item => {
      waypoints.push({
        location: { lat: item.lat, lng: item.lng },
        stopover: true,
      });
    });

    let request = {
      origin: warehouseCoordinates,
      waypoints: waypoints,
      destination: warehouseCoordinates,
      optimizeWaypoints: true,
      travelMode: "DRIVING",
    };
    return directionsService.route(request);
  }

  const confirmReverseRun = (e) => {
    console.log("confirmReverseRun");
    showModal("Are you sure you want to reverse the stop order for this run?",
      MESSAGES_CONSTANTS.MODAL_WARNING_TITLE, "No", hideModal, "Yes", reverseRun);
  }

  const reverseRun = (e) => {
    console.log("reverseRun");
    hideModal();
    const reversedItems = items.reverse();
    setItems([...reversedItems]);
    updateRunReducer([...reversedItems]);    
  }

  const createLoadDone = (loadNumber, driver, truck, shipmentDate) => {
    setShowCreateLoadDialog(false);
    setLoadCreated(true);
    setLoadNumber(loadNumber);
    setLoadDriver(driver);
    setLoadTruck(truck);
    setLoadDate(shipmentDate);
    setShowPrintLoadDialog(true);
    const itemsCopy = items.slice();
    itemsCopy.map(item => {
      item.loadNumber = loadNumber;
    })
    updateRunReducer([...itemsCopy], color, false, true);
  }

  const updateLoadDone = (driver, truck) => {
    setShowCreateLoadDialog(false);
    setLoadDriver(driver);
    setLoadTruck(truck);
  }

  const printLoadDone = (event) => {
    setShowPrintLoadDialog(false);
    setLoadCreated(true);
    updateRunReducer(items, color, false, true, minimized, title, false);
  }

  const deleteLoadDone = (event) => {
    setShowPrintLoadDialog(false);
    setLoadCreated(false)
    setLoadNumber(undefined);
    setLoadDriver(undefined);
    setLoadTruck(undefined);
    setLoadDate(null);
    updateRunReducer(items, color, false, false, minimized, title, false);
    props.refreshRoutesData();
  }

  const handleColorChange = (e) => {    
    let newColor = e.hex;  
    setColor(newColor);
    setShowColorPicker(false);
    updateRunReducer(items, newColor, false);
  }

  const minimizeOrMaximize = (e) =>{
    let value = minimized === MINIMIZED ? "" : MINIMIZED;
    setMinimized(value);
    updateRunReducer(items, color, false, loadCreated, value);
  }

  const superMinimizeOrMaximize = (e) =>{
    let value = minimized === SUPER_MINIMIZED ? "" : SUPER_MINIMIZED;
    setMinimized(value); 
    updateRunReducer(items, color, false, loadCreated, value);
  }

  const checkStops = (onAfterCheckStopsDone) => {
    let stopsDictionary = {};
    props.items.forEach((item, index) => {
      let location = item.lat + "-" + item.lng + item.deliveryAddressName
      if(!stopsDictionary[location]){
        stopsDictionary[location] = [];
      }
      stopsDictionary[location].push(index + 1);
    });
    
    let stopsWithWarning = [];
    for(let location in stopsDictionary){
      let stops = stopsDictionary[location];
      if(stops.length > 1){
        let stopsNotAreConsecutive = false;
        for(var i=1; i < stops.length; i++) {
          if(Math.abs(stops[i]-stops[i-1]) !== 1) {
            stopsNotAreConsecutive = true;
          }
        }
        if(stopsNotAreConsecutive){
          stopsWithWarning.push(stops);
        }
      }
    }
    if(stopsWithWarning.length > 0){      
      showModal(<>The following stops have the same location:  
        {stopsWithWarning.map((stops, index) => <span key={index}><br/><span>{"-" + stops + "."}</span></span>)}</>, 
        MESSAGES_CONSTANTS.MODAL_WARNING_TITLE, MESSAGES_CONSTANTS.MODAL_OK_OPTION, 
        () => {
          hideModal();
          onAfterCheckStopsDone();
        });
    }
    else{
      onAfterCheckStopsDone();
    }
  }
    
  let previousLocation = "";
  let stopCounter = 0;
  return (
    <div className="component_box run">
        <DropTarget
          onHit={handleDrop}
          targetKey={minimized === SUPER_MINIMIZED || loadCreated ? "no-drop" : "boxItem"}
          dropData={{name: props.name}}
        >
          {showColorPicker && 
          (<div className="color-picker">
            <GithubPicker onChangeComplete={ handleColorChange }/>
          </div>)} 

          <div className="box run">
            <div className="box-title run" style={{backgroundColor: color}}>
            {minimized !== SUPER_MINIMIZED && 
              (<span className="minimize-button float-left" onClick={minimizeOrMaximize}
              title={minimized === "" ? "Minimize" : "Maximize"}>
                <FontAwesomeIcon icon={minimized === "" ? faWindowMinimize : faWindowMaximize} />
              </span>)}
              <span className="super-minimize-button float-left" onClick={superMinimizeOrMaximize}
              title={minimized === SUPER_MINIMIZED ? "Maximize" : "Super-minimize"}>
                <FontAwesomeIcon icon={minimized === SUPER_MINIMIZED ? faArrowAltFromTop : faArrowAltToTop} />
              </span>
              {title + (loadCreated ? ' (Load)' : '')}
              {items.length === 0 && (
              <span className="delete-run float-right" onClick={deleteRun}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </span>)}
              <span className="color-picker-button float-right" 
                onClick={() => setShowColorPicker(!showColorPicker)} title="Change color">
                <FontAwesomeIcon icon={faPalette} />
              </span>
              <span className="color-picker-button float-right" onClick={editTitle} title="Edit title">
                <FontAwesomeIcon icon={faTag} />
              </span>
              
            </div>                  
            <hr/>
            <div className={"box-body run " + minimized}> 
            {minimized === "" && items.length >= 2 && !loadCreated &&
              !items.find(i => i.lockedBy) &&
              (<button className="btn btn-secondary btn-auto-route" onClick={confirmAutoRoute}>Auto-Route</button>)
            }
            {minimized === "" && items.length >= 2 && !loadCreated &&
              !items.find(i => i.lockedBy) &&
              (<button className="btn btn-secondary btn-auto-route" onClick={confirmReverseRun}>Reverse</button>)
            }
              {items.map((item, index) => {
                let currentLocation = item.lat + "-" + item.lng + item.deliveryAddressName;
                if(previousLocation !== currentLocation){
                  stopCounter++;
                  previousLocation = currentLocation;
                } 
                return (<Item key={item.uid} uid={item.uid} index={index} itemData={item} stopNumber={stopCounter}
                  kill={kill} swap={swap} showMoreInfo={props.showMoreInfo} hidden={minimized !== ""} 
                  loadCreated={loadCreated} editNote={props.editNote} selectItem={props.selectItem} 
                  remove={removeItem} updateShipDate={props.updateShipDate} updateTargetedShipDate={props.updateTargetedShipDate}/>)
                })}
                
            </div> 
            <div className={"box-footer " + minimized}>
            {minimized !== SUPER_MINIMIZED && 
              (<div>
                <div style={{height:"40px"}}></div>
                <span> Total weight: {Math.round(totalWeight).toLocaleString('en')} lbs</span>
                <span> - Total stops: {stopCounter}</span>
                <br/>                  
                {!loadCreated &&
                (<button disabled={items.length === 0 ||
                    items.filter(i => !i.manualStop).length !== items.filter(i => i.label.startsWith("PL#")).length} 
                  className="btn btn-secondary" title={items.length === 0 ||
                    items.filter(i => !i.manualStop).length !== items.filter(i => i.label.startsWith("PL#")).length ? 
                    "All items must be shipments to create a load." : ""}
                  onClick={() => checkStops(() => setShowCreateLoadDialog(true))}>Create Load</button>)}
                &nbsp;{loadCreated &&
                (<button 
                  className="btn btn-secondary" 
                  onClick={() => checkStops(viewLoad)}>View/Edit Load</button>)}
                {!loadCreated && (<button disabled={items.length === 0 ||
                  items.length !== items.filter(i => i.label.startsWith("SO#")).length}
                  className="btn btn-secondary" title={items.length === 0 ||
                    items.length !== items.filter(i => i.label.startsWith("SO#")).length ? 
                    "All items must be sales orders to print run documents." : ""}
                  onClick={() => setShowPrintSODocsDialog(true)}>Run Documents</button>)}
                &nbsp;{!loadCreated && (<button 
                  className="btn btn-secondary" title={"Add Manual Stop"}
                  onClick={() => props.addManualStop(props.routeId, props.index, items, color)}>Add Stop</button>)}                
              </div>)}
            </div>
          </div>
        </DropTarget>
        <CreateLoad
          showCreateLoadDialog={showCreateLoadDialog}
          onCancelCreateLoad={() => setShowCreateLoadDialog(false)}
          refreshRoutesData={props.refreshRoutesData} 
          onCreateLoadDone={createLoadDone}
          onUpdateLoadDone={updateLoadDone}
          totalWeight={Math.round(totalWeight).toLocaleString('en')}
          loadNumber={loadNumber}
          items={items}
          routeId={props.routeId}
          runNumber={props.index}
          capstoneVersion={props.capstoneVersion}/>
        <PrintLoad
          google={props.google}
          id={props.routeId + '-' + props.index}
          showPrintLoadDialog={showPrintLoadDialog}
          onCancelPrintLoad={printLoadDone} 
          onPrintLoadDone={printLoadDone}
          onDeleteLoad={deleteLoadDone}
          onEditLoad={editLoad}
          OnDoneMarkAsShipped={OnDoneMarkAllAsShipped}
          items={items}
          loadNumber={loadNumber}
          loadCreated={loadCreated}
          driver={loadDriver}
          truck={loadTruck}
          loadDate={loadDate}
          capstoneVersion={props.capstoneVersion}/>
        <Modal
          show={showEditTitle}
          onHide={() => setShowEditTitle(false)}
          centered
          autoFocus={true}
          size="m"
          backdropClassName={"custom-backdrop"}
        >
          <Modal.Header>
            <Modal.Title>Edit Run/Load Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              className="form-control" 
              value={editingTitle}
              onChange={onChangeTitleInput}/>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowEditTitle(false)}>Cancel</Button>
            <Button onClick={saveTitle}>Save</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          className="modal-spinner"
          show={wait}
          centered
          onHide={(e) => {}}
        >
          <Spinner animation="border" role="status" />
          <span className="modal-spinner-text">
            {MESSAGES_CONSTANTS.MODAL_SPINNER_MESSAGE}</span>
        </Modal>
        <CustomModal modalData={modalData} hideModal={hideModal}/>
        <PrintSODocs google={props.google} id={props.routeId + '-' + props.index}
          showDialog={showPrintSODocsDialog} items={items} 
          onHideDialog={() => setShowPrintSODocsDialog(false)} capstoneVersion={props.capstoneVersion}/>
    </div>
    );  
} 