import React, { useState, useEffect } from "react";
import {Modal, Spinner} from "react-bootstrap";
import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";

export default function WaitModal(props){

    const [wait, setWait] = useState(false);

    useEffect(() =>{
      setWait(props.wait);
    }, [props.wait]);

    return (<Modal
        className="modal-spinner"
        show={wait }
        centered
        onHide={(e) => {}}
        >
        <Spinner animation="border" role="status" />
        <span className="modal-spinner-text">
        {MESSAGES_CONSTANTS.MODAL_SPINNER_MESSAGE}</span>
    </Modal>);
}