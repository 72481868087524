import useFetch from "./useFetch";

export default function useGPSInsight() {

  const [callGPSInsight] = useFetch("https://api.gpsinsight.com/v2/");
  
  const getTrucksLocations = (vinNumbers) => {
      return getSessionToken(process.env.REACT_APP_GPSINSIGHT_USERNAME, process.env.REACT_APP_GPSINSIGHT_TOKEN).then(tokenResponse => {
          console.log(tokenResponse);
          if(tokenResponse.result?.head?.status === "OK"){
            let promises = [];
            console.log(vinNumbers)
            vinNumbers.forEach(vinNumber => {
              promises.push(getVehicleLocation(tokenResponse.result.data.token, vinNumber));
            });
            return Promise.all(promises).then(locationResponses => {
                console.log(locationResponses);
                let resultResponse = { result: [], error: undefined };
                locationResponses.forEach(locationResponse => {
                  if(locationResponse.result?.head?.status === "OK"){
                    resultResponse.result.push(locationResponse.result?.data[0]);
                  }
                  else{
                    resultResponse.error = "Error getting truck gps location.";
                  }
                });
                return resultResponse;
              });                              
          }
          return tokenResponse;
      })
  }

  const getSessionToken = (username, appToken) => {
      return callGPSInsight("userauth/login", (`?username=${username}&app_token=${appToken}`), "GET");
  }

  const getVehicleLocation = (sessionToken, vehicleVin) => {
      return callGPSInsight("vehicle/location", (`?session_token=${sessionToken}&vehicle=${vehicleVin}`), "GET");
  }

  return { getTrucksLocations };
}