import { BaseRestService } from "./BaseRestService.js";
import utils from "../utils/utils.js";
var shortid = require('shortid');

export class SalesOrderService extends BaseRestService {

  GetOpenSalesOrders(session, warehouseId, targetDeliveryDate, dateWithinDeliveryWindow, includeOverdue, 
    includeWillCall, includeCommomCarrier, search) {
    var resultResponse = {
      result: [],
      error: undefined,
    };
    return super.CustomFetch("SalesOrder/GetOpenSalesOrders", 
    "?warehouseId=" + warehouseId + "&targetDeliveryDate=" + utils.formatDate(targetDeliveryDate) +
    "&dateWithinDeliveryWindow=" + dateWithinDeliveryWindow + "&includeOverdue=" + includeOverdue + 
    "&includeWillCall=" + includeWillCall + "&includeCommomCarrier=" + includeCommomCarrier + "&search=" + (search ?? ""),
    'POST', session)
    .then(openOrdersResult => {
      if(openOrdersResult.error){
        resultResponse.error = openOrdersResult.error;
      }
      else if(openOrdersResult.result){
        let targetDeliveryDateWithoutTime = new Date((targetDeliveryDate ?? new Date()).toDateString());
        openOrdersResult.result.forEach(order => {
          let soTargetDeliveryDate = new Date(order.targetedDeliveryOrPickUp_DateTime);
          let so = {
            id: order.salesOrderHeader_Id,
            secondaryId: order.salesOrderHeader_Id,
            label : "SO#" + order.salesOrderHeader_Id,
            cityAndState: order.deliveryCity ?? "" + (order.deliveryState ? ", " + order.deliveryState : ""),
            uid: shortid.generate(), 
            totalWeight: 0,
            deliveryAddressName: order.deliveryAddressName ?? '',
            runNumber: order.runNumber,
            lat: order.latitude, 
            lng: order.longitude,
            sequenceNumber: order.stopSequenceNumber,
            targetDeliveryDate: soTargetDeliveryDate,
            overdue: includeOverdue ? (dateWithinDeliveryWindow ? 
              new Date(order.deliveryWindowTo_Date) < targetDeliveryDateWithoutTime : 
              soTargetDeliveryDate < targetDeliveryDateWithoutTime) : false,
            future: new Date(soTargetDeliveryDate.toDateString()) > targetDeliveryDateWithoutTime, 
            lockedBy: order.lockedBy,
            note: order.routingNote,
            includePricing: order.includePricingOnShippingDocuments_Flag,
            routeId: order.warehouseDeliveryRoute_Id,
            deliveryAddressType: order.deliveryAddress_Type,
            isCommonCarrier: order.commonCarrier_Flag,
            deliveryMethodId: order.warehouseDeliveryMethod_Id,
            processThroughAccountsReceivable: order.processThroughAccountsReceivable_Flag,
            deliveryTimeWindows: order.deliveryTimeWindows,
            totalTagsPieceCount: 0,
            backOrder: order.backOrder,
            deliveryWindowFromDate: new Date(order.deliveryWindowFrom_Date),
            deliveryWindowToDate: new Date(order.deliveryWindowTo_Date),
            deliveryNote: order.deliveryNote
          };
          order.salesOrderDetails.forEach(detail => {
            so.totalWeight += detail.totalLineWeight;
            so.totalTagsPieceCount += detail.tagsPieceCount;
          });
          resultResponse.result.push(so);      
        });
      }
      return resultResponse;
    });
  }

  Update(session, orders) {

    var orderModel = {
      session: session,
      SalesOrderHeaders: []
    };

    orders.forEach(order => {
      orderModel.SalesOrderHeaders.push({
        SalesOrderHeader_Id: order.id,
        WarehouseDeliveryRoute_Id: order.routeId,
        RunNumber: order.runNumber,
        StopSequenceNumber: order.stopSequenceNumber
      });
    });

    return super.CustomFetch("SalesOrder/Update", "", 'POST', orderModel);    
  }

  UpdateRoutingNote(session, id, routingNote) {

    var orderModel = {
      session: session,
      SalesOrderHeaders: [{
        SalesOrderHeader_Id: id,
        RoutingNote: routingNote
      }]
    };    

    return super.CustomFetch("SalesOrder/Update", "?updateRoutingNote=true", 'POST', orderModel);
  }

  GetSalesOrder(session, salesOrderHeaderId) {

    return super.CustomFetch("SalesOrder/Get", "?salesOrderHeaderId=" + salesOrderHeaderId , 'POST', session);
  }

  GetPlanningDate(session, todayDate) {

    return super.CustomFetch("SalesOrder/GetPlanningDate", "?today=" + todayDate , 'POST', session);
  }

}
