import { BaseSoapService } from "./BaseSoapService.js";

export class MainInitService extends BaseSoapService {
  constructor(baseUrl, capstoneVersion) {
    super(baseUrl, "MainInitService.asmx", "MainInitService", capstoneVersion);
  }

  GetDocumentInitForSystemSettings(session) {
    var args = {
      session: session,
    };

    var resultResponse = {
      result: undefined,
      error: undefined,
    };

    return super.makeSoapCall("GetDocumentInitForSystemSettings", args).then(
      (result) => {
        console.log("GetDocumentInitForSystemSettings:");
        console.log(result);
        if (result.GetDocumentInitForSystemSettingsResponse) {
          resultResponse.result =
            result.GetDocumentInitForSystemSettingsResponse.GetDocumentInitForSystemSettingsResult;
        }
        return resultResponse;
      },
      (error) => {
        console.log("Error on GetDocumentInitForSystemSettings");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

}
