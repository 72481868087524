const initialState = {
  isLogged: false,
  logginError: "",
  sessionData: {},
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case "logIn":
      return {
        ...state,
        isLogged: true,
        sessionData: action.sessionData,
        logginError: "",
      };
    case "logInError":
      return {
        ...state,
        logginError: action.error,
      };
    case "logOut":
      return {
        ...state,
        isLogged: false,
        sessionData: {}
      };

    default:
      return state;
  }
};

export const logOut = () => {
  console.log("loggin out");
  return (dispatch) => {
    sessionStorage.removeItem("isLogged");
    sessionStorage.removeItem("sessionData");
    sessionStorage.removeItem("currentWarehouse");
    dispatch({type: "logOut"});
  };
};

export const logInError = () => {
  return (dispatch) => {
    dispatch({
      type: "logInError",
      error: "The user or password is invalid.",
    });
  };
};

export const logInSuccess = (sessionData) => {
  return (dispatch) => {
    console.log(sessionData);
    sessionStorage.setItem("isLogged", true);
    sessionStorage.setItem("sessionData", JSON.stringify(sessionData));
    dispatch({
      type: "logIn",
      sessionData: sessionData,
    });
  };
};
export default auth;