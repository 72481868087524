module.exports = {
  formatDate: function (date) {
    if (date && date != null) {
      var year = date.getFullYear();

      var month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : month;

      var day = date.getDate().toString();
      day = day.length > 1 ? day : day;

      return month + "/" + day + "/" + year;
    }
    return "";
  },

  formatTime: function (date) {
    if (date && date != null) {
      let hr = date.getUTCHours();
      let min = date.getUTCMinutes();

      if (min < 10) {
        min = "0" + min;
      }
      var ampm = "AM";
      if (hr > 12) {
        hr -= 12;
        ampm = "PM";
      }

      return hr + ":" + min + " " + ampm;
    }
    return "";
  },

  formatQuantity: function (stringNumber, UOM) {
    if (stringNumber == null) return "";
    if (UOM === "pcs") {
      return parseInt(stringNumber)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else if (UOM === "lb") {
      return parseFloat(stringNumber)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return parseFloat(stringNumber)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },

  formatNumber: function (stringNumber) {
    //, precision = 3) {
    if (stringNumber == null) return "";
    //console.log(stringNumber);
    return (
      //parseFloat(stringNumber)
      //.toFixed(precision)
      stringNumber.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  },

  calculateTableHeight: function () {
    let container = document.getElementById("divContainerWithTable");
    let fields = document.getElementById("divFields");
    let tableHeader = document.getElementById("tableHeaderId");
    let tableBody = document.getElementById("tableBodyId");
    let footer = document.getElementById("divFooter");
    // console.log(container);
    // console.log(fields);
    // console.log(tableHeader);
    // console.log(tableBody);
    tableBody.style.height =
      container.offsetHeight -
      fields.offsetHeight -
      (tableHeader ? tableHeader.offsetHeight : 0) -
      (footer ? footer.offsetHeight : 0) -
      25 +
      "px";
  },
};
