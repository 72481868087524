export const MESSAGES_CONSTANTS = {
  MODAL_ERROR_TITLE: "Error",
  MODAL_WARNING_TITLE: "Warning",
  MODAL_CONFIRM_TITLE: "Confirm",
  MODAL_SUCCESS_TITLE: "Success",
  MODAL_INFO_TITLE: "Info",
  MODAL_OK_OPTION: "OK",
  MODAL_YES_OPTION: "Yes",
  MODAL_NO_OPTION: "No",
  MODAL_SPINNER_MESSAGE: "Loading...",
  LOGIN_FAILED: "Login attempt failed.",
  LOGIN_SESSION_EXPIRE_ERROR:
    "Your session has expired. Please sign in to continue working.",
  NO_RECORDS_FOUND: "No records found.",
  NO_RECORD_FOUND: "Record not found.",
  UNEXPECTED_ERROR:
    "Unexpected error. Contact your system administrator or Bayern Software.",

  //Mark as shipped messages
  MARK_AS_SHIPPED_CONFIRMATION: "Would you like to mark the printed packing list(s) as shipped?",

  //Advance shipment notice messages
  ADVANCE_SHIPMENT_NOTICE_MESSAGE:"Would you like to email the shipment documents package?",
  ASN_FAILED_MISSING_EMAIL:"ASN send failed. Your Capstone user account does not have an email address defined."
};
