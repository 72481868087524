import { BaseSoapService } from "./BaseSoapService.js";

export class WarehouseService extends BaseSoapService {
  constructor(baseUrl, capstoneVersion) {
    super(baseUrl, "WarehouseService.asmx", "WarehouseService", capstoneVersion);
  }

  GetAllActiveWarehouses(session) {
    var args = {
      session: session,
    };

    var resultResponse = {
      data: [],
      error: undefined,
    };
    return super.makeSoapCall("GetAllActiveWarehouses", args).then(
      (result) => {
        console.log("GetAllActiveWarehouses");
        if (
          result.GetAllActiveWarehousesResponse &&
          result.GetAllActiveWarehousesResponse.GetAllActiveWarehousesResult
        ) {
          var warehouses =
            result.GetAllActiveWarehousesResponse.GetAllActiveWarehousesResult
              .WarehouseEx;
          if (warehouses && Array.isArray(warehouses)) {
            resultResponse.data = warehouses;
          } else if (warehouses) {
            resultResponse.data = [warehouses];
          }
        }
        return resultResponse;
      },
      (error) => {
        console.log("Error:");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  GetAllWarehouses(session) {
    var args = {
      session: session,
    };

    var resultResponse = {
      data: [],
      error: undefined,
    };
    return super.makeSoapCall("GetAllWarehouses", args).then(
      (result) => {
        console.log("GetAllWarehouses");
        if (
          result.GetAllWarehousesResponse &&
          result.GetAllWarehousesResponse.GetAllWarehousesResult
        ) {
          var warehouses =
            result.GetAllWarehousesResponse.GetAllWarehousesResult.WarehouseEx;
          if (warehouses && Array.isArray(warehouses)) {
            resultResponse.data = warehouses;
          } else if (warehouses) {
            resultResponse.data = [warehouses];
          }
        }
        return resultResponse;
      },
      (error) => {
        console.log("Error:");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  GetWarehouse(session, warehouseId) {
    var args = {
      session: session,
      warehouseId: warehouseId,
    };

    var resultResponse = {
      data: undefined,
      error: undefined,
    };

    return super.makeSoapCall("GetWarehouse", args).then(
      (result) => {
        console.log("GetWarehouse:");
        console.log(result);
        if (result.GetWarehouseResponse) {
          resultResponse.data = result.GetWarehouseResponse.GetWarehouseResult;
        }
        return resultResponse;
      },
      (error) => {
        console.log("Error on GetWarehouse");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

  GetWarehouseDeliveryRoute(session, routeId) {
    var args = {
      session: session,
      routeId: routeId,
    };

    var resultResponse = {
      result: undefined,
      error: undefined,
    };

    return super.makeSoapCall("GetWarehouseDeliveryRoute", args).then(
      (result) => {
        console.log("GetWarehouseDeliveryRoute:");
        console.log(result);
        if (result.GetWarehouseDeliveryRouteResponse) {
          resultResponse.result = result.GetWarehouseDeliveryRouteResponse.GetWarehouseDeliveryRouteResult;
        }
        return resultResponse;
      },
      (error) => {
        console.log("Error on GetWarehouseDeliveryRoute");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }


  GetAllWarehouseLocationsForWarehouse(session, warehouseId) {
    var args = {
      session: session,
      warehouseId: warehouseId,
    };

    var resultResponse = {
      result: [],
      error: undefined,
    };

    return super
      .makeSoapCall("GetAllWarehouseLocationsForWarehouse", args)
      .then(
        (result) => {
          console.log(result);
          let getAllWarehouseLocation = result.GetAllWarehouseLocationsForWarehouseResponse
            ? result.GetAllWarehouseLocationsForWarehouseResponse
                .GetAllWarehouseLocationsForWarehouseResult
            : result.GetAllWarehouseLocationsForWarehouseResponse;
          let getAllWarehouseLocationList =
            getAllWarehouseLocation !== null
              ? getAllWarehouseLocation.WarehouseLocation
              : getAllWarehouseLocation;

          if (getAllWarehouseLocation) {
            console.log(
              "GetAllWarehouseLocationsForWarehouse sucessfully obteined."
            );
            console.log(getAllWarehouseLocationList);
            if (Array.isArray(getAllWarehouseLocationList)) {
              resultResponse.result = getAllWarehouseLocationList;
            } else if (getAllWarehouseLocationList) {
              resultResponse.result = [getAllWarehouseLocationList];
            }
          }
          return resultResponse;
        },
        (error) => {
          resultResponse.error = error;
          console.log("Error on GetAllWarehouseLocationsForWarehouse: ");
          console.log(error);
          return resultResponse;
        }
      );
  }

  CheckForSingleWarehouseLocationForWarehouse(session, warehouseId) {
    var args = {
      session: session,
      warehouseId: warehouseId,
    };
    var resultResponse = {
      result: undefined,
      error: undefined,
    };
    console.log(args);
    return super
      .makeSoapCall("CheckForSingleWarehouseLocationForWarehouse", args)
      .then(
        (result) => {
          console.log("CheckForSingleWarehouseLocationForWarehouse");
          resultResponse.result = result.CheckForSingleWarehouseLocationForWarehouseResponse
            ? result.CheckForSingleWarehouseLocationForWarehouseResponse
                .CheckForSingleWarehouseLocationForWarehouseResult
            : result.CheckForSingleWarehouseLocationForWarehouseResponse;
          console.log(resultResponse);
          return resultResponse;
        },
        (error) => {
          resultResponse.error = error;
          console.log("Error on CheckForSingleWarehouseLocationForWarehouse:");
          console.log(error);
          return resultResponse;
        }
      );
  }

  GetAllActiveWarehouseDeliveryRoutesForWarehouse(session, warehouseId) {
    var args = {
      session: session,
      warehouseId: warehouseId,
      excludeFullyShippedOrdersFlag: false
    };
    var resultResponse = {
      result: [],
      error: undefined,
    };
    return super.makeSoapCall("GetAllActiveWarehouseDeliveryRoutesForWarehouse", args).then(
      (result) => {
        console.log("GetAllActiveWarehouseDeliveryRoutesForWarehouse");
        if (
          result.GetAllActiveWarehouseDeliveryRoutesForWarehouseResponse &&
          result.GetAllActiveWarehouseDeliveryRoutesForWarehouseResponse.GetAllActiveWarehouseDeliveryRoutesForWarehouseResult
        ) {
          var routes =
            result.GetAllActiveWarehouseDeliveryRoutesForWarehouseResponse
            .GetAllActiveWarehouseDeliveryRoutesForWarehouseResult.WarehouseDeliveryRouteEx;
          if (routes && Array.isArray(routes)) {
            resultResponse.result = routes;
          } else if (routes) {
            resultResponse.result = [routes];
          }
        }
        return resultResponse;
      },
      (error) => {
        resultResponse.error = error;
        console.log("Error on GetAllActiveWarehouseDeliveryRoutesForWarehouse:");
        console.log(error);
        return resultResponse;
      }
    );
  }

  GetAllActiveWarehouseDeliveryMethodsForWarehouse(session, warehouseId) {
    var args = {
      session: session,
      warehouseId: warehouseId
    };

    var resultResponse = {
      result: [],
      error: undefined,
    };
    return super.makeSoapCall("GetAllActiveWarehouseDeliveryMethodsForWarehouse", args).then(
      (result) => {
        console.log("GetAllActiveWarehouseDeliveryMethodsForWarehouse");
        if (
          result.GetAllActiveWarehouseDeliveryMethodsForWarehouseResponse &&
          result.GetAllActiveWarehouseDeliveryMethodsForWarehouseResponse.GetAllActiveWarehouseDeliveryMethodsForWarehouseResult
        ) {
          var deliveryMethods =
            result.GetAllActiveWarehouseDeliveryMethodsForWarehouseResponse.GetAllActiveWarehouseDeliveryMethodsForWarehouseResult
              .WarehouseDeliveryMethod;
          if (deliveryMethods && Array.isArray(deliveryMethods)) {
            resultResponse.result = deliveryMethods;
          } else if (deliveryMethods) {
            resultResponse.result = [deliveryMethods];
          }
        }
        return resultResponse;
      },
      (error) => {
        console.log("Error on GetAllActiveWarehouseDeliveryMethodsForWarehouse:");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

}
