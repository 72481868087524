import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from "react-bootstrap";
import useModal from '../useModal/useModal';
import CustomModal from '../useModal/CustomModal';
import DatePicker from "react-datepicker";
import { SalesInvoiceServiceSOAP } from "../../soapServices/SalesInvoiceService";
import { FileAttachmentService } from "../../soapServices/FileAttachmentService";
import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";
import { useSelector } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import { EmailService } from '../../restServices/EmailService';

const CustomInput = (props, ref) => {
  return (
    <input {...props} />
  );
};

export default function UpdatePackingListDialog(props) {

    const session = useSelector(state => state.auth.sessionData.session);
    const loginUser = useSelector(state => state.auth.sessionData.loginUser);
    const [modalData, showModal, hideModal] = useModal();
    const [show, setShow] = useState(false);
    const [currentItem, setCurrentItem] = useState();
    const [shipDate, setShipDate] = useState();
    const inputDateTimePicker = useRef(null);
    const salesInvoiceServiceSOAP = useRef(null);
    const emailService = useRef(null);
    const fileAttachmentService = useRef(null);

    useEffect(() => {
      emailService.current = new EmailService(process.env.REACT_APP_CAPSTONE_REST_API);
      salesInvoiceServiceSOAP.current = new SalesInvoiceServiceSOAP(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);
      fileAttachmentService.current = new FileAttachmentService(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion); 
    }, []);

    useEffect(() => {
        setCurrentItem(props.currentItem);
        if(props.currentItem && props.currentItem.shipDate){
          setShipDate(props.currentItem.shipDate);
        }
    }, [props.currentItem]);

    useEffect(() => {
        setShow(props.show);
        console.log(loginUser)
    }, [props.show]);

    const hide = () => {
        setShow(false);
        props.hide();
    }

    const update = (e) => {     
      e.preventDefault();
      hide();
      props.setWait(true);
      salesInvoiceServiceSOAP.current.UpdateShipmentDate(session, currentItem.id, shipDate).then(response => {
        props.setWait(false);
        console.log(response);
        if(!response.error){
          props.onUpdateDone();
          currentItem.shipDate = shipDate;
          showModal(MESSAGES_CONSTANTS.ADVANCE_SHIPMENT_NOTICE_MESSAGE, MESSAGES_CONSTANTS.MODAL_INFO_TITLE,
            "Yes", sendEmailShipmentDocumentsPackage, "No", hideModal);
        }
      })
    }

    const sendEmailShipmentDocumentsPackage = async () => {
      hideModal();
      if(!loginUser.EmailAddress){
        showModal(MESSAGES_CONSTANTS.ASN_FAILED_MISSING_EMAIL);
        return;
      }
      props.setWait(true);
      await salesInvoiceServiceSOAP.current.EmailShipmentDocumentsPackage(session, currentItem.id, currentItem.includePricing)
      .then(response => {      
        console.log(response);
        if(response.error && response.result.ErrorMessage.length === 0){
          props.setWait(false);
          showModal("Error has occurred on Email Advance Shipment Notice.");
          return;
        }
        else if(response.result){
          let data = response.result;
          let attachmentUrl = process.env.REACT_APP_CAPSTONE_SERVICE_URL + "Storage/" + data.AttachmentFilePath;
          emailService.current.SendEmailForShipment(session, loginUser.EmailAddress, loginUser.Name,
            data.EmailTo, data.EmailCarbonCopy, data.EmailSubject, data.EmailBody, attachmentUrl, currentItem)
          .then(apiResponse => {
            console.log(apiResponse);
            if(apiResponse.error){
              props.setWait(false);
              showModal("Error has occurred on Email Advance Shipment Notice.");
              return;
            }
            else{
              props.setWait(false);
              showModal("Email Advance Shipment Notice sent.", "Info");
              fileAttachmentService.current.DeleteTemporaryFile(session, data.AttachmentFilePath).then(deleteResponse =>{
                console.log(deleteResponse);
              })
            }
          });
        }
      });
    }

    return (<>
      <Modal
          show={show}
          onHide={hide}
          centered
          autoFocus={true}
        >
          <Modal.Header>
            <Modal.Title>Update Packing List</Modal.Title>
          </Modal.Header>
          <Form onSubmit={update}>
            <Modal.Body>
              <label className="form-label">{props.shipDate ? "Ship Date: " : "Targeted Pickup Date: "}&nbsp;</label>
              <DatePicker 
                selected={shipDate} 
                onChange={(date) => setShipDate(date)}
                className={"form-control"}
                customInput={React.createElement(React.forwardRef(CustomInput))}
                required={true}
                ref={inputDateTimePicker}
              />
                      
            </Modal.Body>
            <Modal.Footer>          
              <Button
                onClick={hide}>
                Cancel
              </Button>
              <Button type='submit'>
                Save
              </Button>          
            </Modal.Footer>
          </Form>
        </Modal>
        <CustomModal modalData={modalData} hideModal={hideModal}/>
      </>
    )
}