import React from "react";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { MESSAGES_CONSTANTS } from "../../utils/messagesConstants.js";
import { SalesInvoiceServiceSOAP } from "../../soapServices/SalesInvoiceService";
import { SalesOrderServiceSOAP } from "../../soapServices/SalesOrderService";
import { ShippingService } from "../../soapServices/ShippingService";
import { LoadService } from "../../restServices/LoadService";
import { EmailService } from "../../restServices/EmailService";
import { FileAttachmentService } from "../../soapServices/FileAttachmentService";
import { useMapImageDriverManifest } from "./useMapImageDriverManifest"
import { useDriverManifest, DriverManifest } from "./DriverManifest";
import useModal from '../useModal/useModal';
import CustomModal from '../useModal/CustomModal';
import { Button, Modal, Spinner } from "react-bootstrap";

export default function PrintLoad(props){

  const salesInvoiceService = useRef();
  const salesOrderService = useRef();
  const shippingService = useRef();
  const loadService = useRef();
  const emailService = useRef();
  const fileService = useRef();

  const session = useSelector(state => state.auth.sessionData.session);
  const loginUser = useSelector(state => state.auth.sessionData.loginUser);
  const [modalData, showModal, hideModal] = useModal();
  const [showDialog, setShowDialog] = useState(false);
  const [loadCreated, setLoadCreated] = useState(false);
  const [loadData, setLoadData] = useState();
  const [wait, setWait] = useState(false);
  const [generateMapImage] = useMapImageDriverManifest({...props, setWait});
  const [printDriverManifest, encodedDirections] = useDriverManifest({...props, setWait});

  useEffect(() => {
    salesInvoiceService.current = new SalesInvoiceServiceSOAP(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);
    salesOrderService.current = new SalesOrderServiceSOAP(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);
    shippingService.current = new ShippingService(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);
    fileService.current = new FileAttachmentService(process.env.REACT_APP_CAPSTONE_SERVICE_URL, props.capstoneVersion);
    loadService.current = new LoadService(process.env.REACT_APP_CAPSTONE_REST_API);
    emailService.current = new EmailService(process.env.REACT_APP_CAPSTONE_REST_API);
    
  }, []);   

  useEffect(() => {
    if (props.showPrintLoadDialog === true) {
      showPrintLoadDialog();
    } else {
      setShowDialog(showDialog);
    }
  }, [props.showPrintLoadDialog]);

  useEffect(() => {
    setLoadCreated(props.loadCreated);
  }, [props.loadCreated]);

  useEffect(() => {
    setLoadData({
      loadNumber: props.loadNumber,
      driver: props.driver,
      truck: props.truck,
      loadDate: props.loadDate
    });
  }, [props.loadNumber, props.driver, props.truck, props.loadDate]);

  const hideDialog = () => {    
    setShowDialog(false);
    props.onCancelPrintLoad();
  };

  const editLoad = (e) => {
    setShowDialog(false);
    props.onEditLoad();
  }

  const confirmDeleteLoad = (e) =>{
    showModal("Are you sure you want to delete this load?", MESSAGES_CONSTANTS.MODAL_WARNING_TITLE,
    "Yes", deleteLoad, "No", hideModal);
  }

  const deleteLoad = () => {
    setWait(true);
    setShowDialog(false);
    hideModal();
    loadService.current.DeleteLoad(session, props.loadNumber)
    .then(deleteResult => {
      setWait(false);
      console.log(deleteResult);
      if(deleteResult.error){
        showModal("Error deleting load: " + deleteResult.error, MESSAGES_CONSTANTS.MODAL_ERROR_TITLE);
      }
      else {
        setShowDialog(false);
        props.onDeleteLoad();
      }
    });
  }

  const showPrintLoadDialog = () => {
    console.log("showPrintLoadDialog")
    console.log(loadData)
    if(loadCreated && loadData.loadNumber && !loadData.driver){
      setWait(true);
      loadService.current.GetLoad(session, props.loadNumber)
      .then(loadResult => {
        console.log(loadResult.result);
        setWait(false);
        setShowDialog(true);
        if(loadResult.result){
          setLoadData({
            driver: loadResult.result.driverName,
            truck: loadResult.result.truckName,
            loadDate : new Date(loadResult.result.delivery_Date).toLocaleDateString(),
            loadNumber: props.loadNumber
          });
        }
      });
    }
    else{    
      setShowDialog(true);
    }
  };

  const printPackingLists = async () => {
    setWait(true);
      
    for (let index = 0; index < props.items.length; index++) {
        let item = props.items[index];
        console.log("Printing packing list " + item.id);       
        await salesInvoiceService.current.PrintPackingList(session, item.id, true, item.includePricing)
        .then(printPackingListResult => {
          console.log("Packing list done " + item.id);
          return sleep((Number(process.env.REACT_APP_WAIT_BETWEEN_PRINT_LOADDOCS_SECONDS) * 1000)).then(() => {
            if(item.deliverTestReport){
              console.log("Printing test report " + item.id);             
              return salesInvoiceService.current.GetTestReportForSalesInvoice(session, item.id)
              .then(testReportResult =>{              
                console.log("Test report done " + item.id);
                if(index === (props.items.length-1)){
                  setWait(false);
                  showModal("Packing Lists Printed.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
                }
                return sleep((Number(process.env.REACT_APP_WAIT_BETWEEN_PRINT_LOADDOCS_SECONDS) * 1000)).then(() => { 
                  console.log("wait 5 more seconds")
                  return true; 
                });
                
              });
            }
            else{
              if(index === (props.items.length-1)){
                setWait(false);
                showModal("Packing Lists Printed.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
              } 
              return true;
            }
          });                           
        });
    }
  }

  const printLoadingTickets = async () => {
    setWait(true);
      
    for (let index = props.items.length-1; index >= 0; index--) {
        let item = props.items[index];
        console.log("Printing loading ticket " + item.id);       
        await salesOrderService.current.PrintLoadingTicket(session, item.id, item.secondaryId)
        .then(printLoadingTicketResult => {
          if(printLoadingTicketResult.error){
            setWait(false);
            showModal(printLoadingTicketResult.error?.detail?.CapstoneException?.ErrorMessage, MESSAGES_CONSTANTS.MODAL_ERROR_TITLE);
            return false;
          }
          console.log("Loading Ticket done " + item.id);
          return sleep((Number(process.env.REACT_APP_WAIT_BETWEEN_PRINT_LOADDOCS_SECONDS) * 1000)).then(() => {   

            if(index === 0){
              setWait(false);
              showModal("Loading Tickets Printed.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
            } 
            return true;
            
          });                           
        });
    }
  }

  const markAllAsShipped = async () => {  
    console.log(loadCreated)
    console.log(loadData.loadNumber)
    if(loadCreated && loadData.loadNumber){
      setWait(true);
      loadService.current.GetLoad(session, loadData.loadNumber)
      .then(loadResult => {
        setWait(false);
        if(!loadResult.error && !loadResult.result){
          showModal("This load no longer exists."); 
          //"Warning", "OK", 
          //props.refreshRoutesData();
        }
        else if(loadResult.result){
          shipPackingLists();
        }
      });
    }
  }

  const shipPackingLists = async () => {
    setWait(true);
    for (let index = 0; index < props.items.length; index++) {
      await salesInvoiceService.current.ShipPackingList(session, props.items[index].id)
      .then(response => {
        console.log(response);
        if(index === (props.items.length-1)){
          setWait(false);
          props.OnDoneMarkAsShipped();
          showModal(MESSAGES_CONSTANTS.ADVANCE_SHIPMENT_NOTICE_MESSAGE, MESSAGES_CONSTANTS.MODAL_INFO_TITLE, 
            "Yes", () => sendEmailShipmentDocumentsPackage(), "No", hideModal);
        }              
      });            
    }; 
  }

  const sendEmailShipmentDocumentsPackage = async () => {
    console.log("sendEmailShipmentDocumentsPackage")
    if(!loginUser.EmailAddress){
      showModal(MESSAGES_CONSTANTS.ASN_FAILED_MISSING_EMAIL);
      return;
    }
    setWait(true);
    for (let index = 0; index < props.items.length; index++) {
      let packingList = props.items[index];
      await salesInvoiceService.current.EmailShipmentDocumentsPackage(session, packingList.id, packingList.includePricing).then(response => {      
        console.log(response);
        if(response.error && response.result.ErrorMessage.length === 0){
          setWait(false);
          showModal("Error has occurred on Email Advance Shipment Notice.");
          return;
        }
        else if(response.result){
          let data = response.result;
          let attachmentUrl = process.env.REACT_APP_CAPSTONE_SERVICE_URL + "Storage/" + data.AttachmentFilePath;
          emailService.current.SendEmailForShipment(session, loginUser.EmailAddress, loginUser.Name,
            data.EmailTo ?? data.EmailCarbonCopy, data.EmailCarbonCopy, data.EmailSubject, data.EmailBody, attachmentUrl, packingList)
          .then(apiResponse => {
            console.log(apiResponse);
            if(apiResponse.error){
              setWait(false);
              showModal("Error has occurred on Email Advance Shipment Notice.");
              return;
            }
            else{
              if(index === props.items.length - 1){
                setWait(false);
                showModal("Emails Advance Shipment Notice sent.", MESSAGES_CONSTANTS.MODAL_INFO_TITLE);
              }
              fileService.current.DeleteTemporaryFile(session, data.AttachmentFilePath).then(deleteResponse =>{
                console.log(deleteResponse);
              })
            }
          });
        }
      });
    }
  }

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  return (
    <div>
      <Modal show={showDialog} onHide={() => {}} centered>
        <Modal.Header>
          <Modal.Title>{loadCreated ? "Load #" + props.loadNumber : "Print Load Documents"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{textAlign:"center"}}>   
            <br/>              
              <button className="btn btn-secondary" onClick={printDriverManifest}>Print Driver Manifest</button>              
            <br/>                         
            <br/>
              <button className="btn btn-secondary" onClick={printPackingLists}>Print Packing Lists</button>
            <br/>
            <br/>
              <button className="btn btn-secondary" onClick={printLoadingTickets}>Print Loading Tickets</button>
            <br/>
            <br/>
              <button className="btn btn-secondary" 
              onClick={markAllAsShipped}
              disabled={props.items.length === props.items.filter(i => i.statusType === 4).length }>
                Mark All as Shipped</button>
            <br/>
            <br/>
              <button className="btn btn-secondary" onClick={sendEmailShipmentDocumentsPackage}>Send ASNs</button>
            <br/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loadCreated &&
          (<Button style={{ width: "6rem" }} onClick={confirmDeleteLoad}>
            Delete
          </Button>)}
          {loadCreated &&
          (<Button style={{ width: "6rem" }} onClick={editLoad}>
            Edit
          </Button>)}
          <Button style={{ width: "6rem" }} onClick={hideDialog}>
            Close
          </Button>                        
        </Modal.Footer>
      </Modal>     
      <Modal
        className="modal-spinner"
        show={wait}
        centered
        onHide={(e) => {}}
        backdropClassName={"custom-backdrop"}
      >
        <Spinner animation="border" role="status" />
        <span className="modal-spinner-text">
          {MESSAGES_CONSTANTS.MODAL_SPINNER_MESSAGE}
        </span>
      </Modal>
      <CustomModal modalData={modalData} hideModal={hideModal}/>
      {loadCreated && (<DriverManifest google={props.google} id={props.id} setWait={setWait}
        title={"Driver Manifest by Packing List - Load #"+ props.loadNumber}
        loadData={loadData} items={props.items} encodedDirections={encodedDirections}/>)}
    </div>
  );
}