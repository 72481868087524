import { BaseSoapService } from "./BaseSoapService.js";

export class InventoryService extends BaseSoapService {
  constructor(baseUrl, capstoneVersion) {
    super(baseUrl, "InventoryService.asmx", "InventoryService", capstoneVersion);
  }

  GetInventoryInit(session) {
    var args = {
      session: session,
    };

    var resultResponse = {
      result: undefined,
      error: undefined,
    };

    return super.makeSoapCall("GetInventoryInit", args).then(
      (result) => {
        console.log("GetInventoryInit:");
        console.log(result);
        if (result.GetInventoryInitResponse) {
          resultResponse.result =
            result.GetInventoryInitResponse.GetInventoryInitResult;
        }
        return resultResponse;
      },
      (error) => {
        console.log("Error on GetInventoryInit");
        console.log(error);
        resultResponse.error = error;
        return resultResponse;
      }
    );
  }

}
